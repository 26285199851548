import React from 'react';
import './ProductGroup.scoped.css'

import db from '../../db/application-db';

//https://sag1v.github.io/react-elastic-carousel
//https://www.npmjs.com/package/@itseasy21/react-elastic-carousel fork v18
import Carousel from '@itseasy21/react-elastic-carousel'

import Product from '../Product/Product';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

class ProductGroup extends React.Component {

    _isMounted = false;
    _isLoading = false;
    _totalItemsToShow = 4;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    render() {
        if(this.state.isLoading) {
            return (
                <LoadingSpinner />
            )
        }
        if(!this.state.isLoading){
            if(this.props.categoryName !== undefined || this.props.categoryName !== ''){
                return(
                    <>
                        <h1>{this.props.categoryName}</h1>
                        {
                            this.props.dataByCategoryId !== undefined &&
                            <Carousel className={'product-container'} itemsToShow={3.5} showArrows={false} pagination={false} itemPadding={[0,0,10,10]}>
                                {
                                    this.props.dataByCategoryId.map((group, index) =>{
                                        return (
                                            <Product className={'group-card'} key={index} groupData={group} />
                                        )
                                    })
                                }
                            </Carousel>
                        }
                    </>
                )
            }
        }
    }
}

export default function( props ) {
    return <ProductGroup {...props} />;
}
