export const assetManagementSeed = [
    {
        Id:1,
        assetId:2,
        menuId:null,
        productId:1,
        position:0
    },
    {
        Id:3,
        assetId:3,
        menuId:null,
        productId:7,
        position:0
    },
    {
        Id:4,
        assetId:4,
        menuId:null,
        productId:87,
        position:0
    },
    {
        Id:5,
        assetId:5,
        menuId:null,
        productId:88,
        position:0
    },
    {
        Id:6,
        assetId:6,
        menuId:null,
        productId:89,
        position:0
    },
    {
        Id:11,
        assetId:7,
        menuId:null,
        productId:90,
        position:0
    },
    {
        Id:13,
        assetId:1,
        menuId:null,
        productId:238,
        position:0
    },
    {
        Id:14,
        assetId:8,
        menuId:null,
        productId:91,
        position:0
    },
    {
        Id:17,
        assetId:9,
        menuId:null,
        productId:65,
        position:0
    },
    {
        Id:19,
        assetId:10,
        menuId:null,
        productId:63,
        position:0
    },
    {
        Id:21,
        assetId:1,
        menuId:null,
        productId:233,
        position:0
    },
    {
        Id:22,
        assetId:1,
        menuId:null,
        productId:232,
        position:0
    },
    {
        Id:23,
        assetId:1,
        menuId:null,
        productId:231,
        position:0
    },
    {
        Id:24,
        assetId:1,
        menuId:null,
        productId:230,
        position:0
    },
    {
        Id:27,
        assetId:11,
        menuId:null,
        productId:63,
        position:0
    },
    {
        Id:34,
        assetId:12,
        menuId:null,
        productId:54,
        position:0
    },
    {
        Id:35,
        assetId:13,
        menuId:null,
        productId:54,
        position:0
    },
    {
        Id:40,
        assetId:14,
        menuId:null,
        productId:55,
        position:0
    },
    {
        Id:41,
        assetId:15,
        menuId:null,
        productId:55,
        position:0
    },
    {
        Id:43,
        assetId:16,
        menuId:null,
        productId:61,
        position:0
    },
    {
        Id:44,
        assetId:17,
        menuId:null,
        productId:61,
        position:0
    },
    {
        Id:45,
        assetId:22,
        menuId:null,
        productId:61,
        position:0
    },
    {
        Id:46,
        assetId:23,
        menuId:null,
        productId:61,
        position:0
    },
    {
        Id:51,
        assetId:18,
        menuId:null,
        productId:72,
        position:0
    },
    {
        Id:53,
        assetId:24,
        menuId:null,
        productId:72,
        position:0
    },
    {
        Id:54,
        assetId:25,
        menuId:null,
        productId:72,
        position:0
    },
    {
        Id:56,
        assetId:19,
        menuId:null,
        productId:72,
        position:0
    },
    {
        Id:57,
        assetId:20,
        menuId:null,
        productId:71,
        position:0
    },
    {
        Id:65,
        assetId:26,
        menuId:null,
        productId:56,
        position:0
    },
    {
        Id:67,
        assetId:27,
        menuId:null,
        productId:57,
        position:0
    },
    {
        Id:68,
        assetId:194,
        menuId:null,
        productId:20,
        position:0
    },
    {
        Id:69,
        assetId:195,
        menuId:null,
        productId:20,
        position:0
    },
    {
        Id:70,
        assetId:193,
        menuId:null,
        productId:186,
        position:0
    },
    {
        Id:71,
        assetId:78,
        menuId:null,
        productId:185,
        position:0
    },
    {
        Id:72,
        assetId:79,
        menuId:null,
        productId:183,
        position:0
    },
    {
        Id:73,
        assetId:78,
        menuId:null,
        productId:182,
        position:0
    },
    {
        Id:78,
        assetId:1,
        menuId:null,
        productId:178,
        position:0
    },
    {
        Id:79,
        assetId:190,
        menuId:null,
        productId:84,
        position:0
    },
    {
        Id:80,
        assetId:186,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:81,
        assetId:191,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:82,
        assetId:187,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:83,
        assetId:188,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:84,
        assetId:189,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:85,
        assetId:192,
        menuId:null,
        productId:45,
        position:0
    },
    {
        Id:86,
        assetId:28,
        menuId:null,
        productId:78,
        position:0
    },
    {
        Id:87,
        assetId:29,
        menuId:null,
        productId:78,
        position:0
    },
    {
        Id:88,
        assetId:185,
        menuId:null,
        productId:86,
        position:0
    },
    {
        Id:90,
        assetId:28,
        menuId:null,
        productId:79,
        position:0
    },
    {
        Id:91,
        assetId:29,
        menuId:null,
        productId:79,
        position:0
    },
    {
        Id:92,
        assetId:21,
        menuId:null,
        productId:52,
        position:0
    },
    {
        Id:93,
        assetId:12,
        menuId:null,
        productId:51,
        position:0
    },
    {
        Id:94,
        assetId:12,
        menuId:null,
        productId:50,
        position:0
    },
    {
        Id:95,
        assetId:31,
        menuId:null,
        productId:50,
        position:0
    },
    {
        Id:96,
        assetId:31,
        menuId:null,
        productId:51,
        position:0
    },
    {
        Id:97,
        assetId:32,
        menuId:null,
        productId:53,
        position:0
    },
    {
        Id:98,
        assetId:21,
        menuId:null,
        productId:53,
        position:0
    },
    {
        Id:100,
        assetId:34,
        menuId:null,
        productId:76,
        position:0
    },
    {
        Id:101,
        assetId:36,
        menuId:null,
        productId:76,
        position:0
    },
    {
        Id:102,
        assetId:35,
        menuId:null,
        productId:76,
        position:0
    },
    {
        Id:103,
        assetId:37,
        menuId:null,
        productId:62,
        position:0
    },
    {
        Id:104,
        assetId:38,
        menuId:null,
        productId:62,
        position:0
    },
    {
        Id:105,
        assetId:39,
        menuId:null,
        productId:62,
        position:0
    },
    {
        Id:108,
        assetId:42,
        menuId:null,
        productId:81,
        position:0
    },
    {
        Id:109,
        assetId:43,
        menuId:null,
        productId:81,
        position:0
    },
    {
        Id:110,
        assetId:44,
        menuId:null,
        productId:82,
        position:0
    },
    {
        Id:111,
        assetId:45,
        menuId:null,
        productId:82,
        position:0
    },
    {
        Id:112,
        assetId:46,
        menuId:null,
        productId:74,
        position:0
    },
    {
        Id:113,
        assetId:47,
        menuId:null,
        productId:74,
        position:0
    },
    {
        Id:114,
        assetId:48,
        menuId:null,
        productId:75,
        position:0
    },
    {
        Id:115,
        assetId:49,
        menuId:null,
        productId:75,
        position:0
    },
    {
        Id:118,
        assetId:50,
        menuId:null,
        productId:59,
        position:0
    },
    {
        Id:119,
        assetId:44,
        menuId:null,
        productId:60,
        position:0
    },
    {
        Id:120,
        assetId:51,
        menuId:null,
        productId:60,
        position:0
    },
    {
        Id:121,
        assetId:52,
        menuId:null,
        productId:80,
        position:0
    },
    {
        Id:122,
        assetId:53,
        menuId:null,
        productId:80,
        position:0
    },
    {
        Id:124,
        assetId:54,
        menuId:null,
        productId:83,
        position:0
    },
    {
        Id:125,
        assetId:56,
        menuId:null,
        productId:83,
        position:0
    },
    {
        Id:126,
        assetId:1,
        menuId:null,
        productId:177,
        position:0
    },
    {
        Id:128,
        assetId:182,
        menuId:null,
        productId:175,
        position:0
    },
    {
        Id:129,
        assetId:181,
        menuId:null,
        productId:174,
        position:0
    },
    {
        Id:131,
        assetId:178,
        menuId:null,
        productId:173,
        position:0
    },
    {
        Id:132,
        assetId:179,
        menuId:null,
        productId:173,
        position:0
    },
    {
        Id:134,
        assetId:196,
        menuId:null,
        productId:172,
        position:0
    },
    {
        Id:139,
        assetId:174,
        menuId:null,
        productId:170,
        position:0
    },
    {
        Id:141,
        assetId:1,
        menuId:null,
        productId:92,
        position:0
    },
    {
        Id:142,
        assetId:57,
        menuId:null,
        productId:44,
        position:0
    },
    {
        Id:143,
        assetId:58,
        menuId:null,
        productId:44,
        position:0
    },
    {
        Id:144,
        assetId:59,
        menuId:null,
        productId:44,
        position:0
    },
    {
        Id:145,
        assetId:60,
        menuId:null,
        productId:44,
        position:0
    },
    {
        Id:146,
        assetId:61,
        menuId:null,
        productId:73,
        position:0
    },
    {
        Id:147,
        assetId:62,
        menuId:null,
        productId:46,
        position:0
    },
    {
        Id:149,
        assetId:64,
        menuId:null,
        productId:40,
        position:0
    },
    {
        Id:150,
        assetId:65,
        menuId:null,
        productId:40,
        position:0
    },
    {
        Id:159,
        assetId:66,
        menuId:null,
        productId:30,
        position:0
    },
    {
        Id:163,
        assetId:67,
        menuId:null,
        productId:100,
        position:0
    },
    {
        Id:164,
        assetId:68,
        menuId:null,
        productId:100,
        position:0
    },
    {
        Id:165,
        assetId:69,
        menuId:null,
        productId:100,
        position:0
    },
    {
        Id:167,
        assetId:71,
        menuId:null,
        productId:34,
        position:0
    },
    {
        Id:169,
        assetId:74,
        menuId:null,
        productId:68,
        position:0
    },
    {
        Id:170,
        assetId:74,
        menuId:null,
        productId:67,
        position:0
    },
    {
        Id:171,
        assetId:75,
        menuId:null,
        productId:69,
        position:0
    },
    {
        Id:172,
        assetId:76,
        menuId:null,
        productId:66,
        position:0
    },
    {
        Id:174,
        assetId:78,
        menuId:null,
        productId:104,
        position:0
    },
    {
        Id:176,
        assetId:79,
        menuId:null,
        productId:102,
        position:0
    },
    {
        Id:182,
        assetId:80,
        menuId:null,
        productId:105,
        position:0
    },
    {
        Id:184,
        assetId:81,
        menuId:null,
        productId:106,
        position:0
    },
    {
        Id:185,
        assetId:82,
        menuId:null,
        productId:107,
        position:0
    },
    {
        Id:188,
        assetId:83,
        menuId:null,
        productId:108,
        position:0
    },
    {
        Id:189,
        assetId:84,
        menuId:null,
        productId:108,
        position:0
    },
    {
        Id:190,
        assetId:85,
        menuId:null,
        productId:108,
        position:0
    },
    {
        Id:191,
        assetId:86,
        menuId:null,
        productId:109,
        position:0
    },
    {
        Id:192,
        assetId:87,
        menuId:null,
        productId:109,
        position:0
    },
    {
        Id:193,
        assetId:88,
        menuId:null,
        productId:109,
        position:0
    },
    {
        Id:194,
        assetId:89,
        menuId:null,
        productId:110,
        position:0
    },
    {
        Id:195,
        assetId:90,
        menuId:null,
        productId:110,
        position:0
    },
    {
        Id:196,
        assetId:98,
        menuId:null,
        productId:110,
        position:0
    },
    {
        Id:197,
        assetId:92,
        menuId:null,
        productId:111,
        position:0
    },
    {
        Id:198,
        assetId:93,
        menuId:null,
        productId:112,
        position:0
    },
    {
        Id:199,
        assetId:94,
        menuId:null,
        productId:113,
        position:0
    },
    {
        Id:200,
        assetId:95,
        menuId:null,
        productId:114,
        position:0
    },
    {
        Id:201,
        assetId:96,
        menuId:null,
        productId:115,
        position:0
    },
    {
        Id:202,
        assetId:97,
        menuId:null,
        productId:116,
        position:0
    },
    {
        Id:203,
        assetId:98,
        menuId:null,
        productId:117,
        position:0
    },
    {
        Id:212,
        assetId:100,
        menuId:null,
        productId:121,
        position:0
    },
    {
        Id:213,
        assetId:101,
        menuId:null,
        productId:121,
        position:0
    },
    {
        Id:214,
        assetId:102,
        menuId:null,
        productId:122,
        position:0
    },
    {
        Id:215,
        assetId:103,
        menuId:null,
        productId:122,
        position:0
    },
    {
        Id:216,
        assetId:106,
        menuId:null,
        productId:124,
        position:0
    },
    {
        Id:217,
        assetId:107,
        menuId:null,
        productId:125,
        position:0
    },
    {
        Id:218,
        assetId:108,
        menuId:null,
        productId:126,
        position:0
    },
    {
        Id:219,
        assetId:107,
        menuId:null,
        productId:127,
        position:0
    },
    {
        Id:221,
        assetId:110,
        menuId:null,
        productId:128,
        position:0
    },
    {
        Id:222,
        assetId:111,
        menuId:null,
        productId:9,
        position:0
    },
    {
        Id:223,
        assetId:3,
        menuId:null,
        productId:14,
        position:0
    },
    {
        Id:224,
        assetId:112,
        menuId:null,
        productId:6,
        position:0
    },
    {
        Id:228,
        assetId:113,
        menuId:null,
        productId:131,
        position:0
    },
    {
        Id:229,
        assetId:115,
        menuId:null,
        productId:3,
        position:0
    },
    {
        Id:231,
        assetId:116,
        menuId:null,
        productId:132,
        position:0
    },
    {
        Id:232,
        assetId:117,
        menuId:null,
        productId:132,
        position:0
    },
    {
        Id:233,
        assetId:118,
        menuId:null,
        productId:133,
        position:0
    },
    {
        Id:234,
        assetId:119,
        menuId:null,
        productId:133,
        position:0
    },
    {
        Id:236,
        assetId:121,
        menuId:null,
        productId:18,
        position:0
    },
    {
        Id:238,
        assetId:124,
        menuId:null,
        productId:17,
        position:0
    },
    {
        Id:239,
        assetId:123,
        menuId:null,
        productId:13,
        position:0
    },
    {
        Id:240,
        assetId:125,
        menuId:null,
        productId:12,
        position:0
    },
    {
        Id:244,
        assetId:126,
        menuId:null,
        productId:134,
        position:0
    },
    {
        Id:245,
        assetId:127,
        menuId:null,
        productId:134,
        position:0
    },
    {
        Id:249,
        assetId:1,
        menuId:null,
        productId:137,
        position:0
    },
    {
        Id:250,
        assetId:129,
        menuId:null,
        productId:138,
        position:0
    },
    {
        Id:252,
        assetId:130,
        menuId:null,
        productId:140,
        position:0
    },
    {
        Id:254,
        assetId:131,
        menuId:null,
        productId:142,
        position:0
    },
    {
        Id:256,
        assetId:132,
        menuId:null,
        productId:144,
        position:0
    },
    {
        Id:258,
        assetId:133,
        menuId:null,
        productId:146,
        position:0
    },
    {
        Id:259,
        assetId:134,
        menuId:null,
        productId:147,
        position:0
    },
    {
        Id:260,
        assetId:140,
        menuId:null,
        productId:147,
        position:0
    },
    {
        Id:261,
        assetId:142,
        menuId:null,
        productId:147,
        position:0
    },
    {
        Id:262,
        assetId:143,
        menuId:null,
        productId:147,
        position:0
    },
    {
        Id:263,
        assetId:135,
        menuId:null,
        productId:148,
        position:0
    },
    {
        Id:264,
        assetId:141,
        menuId:null,
        productId:148,
        position:0
    },
    {
        Id:265,
        assetId:136,
        menuId:null,
        productId:149,
        position:0
    },
    {
        Id:266,
        assetId:137,
        menuId:null,
        productId:150,
        position:0
    },
    {
        Id:267,
        assetId:138,
        menuId:null,
        productId:151,
        position:0
    },
    {
        Id:268,
        assetId:147,
        menuId:null,
        productId:151,
        position:0
    },
    {
        Id:269,
        assetId:139,
        menuId:null,
        productId:152,
        position:0
    },
    {
        Id:270,
        assetId:148,
        menuId:null,
        productId:152,
        position:0
    },
    {
        Id:271,
        assetId:144,
        menuId:null,
        productId:153,
        position:0
    },
    {
        Id:272,
        assetId:149,
        menuId:null,
        productId:153,
        position:0
    },
    {
        Id:273,
        assetId:145,
        menuId:null,
        productId:154,
        position:0
    },
    {
        Id:274,
        assetId:146,
        menuId:null,
        productId:155,
        position:0
    },
    {
        Id:275,
        assetId:150,
        menuId:null,
        productId:155,
        position:0
    },
    {
        Id:277,
        assetId:151,
        menuId:null,
        productId:35,
        position:0
    },
    {
        Id:278,
        assetId:152,
        menuId:null,
        productId:35,
        position:0
    },
    {
        Id:279,
        assetId:153,
        menuId:null,
        productId:35,
        position:0
    },
    {
        Id:280,
        assetId:154,
        menuId:null,
        productId:156,
        position:0
    },
    {
        Id:281,
        assetId:155,
        menuId:null,
        productId:156,
        position:0
    },
    {
        Id:282,
        assetId:156,
        menuId:null,
        productId:156,
        position:0
    },
    {
        Id:284,
        assetId:157,
        menuId:null,
        productId:157,
        position:0
    },
    {
        Id:285,
        assetId:158,
        menuId:null,
        productId:158,
        position:0
    },
    {
        Id:288,
        assetId:1,
        menuId:null,
        productId:160,
        position:0
    },
    {
        Id:289,
        assetId:1,
        menuId:null,
        productId:161,
        position:0
    },
    {
        Id:290,
        assetId:1,
        menuId:null,
        productId:162,
        position:0
    },
    {
        Id:291,
        assetId:159,
        menuId:null,
        productId:85,
        position:0
    },
    {
        Id:292,
        assetId:160,
        menuId:null,
        productId:85,
        position:0
    },
    {
        Id:293,
        assetId:161,
        menuId:null,
        productId:85,
        position:0
    },
    {
        Id:294,
        assetId:1,
        menuId:null,
        productId:163,
        position:0
    },
    {
        Id:295,
        assetId:1,
        menuId:null,
        productId:164,
        position:0
    },
    {
        Id:297,
        assetId:162,
        menuId:null,
        productId:166,
        position:0
    },
    {
        Id:299,
        assetId:164,
        menuId:null,
        productId:167,
        position:0
    },
    {
        Id:300,
        assetId:165,
        menuId:null,
        productId:167,
        position:0
    },
    {
        Id:303,
        assetId:70,
        menuId:null,
        productId:101,
        position:0
    },
    {
        Id:304,
        assetId:183,
        menuId:null,
        productId:176,
        position:0
    },
    {
        Id:305,
        assetId:104,
        menuId:null,
        productId:123,
        position:0
    },
    {
        Id:307,
        assetId:105,
        menuId:null,
        productId:123,
        position:0
    },
    {
        Id:309,
        assetId:222,
        menuId:null,
        productId:4,
        position:0
    },
    {
        Id:312,
        assetId:202,
        menuId:null,
        productId:189,
        position:0
    },
    {
        Id:313,
        assetId:202,
        menuId:null,
        productId:194,
        position:0
    },
    {
        Id:314,
        assetId:202,
        menuId:null,
        productId:199,
        position:0
    },
    {
        Id:315,
        assetId:202,
        menuId:null,
        productId:204,
        position:0
    },
    {
        Id:316,
        assetId:200,
        menuId:null,
        productId:207,
        position:0
    },
    {
        Id:319,
        assetId:200,
        menuId:null,
        productId:208,
        position:0
    },
    {
        Id:320,
        assetId:200,
        menuId:null,
        productId:209,
        position:0
    },
    {
        Id:321,
        assetId:200,
        menuId:null,
        productId:210,
        position:0
    },
    {
        Id:322,
        assetId:204,
        menuId:null,
        productId:214,
        position:0
    },
    {
        Id:323,
        assetId:199,
        menuId:null,
        productId:223,
        position:0
    },
    {
        Id:324,
        assetId:199,
        menuId:null,
        productId:224,
        position:0
    },
    {
        Id:325,
        assetId:199,
        menuId:null,
        productId:226,
        position:0
    },
    {
        Id:326,
        assetId:201,
        menuId:null,
        productId:215,
        position:0
    },
    {
        Id:327,
        assetId:201,
        menuId:null,
        productId:216,
        position:0
    },
    {
        Id:328,
        assetId:201,
        menuId:null,
        productId:217,
        position:0
    },
    {
        Id:329,
        assetId:207,
        menuId:null,
        productId:243,
        position:0
    },
    {
        Id:330,
        assetId:214,
        menuId:null,
        productId:244,
        position:0
    },
    {
        Id:331,
        assetId:208,
        menuId:null,
        productId:245,
        position:0
    },
    {
        Id:332,
        assetId:209,
        menuId:null,
        productId:246,
        position:0
    },
    {
        Id:333,
        assetId:210,
        menuId:null,
        productId:247,
        position:0
    },
    {
        Id:334,
        assetId:211,
        menuId:null,
        productId:248,
        position:0
    },
    {
        Id:335,
        assetId:212,
        menuId:null,
        productId:249,
        position:0
    },
    {
        Id:336,
        assetId:213,
        menuId:null,
        productId:250,
        position:0
    },
    {
        Id:337,
        assetId:215,
        menuId:null,
        productId:252,
        position:0
    },
    {
        Id:345,
        assetId:226,
        menuId:null,
        productId:180,
        position:0
    },
    {
        Id:346,
        assetId:227,
        menuId:null,
        productId:26,
        position:0
    },
    {
        Id:349,
        assetId:230,
        menuId:null,
        productId:39,
        position:0
    },
    {
        Id:351,
        assetId:232,
        menuId:null,
        productId:31,
        position:0
    },
    {
        Id:354,
        assetId:234,
        menuId:null,
        productId:119,
        position:0
    },
    {
        Id:355,
        assetId:233,
        menuId:null,
        productId:8,
        position:0
    },
    {
        Id:356,
        assetId:248,
        menuId:null,
        productId:43,
        position:0
    },
    {
        Id:357,
        assetId:249,
        menuId:null,
        productId:42,
        position:0
    },
    {
        Id:358,
        assetId:236,
        menuId:24,
        productId:null,
        position:0
    },
    {
        Id:359,
        assetId:237,
        menuId:7,
        productId:null,
        position:0
    },
    {
        Id:360,
        assetId:238,
        menuId:25,
        productId:null,
        position:0
    },
    {
        Id:361,
        assetId:239,
        menuId:26,
        productId:null,
        position:0
    },
    {
        Id:362,
        assetId:240,
        menuId:5,
        productId:null,
        position:0
    },
    {
        Id:363,
        assetId:241,
        menuId:22,
        productId:null,
        position:0
    },
    {
        Id:364,
        assetId:242,
        menuId:23,
        productId:null,
        position:0
    },
    {
        Id:365,
        assetId:243,
        menuId:4,
        productId:null,
        position:0
    },
    {
        Id:367,
        assetId:244,
        menuId:6,
        productId:null,
        position:0
    },
    {
        Id:368,
        assetId:199,
        menuId:null,
        productId:225,
        position:0
    },
    {
        Id:369,
        assetId:199,
        menuId:null,
        productId:227,
        position:0
    },
    {
        Id:370,
        assetId:130,
        menuId:null,
        productId:141,
        position:0
    },
    {
        Id:371,
        assetId:131,
        menuId:null,
        productId:143,
        position:0
    },
    {
        Id:372,
        assetId:129,
        menuId:null,
        productId:139,
        position:0
    },
    {
        Id:373,
        assetId:132,
        menuId:null,
        productId:145,
        position:0
    },
    {
        Id:374,
        assetId:184,
        menuId:null,
        productId:176,
        position:0
    },
    {
        Id:376,
        assetId:234,
        menuId:null,
        productId:120,
        position:0
    },
    {
        Id:378,
        assetId:44,
        menuId:null,
        productId:259,
        position:0
    },
    {
        Id:379,
        assetId:51,
        menuId:null,
        productId:259,
        position:0
    },
    {
        Id:380,
        assetId:205,
        menuId:null,
        productId:214,
        position:0
    },
    {
        Id:382,
        assetId:50,
        menuId:null,
        productId:58,
        position:0
    },
    {
        Id:385,
        assetId:32,
        menuId:null,
        productId:52,
        position:0
    },
    {
        Id:386,
        assetId:157,
        menuId:null,
        productId:134,
        position:0
    },
    {
        Id:387,
        assetId:170,
        menuId:null,
        productId:260,
        position:0
    },
    {
        Id:388,
        assetId:171,
        menuId:null,
        productId:260,
        position:0
    },
    {
        Id:389,
        assetId:264,
        menuId:13,
        productId:null,
        position:0
    },
    {
        Id:390,
        assetId:266,
        menuId:15,
        productId:null,
        position:0
    },
    {
        Id:391,
        assetId:272,
        menuId:27,
        productId:null,
        position:0
    },
    {
        Id:392,
        assetId:258,
        menuId:30,
        productId:null,
        position:0
    },
    {
        Id:393,
        assetId:268,
        menuId:31,
        productId:null,
        position:0
    },
    {
        Id:394,
        assetId:256,
        menuId:32,
        productId:null,
        position:0
    },
    {
        Id:395,
        assetId:271,
        menuId:33,
        productId:null,
        position:0
    },
    {
        Id:396,
        assetId:261,
        menuId:34,
        productId:null,
        position:0
    },
    {
        Id:397,
        assetId:270,
        menuId:null,
        productId:58,
        position:0
    },
    {
        Id:398,
        assetId:259,
        menuId:null,
        productId:25,
        position:0
    },
    {
        Id:400,
        assetId:254,
        menuId:null,
        productId:32,
        position:0
    },
    {
        Id:401,
        assetId:257,
        menuId:null,
        productId:64,
        position:0
    },
    {
        Id:413,
        assetId:267,
        menuId:35,
        productId:null,
        position:0
    },
    {
        Id:414,
        assetId:322,
        menuId:null,
        productId:218,
        position:0
    },
    {
        Id:415,
        assetId:322,
        menuId:null,
        productId:240,
        position:0
    },
    {
        Id:416,
        assetId:323,
        menuId:null,
        productId:239,
        position:0
    },
    {
        Id:419,
        assetId:223,
        menuId:null,
        productId:10,
        position:0
    },
    {
        Id:421,
        assetId:269,
        menuId:null,
        productId:154,
        position:0
    },
    {
        Id:423,
        assetId:253,
        menuId:null,
        productId:93,
        position:0
    },
    {
        Id:425,
        assetId:309,
        menuId:null,
        productId:85,
        position:0
    },
    {
        Id:426,
        assetId:277,
        menuId:null,
        productId:34,
        position:0
    },
    {
        Id:431,
        assetId:283,
        menuId:null,
        productId:188,
        position:0
    },
    {
        Id:434,
        assetId:283,
        menuId:null,
        productId:193,
        position:0
    },
    {
        Id:437,
        assetId:283,
        menuId:null,
        productId:198,
        position:0
    },
    {
        Id:440,
        assetId:283,
        menuId:null,
        productId:203,
        position:0
    },
    {
        Id:443,
        assetId:276,
        menuId:null,
        productId:41,
        position:0
    },
    {
        Id:444,
        assetId:275,
        menuId:null,
        productId:38,
        position:0
    },
    {
        Id:445,
        assetId:282,
        menuId:null,
        productId:37,
        position:0
    },
    {
        Id:446,
        assetId:274,
        menuId:null,
        productId:33,
        position:0
    },
    {
        Id:447,
        assetId:297,
        menuId:null,
        productId:255,
        position:0
    },
    {
        Id:448,
        assetId:296,
        menuId:null,
        productId:255,
        position:0
    },
    {
        Id:449,
        assetId:318,
        menuId:null,
        productId:27,
        position:0
    },
    {
        Id:450,
        assetId:314,
        menuId:null,
        productId:27,
        position:0
    },
    {
        Id:451,
        assetId:291,
        menuId:null,
        productId:24,
        position:0
    },
    {
        Id:452,
        assetId:292,
        menuId:null,
        productId:24,
        position:0
    },
    {
        Id:453,
        assetId:293,
        menuId:null,
        productId:24,
        position:0
    },
    {
        Id:465,
        assetId:320,
        menuId:null,
        productId:20,
        position:0
    },
    {
        Id:466,
        assetId:321,
        menuId:null,
        productId:20,
        position:0
    },
    {
        Id:467,
        assetId:280,
        menuId:null,
        productId:77,
        position:0
    },
    {
        Id:468,
        assetId:281,
        menuId:null,
        productId:77,
        position:0
    },
    {
        Id:470,
        assetId:327,
        menuId:null,
        productId:149,
        position:0
    },
    {
        Id:471,
        assetId:328,
        menuId:null,
        productId:150,
        position:0
    },
    {
        Id:473,
        assetId:329,
        menuId:null,
        productId:28,
        position:0
    },
    {
        Id:474,
        assetId:330,
        menuId:null,
        productId:28,
        position:0
    },
    {
        Id:476,
        assetId:285,
        menuId:null,
        productId:21,
        position:0
    },
    {
        Id:477,
        assetId:286,
        menuId:null,
        productId:21,
        position:0
    },
    {
        Id:478,
        assetId:287,
        menuId:null,
        productId:21,
        position:0
    },
    {
        Id:479,
        assetId:288,
        menuId:null,
        productId:21,
        position:0
    },
    {
        Id:480,
        assetId:290,
        menuId:null,
        productId:21,
        position:0
    },
    {
        Id:481,
        assetId:310,
        menuId:36,
        productId:null,
        position:0
    },
    {
        Id:491,
        assetId:318,
        menuId:null,
        productId:48,
        position:0
    },
    {
        Id:492,
        assetId:312,
        menuId:null,
        productId:48,
        position:0
    },
    {
        Id:493,
        assetId:313,
        menuId:null,
        productId:48,
        position:0
    },
    {
        Id:494,
        assetId:314,
        menuId:null,
        productId:48,
        position:0
    },
    {
        Id:495,
        assetId:311,
        menuId:null,
        productId:253,
        position:0
    },
    {
        Id:496,
        assetId:311,
        menuId:null,
        productId:254,
        position:0
    },
    {
        Id:497,
        assetId:310,
        menuId:null,
        productId:256,
        position:0
    },
    {
        Id:498,
        assetId:310,
        menuId:null,
        productId:257,
        position:0
    },
    {
        Id:499,
        assetId:310,
        menuId:null,
        productId:258,
        position:0
    },
    {
        Id:500,
        assetId:265,
        menuId:37,
        productId:null,
        position:0
    },
    {
        Id:501,
        assetId:339,
        menuId:10,
        productId:null,
        position:0
    },
    {
        Id:502,
        assetId:332,
        menuId:14,
        productId:null,
        position:0
    },
    {
        Id:503,
        assetId:333,
        menuId:9,
        productId:null,
        position:0
    },
    {
        Id:504,
        assetId:334,
        menuId:8,
        productId:null,
        position:0
    },
    {
        Id:505,
        assetId:335,
        menuId:11,
        productId:null,
        position:0
    },
    {
        Id:506,
        assetId:336,
        menuId:17,
        productId:null,
        position:0
    },
    {
        Id:507,
        assetId:337,
        menuId:12,
        productId:null,
        position:0
    },
    {
        Id:508,
        assetId:341,
        menuId:null,
        productId:2,
        position:0
    },
    {
        Id:509,
        assetId:340,
        menuId:null,
        productId:15,
        position:0
    },
    {
        Id:510,
        assetId:342,
        menuId:null,
        productId:166,
        position:0
    },
    {
        Id:511,
        assetId:344,
        menuId:null,
        productId:103,
        position:0
    },
    {
        Id:512,
        assetId:344,
        menuId:null,
        productId:181,
        position:0
    },
    {
        Id:513,
        assetId:344,
        menuId:null,
        productId:184,
        position:0
    },
    {
        Id:514,
        assetId:345,
        menuId:null,
        productId:102,
        position:0
    },
    {
        Id:515,
        assetId:345,
        menuId:null,
        productId:103,
        position:0
    },
    {
        Id:516,
        assetId:345,
        menuId:null,
        productId:104,
        position:0
    },
    {
        Id:517,
        assetId:346,
        menuId:null,
        productId:251,
        position:0
    },
    {
        Id:518,
        assetId:347,
        menuId:null,
        productId:171,
        position:0
    },
    {
        Id:519,
        assetId:63,
        menuId:null,
        productId:93,
        position:0
    },
    {
        Id:520,
        assetId:177,
        menuId:null,
        productId:261,
        position:0
    },
    {
        Id:521,
        assetId:175,
        menuId:null,
        productId:262,
        position:0
    },
    {
        Id:522,
        assetId:176,
        menuId:null,
        productId:263,
        position:0
    },
    {
        Id:523,
        assetId:166,
        menuId:null,
        productId:264,
        position:0
    },
    {
        Id:524,
        assetId:167,
        menuId:null,
        productId:264,
        position:0
    },
    {
        Id:525,
        assetId:353,
        menuId:null,
        productId:97,
        position:0
    },
    {
        Id:526,
        assetId:356,
        menuId:null,
        productId:98,
        position:0
    },
    {
        Id:527,
        assetId:357,
        menuId:null,
        productId:99,
        position:0
    },
    {
        Id:528,
        assetId:358,
        menuId:null,
        productId:70,
        position:0
    },
    {
        Id:529,
        assetId:352,
        menuId:null,
        productId:70,
        position:0
    },
    {
        Id:530,
        assetId:355,
        menuId:null,
        productId:70,
        position:0
    },
    {
        Id:531,
        assetId:354,
        menuId:null,
        productId:118,
        position:0
    },
    {
        Id:532,
        assetId:350,
        menuId:null,
        productId:29,
        position:0
    },
    {
        Id:534,
        assetId:360,
        menuId:null,
        productId:5,
        position:0
    },
    {
        Id:535,
        assetId:361,
        menuId:null,
        productId:135,
        position:0
    },
    {
        Id:536,
        assetId:362,
        menuId:null,
        productId:136,
        position:0
    },
    {
        Id:537,
        assetId:363,
        menuId:null,
        productId:47,
        position:0
    },
    {
        Id:538,
        assetId:364,
        menuId:null,
        productId:179,
        position:0
    },
    {
        Id:539,
        assetId:348,
        menuId:null,
        productId:168,
        position:0
    },
    {
        Id:540,
        assetId:349,
        menuId:null,
        productId:168,
        position:0
    },
    {
        Id:541,
        assetId:365,
        menuId:null,
        productId:173,
        position:0
    },
    {
        Id:542,
        assetId:368,
        menuId:null,
        productId:23,
        position:0
    },
    {
        Id:543,
        assetId:367,
        menuId:null,
        productId:23,
        position:0
    },
    {
        Id:544,
        assetId:369,
        menuId:null,
        productId:23,
        position:0
    },
    {
        Id:545,
        assetId:366,
        menuId:null,
        productId:23,
        position:0
    },
    {
        Id:546,
        assetId:374,
        menuId:null,
        productId:22,
        position:0
    },
    {
        Id:547,
        assetId:373,
        menuId:null,
        productId:22,
        position:0
    },
    {
        Id:548,
        assetId:372,
        menuId:null,
        productId:22,
        position:0
    },
    {
        Id:549,
        assetId:371,
        menuId:null,
        productId:22,
        position:0
    },
    {
        Id:550,
        assetId:370,
        menuId:null,
        productId:22,
        position:0
    },
    {
        Id:551,
        assetId:375,
        menuId:null,
        productId:129,
        position:0
    },
    {
        Id:552,
        assetId:376,
        menuId:null,
        productId:129,
        position:0
    },
    {
        Id:553,
        assetId:377,
        menuId:null,
        productId:130,
        position:0
    },
    {
        Id:554,
        assetId:378,
        menuId:null,
        productId:130,
        position:0
    },
    {
        Id:555,
        assetId:379,
        menuId:null,
        productId:11,
        position:0
    },
    {
        Id:556,
        assetId:380,
        menuId:null,
        productId:24,
        position:0
    },
    {
        Id:557,
        assetId:381,
        menuId:null,
        productId:159,
        position:0
    },
    {
        Id:558,
        assetId:382,
        menuId:null,
        productId:19,
        position:0
    },
    {
        Id:559,
        assetId:383,
        menuId:null,
        productId:19,
        position:0
    },
    {
        Id:560,
        assetId:385,
        menuId:null,
        productId:19,
        position:0
    },
    {
        Id:561,
        assetId:384,
        menuId:null,
        productId:19,
        position:0
    },
    {
        Id:562,
        assetId:386,
        menuId:null,
        productId:46,
        position:0
    },
    {
        Id:564,
        assetId:389,
        menuId:null,
        productId:26,
        position:0
    },
    {
        Id:565,
        assetId:388,
        menuId:null,
        productId:26,
        position:0
    },
    {
        Id:566,
        assetId:390,
        menuId:null,
        productId:266,
        position:0
    },
    {
        Id:567,
        assetId:391,
        menuId:null,
        productId:265,
        position:0
    },
    {
        Id:568,
        assetId:392,
        menuId:null,
        productId:265,
        position:0
    },
    {
        Id:569,
        assetId:393,
        menuId:null,
        productId:265,
        position:0
    },
    {
        Id:570,
        assetId:394,
        menuId:null,
        productId:265,
        position:0
    },
    {
        Id:571,
        assetId:395,
        menuId:null,
        productId:265,
        position:0
    },
    {
        Id:572,
        assetId:367,
        menuId:null,
        productId:120,
        position:0
    },
    {
        Id:573,
        assetId:396,
        menuId:null,
        productId:30,
        position:0
    },
    {
        Id:575,
        assetId:403,
        menuId:null,
        productId:267,
        position:0
    },
    {
        Id:576,
        assetId:404,
        menuId:null,
        productId:267,
        position:0
    },
    {
        Id:577,
        assetId:406,
        menuId:null,
        productId:267,
        position:0
    },
    {
        Id:578,
        assetId:407,
        menuId:null,
        productId:267,
        position:0
    },
    {
        Id:579,
        assetId:412,
        menuId:null,
        productId:269,
        position:0
    },
    {
        Id:580,
        assetId:397,
        menuId:null,
        productId:130,
        position:0
    },
    {
        Id:581,
        assetId:399,
        menuId:null,
        productId:130,
        position:0
    },
    {
        Id:582,
        assetId:400,
        menuId:null,
        productId:129,
        position:0
    },
    {
        Id:583,
        assetId:401,
        menuId:null,
        productId:129,
        position:0
    },
    {
        Id:584,
        assetId:408,
        menuId:null,
        productId:120,
        position:0
    },
    {
        Id:585,
        assetId:409,
        menuId:null,
        productId:120,
        position:0
    },
    {
        Id:586,
        assetId:413,
        menuId:null,
        productId:270,
        position:0
    },
    {
        Id:587,
        assetId:411,
        menuId:null,
        productId:165,
        position:0
    },
    {
        Id:588,
        assetId:415,
        menuId:null,
        productId:49,
        position:0
    },
    {
        Id:589,
        assetId:416,
        menuId:null,
        productId:49,
        position:0
    },
    {
        Id:590,
        assetId:421,
        menuId:null,
        productId:49,
        position:0
    },
    {
        Id:591,
        assetId:420,
        menuId:null,
        productId:49,
        position:0
    },
    {
        Id:592,
        assetId:419,
        menuId:null,
        productId:242,
        position:0
    },
    {
        Id:593,
        assetId:419,
        menuId:null,
        productId:237,
        position:0
    },
    {
        Id:594,
        assetId:419,
        menuId:null,
        productId:235,
        position:0
    },
    {
        Id:595,
        assetId:419,
        menuId:null,
        productId:229,
        position:0
    },
    {
        Id:596,
        assetId:422,
        menuId:null,
        productId:220,
        position:0
    },
    {
        Id:597,
        assetId:422,
        menuId:null,
        productId:222,
        position:0
    },
    {
        Id:598,
        assetId:422,
        menuId:null,
        productId:219,
        position:0
    },
    {
        Id:599,
        assetId:422,
        menuId:null,
        productId:221,
        position:0
    },
    {
        Id:600,
        assetId:418,
        menuId:null,
        productId:234,
        position:0
    },
    {
        Id:601,
        assetId:418,
        menuId:null,
        productId:236,
        position:0
    },
    {
        Id:602,
        assetId:418,
        menuId:null,
        productId:228,
        position:0
    },
    {
        Id:603,
        assetId:418,
        menuId:null,
        productId:241,
        position:0
    },
    {
        Id:604,
        assetId:417,
        menuId:null,
        productId:195,
        position:0
    },
    {
        Id:605,
        assetId:417,
        menuId:null,
        productId:205,
        position:0
    },
    {
        Id:606,
        assetId:417,
        menuId:null,
        productId:200,
        position:0
    },
    {
        Id:607,
        assetId:417,
        menuId:null,
        productId:190,
        position:0
    },
    {
        Id:608,
        assetId:414,
        menuId:null,
        productId:187,
        position:0
    },
    {
        Id:609,
        assetId:414,
        menuId:null,
        productId:192,
        position:0
    },
    {
        Id:610,
        assetId:414,
        menuId:null,
        productId:202,
        position:0
    },
    {
        Id:611,
        assetId:414,
        menuId:null,
        productId:197,
        position:0
    },
    {
        Id:612,
        assetId:424,
        menuId:null,
        productId:213,
        position:0
    },
    {
        Id:613,
        assetId:424,
        menuId:null,
        productId:211,
        position:0
    },
    {
        Id:614,
        assetId:424,
        menuId:null,
        productId:212,
        position:0
    },
    {
        Id:615,
        assetId:426,
        menuId:null,
        productId:169,
        position:0
    },
    {
        Id:616,
        assetId:425,
        menuId:null,
        productId:169,
        position:0
    },
    {
        Id:617,
        assetId:427,
        menuId:null,
        productId:16,
        position:0
    },
    {
        Id:618,
        assetId:428,
        menuId:null,
        productId:26,
        position:0
    },
    {
        Id:619,
        assetId:128,
        menuId:null,
        productId:134,
        position:0
    }
]