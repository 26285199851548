export const productSeed = [
    {
        Id:1,
        rankingId:null,
        name:" Motion T-Shirt",
        description:"<p>Neck plate for labelling<br>Contrast side panels<\/p>"
    },
    {
        Id:2,
        rankingId:2,
        name:" Low Risk Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>Two external fastened flap pockets<\/p>"
    },
    {
        Id:3,
        rankingId:2,
        name:"Low Risk Jacket",
        description:"<p>Unisex food manufacturing jacket<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>Two external fastened flap pockets<\/p>"
    },
    {
        Id:4,
        rankingId:null,
        name:"Low Risk Side Pocket Trousers",
        description:"<p>Unisex food manufacturing trousers<br>Side pockets closed with hidden metal ring fasteners<br>Leg pocket, closed with hidden metal fastener, at left side leg<br>Adjustable leg width<\/p>"
    },
    {
        Id:5,
        rankingId:1,
        name:" High Risk Set in Sleeve Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Traditional collar<br>Set in sleeves<br>Additional stud fastening at neck<br>Adjustable cuff width<br>One internal pocket<\/p>"
    },
    {
        Id:6,
        rankingId:2,
        name:"High Risk Raglan Sleeve Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>Two internal fastened pockets<\/p>"
    },
    {
        Id:7,
        rankingId:2,
        name:"High Risk Jacket",
        description:"<p>Unisex food manufacturing jacket<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>One internal fastened pocket<\/p>"
    },
    {
        Id:8,
        rankingId:1,
        name:"Low Risk Slash Pocket Trousers",
        description:"<p>Unisex food manufacturing trousers<br>Hook and bar fastening<br>Two front swing pockets<br>Pin tuck down centre front of legs&nbsp;<br>Jet pocket on back<\/p>"
    },
    {
        Id:9,
        rankingId:2,
        name:"High Risk Elasticated Trousers",
        description:"<p>Unisex food manufacturing trousers<br>Fully elasticated waist<br>Internal drawstring at waist to secure<br>Slash pocket at hips<\/p>"
    },
    {
        Id:10,
        rankingId:null,
        name:"Max Risk Inspector Coat",
        description:"<p>Unisex food manufacturing inspector coat<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>No pockets<\/p>"
    },
    {
        Id:11,
        rankingId:1,
        name:"Max Risk Set in Sleeve Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Traditional collar<br>Raglan sleeves<br>Adjustable cuff width<br>No pockets<\/p>"
    },
    {
        Id:12,
        rankingId:2,
        name:"Max Risk Raglan Sleeve Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>No pockets<\/p>"
    },
    {
        Id:13,
        rankingId:2,
        name:"Max Risk Jacket",
        description:"<p>Unisex food manufacturing jacket<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>No pockets<\/p>"
    },
    {
        Id:14,
        rankingId:2,
        name:"High Risk Light Weight Jacket",
        description:"<p>Unisex food manufacturing jacket<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>Light weight fabric<br>Two internal pockets<\/p>"
    },
    {
        Id:15,
        rankingId:2,
        name:"Max Risk Cuffed Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Ribbed cuffs<br>No pockets<\/p>"
    },
    {
        Id:16,
        rankingId:1,
        name:"Max Risk Trousers",
        description:"<p>Unisex food manufacturing trousers<br>Fully elasticated waist<br>No pockets<\/p>"
    },
    {
        Id:17,
        rankingId:2,
        name:"Max Risk Elasticated Trousers",
        description:"<p>Unisex food manufacturing trousers<br>Fully elasticated waist<br>Internal drawstring at waist to secure<\/p>"
    },
    {
        Id:18,
        rankingId:2,
        name:"Max Risk Cuffed Trousers",
        description:"<p>Unisex food manufacturing trousers<br>Fully elasticated waist<br>Internal drawstring at waist to secure<br>Ribbed ankle cuffs<\/p>"
    },
    {
        Id:19,
        rankingId:2,
        name:"Zonal Marked Inspector Coat",
        description:"<p>Unisex food manufacturing inspector coat<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>Coloured yoke<br>No pockets<\/p>"
    },
    {
        Id:20,
        rankingId:2,
        name:"Max Risk Zonal Marked Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Mandarin collar<br>Raglan sleeves<br>Adjustable cuff width<br>Coloured yoke<br>No pockets<\/p>"
    },
    {
        Id:21,
        rankingId:1,
        name:"High Risk Coloured Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Traditional collar<br>Set in sleeves<br>Internal pocket<br>Different colour options available to identify wearers\/departments<\/p>"
    },
    {
        Id:22,
        rankingId:1,
        name:"Max Risk Coloured Coat",
        description:"<p>Unisex food manufacturing coat<br>Front opening<br>Concealed fasteners<br>Traditional collar<br>Set in sleeves<br>No pockets<br>Different colour options available to identify wearers\/departments<\/p>"
    },
    {
        Id:23,
        rankingId:1,
        name:"Polycotton Coloured Trousers",
        description:"<p>Unisex food manufacturing trousers<br>Hook and bar fastening<br>Two front swing pockets<br>Jet pocket on back<\/p>"
    },
    {
        Id:24,
        rankingId:1,
        name:"Max Risk Coloured Trousers",
        description:"<p>Unisex food manufacturing trousers<br>Fully elasticated waist<br>Zip fly fastening<br>No pockets<\/p>"
    },
    {
        Id:25,
        rankingId:2,
        name:"Low Risk Coverall",
        description:"<p>Unisex food manufacturing coverall<br>Front opening<br>Concealed metal ring fasteners<br>Mandarin collar<br>Raglan sleeves<br>Internal pocket<\/p>"
    },
    {
        Id:26,
        rankingId:1,
        name:"High Risk Coverall",
        description:"<p>Unisex food manufacturing coverall<br>Front opening<br>Concealed stud front fastening with top stud showing<br>Traditional collar<br>Raglan sleeves<br>Internal pocket<\/p>"
    },
    {
        Id:27,
        rankingId:1,
        name:"Food Trade T-shirt",
        description:"<p>Unisex T-shirt<br>4 layer ribbed crew neck<br>Fabric treated for extra stability and long life<br>Available in selection of colours<\/p>"
    },
    {
        Id:28,
        rankingId:1,
        name:"Food Trade Polo Shirt",
        description:"<p>Unisex Polo shirt<br>3 button neck fastering<br>Fabric treated for extra stability and long life<br>Ribbed finish at collar and cuff<br>Available in selection of colours<\/p>"
    },
    {
        Id:29,
        rankingId:2,
        name:"Food Trade Hat",
        description:"<p>Unisex food trade hat<br>Adjustable snap-buttons at the back<br>Elasticated mesh for the hair<\/p>"
    },
    {
        Id:30,
        rankingId:1,
        name:"Contemporary Chef's Jacket",
        description:"<p>Unisex chef jacket<br>Long sleeves<br>Mandarin collar<br>Concealed press studs<br>Double breasted<\/p>"
    },
    {
        Id:31,
        rankingId:1,
        name:"Contemporary Short Sleeve Chef's Jacket",
        description:"<p>Unisex chef jacket<br>Short sleeves<br>Mandarin collar<br>Concealed press studs<br>Double breasted<\/p>"
    },
    {
        Id:32,
        rankingId:2,
        name:"Concealed Stud Chef's Jacket",
        description:"<p>Unisex chef jackets<br>Long sleeves<br>Stand up collar<br>Concealed press studs<br>Modern fit<\/p>"
    },
    {
        Id:33,
        rankingId:2,
        name:"CoolMax Panel Short Sleeve Chef's Jacket",
        description:"<p>Unisex chef jacket<br>Short sleeves<br>Stand up collar<br>Visible press studs<br>Thermo cool back panel<br>Pen pocket on left sleeve<\/p>"
    },
    {
        Id:34,
        rankingId:1,
        name:"Double Breasted Short Sleeve Chef's Jacket",
        description:"<p>Unisex chef jacket<br>Short sleeves<br>Mandarin collar<br>Two way double breasted front due to the detachable buttons&nbsp;(Buttons ordered separately)<br>Loop at back neck for Bib apron<br>Contrast piping at neck line, cuffs and front edges<\/p>"
    },
    {
        Id:35,
        rankingId:null,
        name:"Piped Double Breasted Chef's Jacket",
        description:"<p>Unisex chef jacket<br>Long sleeves, easy roll up slit feature<br>Mandarin collar<br>Two way double breasted front due to the detachable buttons&nbsp; (Buttons ordered separately)<br>Loop at back neck for Bib apron<br>Contrast piping at neck line, cuffs and front edges<\/p>"
    },
    {
        Id:37,
        rankingId:null,
        name:"Contrast Collar Chef's Jacket",
        description:"<p>Unisex chef jacket<br>Long sleeves, easy roll up slit feature<br>Contrast Mandarin collar<br>Two way double breasted front due to the detachable buttons <br>(Buttons ordered separately)<br>Loop at back neck for Bib apron<br>Contrast backing on cuff<\/p>"
    },
    {
        Id:38,
        rankingId:1,
        name:"Executive Chef's Short Sleeve Shirt",
        description:"<p>Unisex chef shirt<br>Short sleeves<br>Pen pocket on left sleeve<br>Stand up collar<br>Fix buttons with contrast thread<br>Back darts for tailored fit<\/p>"
    },
    {
        Id:39,
        rankingId:1,
        name:"Chef's Hook and Bar Fastened Trousers",
        description:"<p>Unisex chef trousers<br>Hook and bar fastening<br>Elasticated waist for comfort<br>Belt loops<br>Swing pockets at hips<br>Patch pockets on back<\/p>"
    },
    {
        Id:40,
        rankingId:null,
        name:"Chef's Elasticated Trousers",
        description:"<p>Unisex chef trousers<br>Fully elasticated waist<br>Drawstring at waist to secure<br>Slash pocket at hips<br>Patch pocket on back and side leg<\/p>"
    },
    {
        Id:41,
        rankingId:null,
        name:"Chef's Side Pocket Trousers",
        description:"<p>Unisex chef trousers<br>Hook and bar fasterning<br>Semi elasticated waist with adjustment strap at back<br>Adjustable leg width<br>Side pocket fastern with concerned press stud<\/p>"
    },
    {
        Id:42,
        rankingId:null,
        name:"Chef's Navy Check Trousers",
        description:"<p>Unisex chef trousers<br>Hook and bar fastening<br>Elasticated waist<br>Belt loops<br>Swing pockets at hips<br>Patch pocket at back<\/p>"
    },
    {
        Id:43,
        rankingId:null,
        name:"Chef's Mono Check Trousers",
        description:"<p>Unisex chef trousers<br>Hook and bar fastening<br>Elasticated waist<br>Belt loops<br>Swing pockets at hips<br>Patch pocket at back<\/p>"
    },
    {
        Id:44,
        rankingId:1,
        name:"Bib Apron",
        description:"<p>Unisex food catering Bib apron<br>Adjustable neck strap<br>Tied at waist<br>Durable and tear resistant<br>Available in selection of colours<\/p>"
    },
    {
        Id:45,
        rankingId:null,
        name:"Waist Apron",
        description:"<p>Unisex food catering waist apron<br>Tied at waist<br>Durable and tear resistant<br>Available in selection of colours<\/p>"
    },
    {
        Id:46,
        rankingId:1,
        name:"Butchers Apron",
        description:"<p>Unisex butchers apron<br>Secured neck strap<br>Tied at waist<br>Durable and tear resistant<br>Classic stripe<\/p>"
    },
    {
        Id:47,
        rankingId:1,
        name:"Skull Cap",
        description:"<p>Unisex food catering skull cap<br>Oval flat top cap<br>Traditionally worn in kitchens<\/p>"
    },
    {
        Id:48,
        rankingId:null,
        name:"T-shirt",
        description:"<p>Unisex catering T-shirt<br>4 layer ribbed crew neck<br>Extra durable<br>Available in selection of colours<\/p>"
    },
    {
        Id:49,
        rankingId:null,
        name:"Polo Shirt With Pocket",
        description:"<p>Unisex catering polo shirt<br>3 button neck fastering<br>Fabric treated for extra stability and long life<br>Ribbed finish at collar and cuff<br>Available in selection of colours<br>Patch pocket at chest<\/p>"
    },
    {
        Id:50,
        rankingId:null,
        name:"Aqualine Liquid Soap Dispenser 650ml (Manual)",
        description:"<p>Liquid lotion dispenser<br>Variety of qualitative soap (fragrance-free, perfumed etc.)<br>Manual &amp; no touch versions available<\/p>"
    },
    {
        Id:51,
        rankingId:1,
        name:"Aqualine Liquid Soap Dispenser 1000ml (Manual)",
        description:"<p>Liquid lotion dispenser<br>Variety of qualitative soap (fragrance-free, perfumed etc.)<br>Manual &amp; no touch versions available<\/p>"
    },
    {
        Id:52,
        rankingId:null,
        name:"Aqualine Liquid Soap Dispenser 650ml (No Touch)",
        description:"<p>Liquid lotion dispenser<br>Variety of qualitative soap (fragrance-free, perfumed etc.)<br>Manual &amp; no touch versions available<\/p>"
    },
    {
        Id:53,
        rankingId:null,
        name:"Aqualine Liquid Soap Dispenser 1000ml (No Touch)",
        description:"<p>Liquid lotion dispenser<br>Variety of qualitative soap (fragrance-free, perfumed etc.)<br>Manual &amp; no touch versions available<\/p>"
    },
    {
        Id:54,
        rankingId:null,
        name:"Aqualine Foam Soap Dispenser 800ml (Manual)",
        description:"<p>Foaming lotion dispenser with anti-drip system<br>Light and smooth Ecolabel creamy foam<br>Manual &amp; no touch versions available<\/p>"
    },
    {
        Id:55,
        rankingId:null,
        name:"Aqualine Foam Soap Dispenser 800ml (No Touch)",
        description:"<p>Foaming lotion dispenser with anti-drip system<br>Light and smooth Ecolabel creamy foam<br>Manual &amp; no touch versions available<\/p>"
    },
    {
        Id:56,
        rankingId:null,
        name:"Aqualine Industrial Soap Dispenser 2500ml",
        description:"<p>ABS construction<br>Large robust lever<br>Supplied with apricot gel soap with an&nbsp;industry scrubbing agent<\/p>"
    },
    {
        Id:57,
        rankingId:null,
        name:"Aqualine Industrial Soap Dispenser 5000 ml",
        description:"<p>Metal construction<br>Large robust metal lever<br>Supplied with apricot gel soap with an&nbsp;industry scrubbing agent<\/p>"
    },
    {
        Id:58,
        rankingId:null,
        name:"Aqualine Sanitiser Dispenser 650ml (Manual)",
        description:"<p>Liquid sanitiser dispenser<br>Manual &amp; no touch versions available<br>Can be mounted to metal stand<\/p>"
    },
    {
        Id:59,
        rankingId:null,
        name:"Aqualine Sanitiser Dispenser 1000ml (Manual)",
        description:"<p>Liquid sanitiser dispenser<br>Manual &amp; no touch versions available<br>Can be mounted to metal stand<\/p>"
    },
    {
        Id:60,
        rankingId:null,
        name:"Aqualine Sanitiser Dispenser 1000ml (No Touch)",
        description:"<p>Liquid sanitiser dispenser<br>Manual &amp; no touch versions available<br>Can be mounted to metal stand<\/p>"
    },
    {
        Id:61,
        rankingId:null,
        name:"Aqualine Folded Paper Dispenser",
        description:"<p>Convenient change of folded paper<br>Control window allows vision of consumption<br>Variety of paper qualities available, including Ecolabel certified paper<br>Folded paper offers great absorption and hygiene<\/p>"
    },
    {
        Id:62,
        rankingId:null,
        name:"Aqualine Paper Roll Dispenser",
        description:"<p>Manual operation<br>Single use paper sheets<br>Paper is fully protected by the dispenser<br>Delivered with a key for easy replenishing<br>Standard batteries: 20,000 uses<br>Adjustable length of the paper sheet: 20, 24, 28 or 32cm<br>Visual control of consumable through window on the front<\/p>"
    },
    {
        Id:63,
        rankingId:1,
        name:"Aqualine Cotton Towel Dispenser",
        description:"<p>Manual and retractable versions available<br>Hands dried in 10s<br>Special weaving for an efficient friction<br>Washing cycle : 60-80 washings<br>Recycling of the towels<br>100% cotton hand drying solution \u2013 like at home<br>Optimal absorption<br>Natural hypoallergenic<\/p>"
    },
    {
        Id:64,
        rankingId:null,
        name:"Aqualine Cotton Towel Dispenser retractable",
        description:"<p>Manual and retractable versions available<br>Hands dried in 10s<br>Special weaving for an efficient friction<br>Washing cycle : 60-80 washings<br>Recycling of the towels<br>100% cotton hand drying solution \u2013 like at home<br>Optimal absorption<br>Natural hypoallergenic<\/p>"
    },
    {
        Id:65,
        rankingId:null,
        name:"Aqualine Centre Feed Paper Dispenser",
        description:"<p>Convenient change of paper roll<br>Single use paper<br>Paper is fully protected by the dispenser<br>Holds rolls of 250 sheets<br>Refills available in blue and white<\/p>"
    },
    {
        Id:66,
        rankingId:null,
        name:"Dyson Jet Air V Hand Dryer",
        description:"<p>Robust, modern and compact, suitable for all washrooms (small areas and busy places)<br>Doesn\u2019t use any consumables and low standby consumption<br>Powerful and fast hand drying for great end-user experience<br>Touch free capacitive sensor activation<br>Hand dry time measurement:&nbsp;12 seconds<br>HEPA filter capture 99.9 % of particles the size of bacteria<br>Standby power consumption:&nbsp;Less than 0.5 W<\/p>"
    },
    {
        Id:67,
        rankingId:null,
        name:"Dyson Airblade 9KJ Hand Dryer",
        description:"<p>Choose between MAX 900 W and ECO mode 650 W<br>Hand dry time measurement:10 seconds (MAX) and 12 seconds (ECO)<br>Standby power consumption:&nbsp;Less than 0.5 W<br>Hygienic performance with&nbsp;HEPA filter<\/p>"
    },
    {
        Id:68,
        rankingId:null,
        name:"Dyson Airblade  Dryer Wall",
        description:"<p>Wash and dry wall, tall and short versions<br>No touch infra-red activation<br>Hand dry time measurement:&nbsp;14 seconds<br>Hygienic performance with HEPA filter<br>Captures 99.95% of particles the size of bacteria from the washroom air<\/p>"
    },
    {
        Id:69,
        rankingId:null,
        name:"Dyson Airblade Dryer Short",
        description:"<p>Wash and dry wall, tall and short versions<br>No touch infra-red activation<br>Hand dry time measurement:&nbsp;14 seconds<br>Hygienic performance with HEPA filter<br>Captures 99.95% of particles the size of bacteria from the washroom air<\/p>"
    },
    {
        Id:70,
        rankingId:null,
        name:"Dyson Airblade  Dryer Tall ",
        description:"<p>Wash and dry wall, tall and short versions<br>No touch infra-red activation<br>Hand dry time measurement:&nbsp;14 seconds<br>Hygienic performance with HEPA filter<br>Captures 99.95% of particles the size of bacteria from the washroom air<\/p>"
    },
    {
        Id:71,
        rankingId:null,
        name:"Aqualine Fragranced Essential Oil Air Freshener",
        description:"<p>Non-aerosol solution<br>Delivers continuous level of fresh and&nbsp;odour control fragrance for up to 90 days<br>Passive system that works almost&nbsp;exclusively with air flows<br>Cartridge operation unit<br>Cartridges available in three subtle fragrances<\/p>"
    },
    {
        Id:72,
        rankingId:1,
        name:"Aqualine Fragrance Dispenser",
        description:"<p>Fully programmable dispenser<br>Multi-function programming<br>High quality fresh, modern and effective range of fragrances<br>Safe formulations that meet all EU regulations<\/p>"
    },
    {
        Id:73,
        rankingId:null,
        name:"Biozone Ac System",
        description:"<p>Reduce bacteria, viruses and other microcontaminants while eliminating odour<br>Mounted on a celling or wall<br>Completely automated and chemical-free<\/p>"
    },
    {
        Id:74,
        rankingId:null,
        name:"Aqualine Sanitary Disposal Unit (No Touch)",
        description:"<p>Available with a pedal and no touch option for maximum hygiene<br>The design makes them easy to clean externally<br>Reversible lid so unit can be used either side of cubicle<\/p>"
    },
    {
        Id:75,
        rankingId:1,
        name:"Aqualine Sanitary Disposal Unit (Pedal)",
        description:"<p>Available with a pedal and no touch option for maximum hygiene<br>The design makes them easy to clean externally<br>Reversible lid so unit can be used either side of cubicle<\/p>"
    },
    {
        Id:76,
        rankingId:null,
        name:"Aqualine Modesty Bag Dispenser",
        description:"<p>Aqualine modesty bags are a discreet method to dispose of feminine hygiene waste<br>Designed for installation alongside Aqualine Sanitary Disposal Unit<br>Single bag dispensing helps control consumable costs<\/p>"
    },
    {
        Id:77,
        rankingId:1,
        name:"Aqualine Pedal Operated Nappy\/ Incontinence And Swab Bins",
        description:"<p>Pedal operation allows minimum contact<br>The design ensures unit is easy to clean<\/p>"
    },
    {
        Id:78,
        rankingId:null,
        name:"Aqualine Jumbo Roll Dispenser",
        description:"<p>The jumbo is a toilet roll dispenser ideal for high to medium traffic environments<br>Accommodates any roll with a core diameter of 56 \u2013 62mm&nbsp;and with an external diameter of up to 280mm<br>Features a useful inspection window<\/p>"
    },
    {
        Id:79,
        rankingId:null,
        name:"Aqualine Jumbo Roll Dispenser reserve",
        description:"<p>The jumbo is a toilet roll dispenser ideal for high to medium traffic environments<br>Accommodates any roll with a core diameter of 56 \u2013 62mm&nbsp;and with an external diameter of up to 280mm<br>Features a useful inspection window<\/p>"
    },
    {
        Id:80,
        rankingId:1,
        name:"Aqualine Small Roll Dispenser",
        description:"<p>Single use paper sheets<br>Paper is fully protected by the dispenser<br>Delivered with its key<br>Adjustable length of the paper sheet: 20, 24, 28 or 32cm<br>Visual control of consumable through window on the front<\/p>"
    },
    {
        Id:81,
        rankingId:null,
        name:"Aqualine Safe Seat Sanitiser (Manual)",
        description:"<p>Foaming lotion placed on a toilet paper<br>Fungicide and bactericide foam<br>Manual and no touch versions available<\/p>"
    },
    {
        Id:82,
        rankingId:null,
        name:"Aqualine Safe Seat Sanitiser (No Touch)",
        description:"<p>Foaming lotion placed on a toilet paper<br>Fungicide and bactericide foam<br>Manual and no touch versions available<\/p>"
    },
    {
        Id:83,
        rankingId:null,
        name:"Aqualine Toilet Brush",
        description:"<p>Wall mounted<br>Easy to clean<\/p>"
    },
    {
        Id:84,
        rankingId:null,
        name:"Water Management System Wes50 No Valve300",
        description:"<p>Save water and money by controlling water usage<br>Pre-set number of flushes per day<br>Battery or mains operated<\/p>"
    },
    {
        Id:85,
        rankingId:null,
        name:"P-Screen\u00ae",
        description:"<p>Triple Action Technology - dual fragrance &amp; enzyme protection (via active central core)<br>Recyclable<br>Deep bubble &amp; bristle design for optimum splash back protection<br>Fragrances:<br>yellow \u2013 citrus mango<br>light blue \u2013 ice cool<br>grey \u2013 linen breeze<br>dark blue \u2013 marine musk<\/p>"
    },
    {
        Id:86,
        rankingId:null,
        name:"V-Screen\u00ae",
        description:"<p>Unique web design prevents splash back &amp; the angled anti-splash texture keeps urinal areas clean<br>Uses no harmful propellants or solvents<br>Unique anti-slip feature helps keep V-Screen\u00ae in the optimum position in the urinal<\/p>"
    },
    {
        Id:87,
        rankingId:null,
        name:"Apron",
        description:"<p>Provides additional protection to your normal flame retardant garments<br>Low weight and high protection<br>Plastic flame retardant press fasteners at neck and waistband for quick release<br>Reinforced with aramid coated fabric<\/p>"
    },
    {
        Id:88,
        rankingId:null,
        name:"Long Johns ",
        description:"<p>Provides additional protection in combination with your flame retardant garments<br>To be worn underneath as an extra layer<\/p>"
    },
    {
        Id:89,
        rankingId:null,
        name:"Polo Shirt ",
        description:"<p>Provides additional protection in combination with your flame retardant garments<br>Hidden plastic flame retardant press fasteners in front<br>Bottom sleeves with rib cuff<br>To be worn underneath as an extra layer<\/p>"
    },
    {
        Id:90,
        rankingId:null,
        name:"Additional Protection T-Shirt",
        description:"<p>Provides additional protection in combination with your flame retardant garments<br>Longer back for maximum protection and comfort<br>To be worn underneath as an extra layer<\/p>"
    },
    {
        Id:91,
        rankingId:null,
        name:"Additional Protection Zip Sweater ",
        description:"<p>Flame retardant zip sweater closed with plastic flame retardant zipper in front<br>Zipper garage at neck for comfort<br>High collar and long ergonomic cuffs with thumb grip for protection<br>Red stitching and details<\/p>"
    },
    {
        Id:93,
        rankingId:null,
        name:"Butchers Apron",
        description:"<p>Unisex butchers apron<br>Secured neck strap<br>Tied at waist<br>Durable and tear resistant<br>Classic stripe<\/p>"
    },
    {
        Id:97,
        rankingId:null,
        name:"Chemical Splash Polycotton Coverall",
        description:"<p>Bartacks at stress points for extra strength<br>Spiral zip fastened fly<br>Waistband has hook and bar fastening<br>Waistband has six belt loops<br>Two vertical self-lined swing pockets quarter stitched<br>Sewn in crease in front legs<br>One rear jetted pocket right side as worn<\/p><p>EN 13034 Type 6 [PB]<\/p>"
    },
    {
        Id:98,
        rankingId:null,
        name:"Chemical Splash Polycotton Jacket",
        description:"<p>Siloxane apron with Vt945 lining<br>Labels sewn to lining of bib&nbsp;<br>Vt945 halter neck<br>Two Vt945 ties with one heavy duty concealed stud (Concealed male and female stud)<br>Zip fastened front<br>Action back<\/p><p>EN 13034 Type 6 [PB]<\/p>"
    },
    {
        Id:99,
        rankingId:null,
        name:"Chemical Splash Polycotton Trousers",
        description:"<p>Bartacks at stress points for extra strength<br>One straight through supersnap stud at neck<br>Spiral zip fastened front to chest<br>Two square breast pockets have flaps fastened with two straight through supersnap studs<br>Two side seam access pockets<br>One piece plain back<\/p><p>EN 13034 Type 6 [PB]<\/p>"
    },
    {
        Id:100,
        rankingId:null,
        name:"Motion Coverall",
        description:"<p>Adjustable semi elasticated waist<br>Side pockets and reach through openings<br>Chest and back pockets&nbsp;<br>Loop for ID-card in right side pocket<br>Leg pocket with mobile pocket<br>Double ruler pocket with pen pocket&nbsp;<br>Preshaped knee area, with inside knee pockets<br>Reinforcement material on knees and bottom hem<br>All fasteners are plastic<\/p>"
    },
    {
        Id:101,
        rankingId:null,
        name:"Work Coverall",
        description:"<p>Side and back pockets<br>Napoleon chest pockets closed with zipper, pen holder at left sleeve<br>Reinforced knee pockets and leg pockets<\/p>"
    },
    {
        Id:102,
        rankingId:null,
        name:"Eco-Bin Wipers (600) - Multi loose fill - Blue",
        description:"<p>Metal industry<br>Storage Container: Self-closing lid&nbsp;<br>Foot pedal<br>Two wheels&nbsp;<br>1 metal lock<br>Clean and soiled in one solution&nbsp;<br>600 clean wipers per box&nbsp;<br>More boxes per frame possible<br>Movable&nbsp;<br>Anti-static<br>Wiper: High Quality&nbsp;<br>Highly absorbant<br>Multi purpose usage<br>Wipers in bag for dust-free storage<br>Wipers in bag for easy counting and take-away<br>12 bags of 50 wipers<\/p>"
    },
    {
        Id:103,
        rankingId:null,
        name:"Eco-Bin Wipers (600) - Multi loose fill - Green",
        description:"<p>Graphic industry<br>Storage Container: Self-closing lid&nbsp;<br>Foot pedal<br>Two wheels&nbsp;<br>1 metal lock<br>Clean and soiled in one solution&nbsp;<br>600 clean wipers per box&nbsp;<br>More boxes per frame possible<br>Movable&nbsp;<br>Anti-static<br>Wiper: High Quality&nbsp;<br>Highly absorbant<br>Multi purpose usage<br>Wipers in bag for dust-free storage<br>Wipers in bag for easy counting and take-away<br>12 bags of 50 wipers<\/p>"
    },
    {
        Id:104,
        rankingId:null,
        name:"Eco-Bin Wipers (600) - Multi loose fill - Red",
        description:"<p>Automotive industry<br>Storage Container: Self-closing lid&nbsp;<br>Foot pedal<br>Two wheels&nbsp;<br>1 metal lock<br>Clean and soiled in one solution&nbsp;<br>600 clean wipers per box&nbsp;<br>More boxes per frame possible<br>Movable&nbsp;<br>Anti-static<br>Wiper: High Quality&nbsp;<br>Highly absorbant<br>Multi purpose usage<br>Wipers in bag for dust-free storage<br>Wipers in bag for easy counting and take-away<br>12 bags of 50 wipers<\/p>"
    },
    {
        Id:105,
        rankingId:null,
        name:"Endurance Coverall",
        description:"<p>Chest- side-and knee pockets<br>Reinforced back and side pockets&nbsp;<br>Front closed with hidden metal ring fasteners<\/p><p>ISO 11612: 2015, A1\/A2, B1, C1, F1&nbsp;<br>EN 1149-5:2018<br>EN 13034:2005+A1:2009 type 6 [PB]<br>IEC 61482-2:2009, class 1 (4 kA)<\/p>"
    },
    {
        Id:106,
        rankingId:null,
        name:"Endurance Jacket",
        description:"<p>Chest- and front pockets<br>Stand up collar&nbsp;<br>Front closed with hidden metal ring fasteners<\/p><p>ISO 11612: 2015, A1\/A2, B1, C1, F1&nbsp;<br>EN 1149-5:2018<br>EN 13034:2005+A1:2009 type 6 [PB]<br>IEC 61482-2:2009, class 1 (4 kA)<\/p>"
    },
    {
        Id:107,
        rankingId:null,
        name:"Endurance Trousers",
        description:"<p>Side- and knee pockets<br>Reinforced back pocket and side pockets&nbsp;<br>Elastic in waist and fly closed with with hidden metal ring fasteners<\/p><p>ISO 11612: 2015, A1\/A2, B1, C1, F1&nbsp;<br>EN 1149-5:2018<br>EN 13034:2005+A1:2009 type 6 [PB]<br>IEC 61482-2:2009, class 1 (4 kA)<\/p>"
    },
    {
        Id:108,
        rankingId:null,
        name:"Flame and Fire Plus Protection Coverall ",
        description:"<p>Adjustable semi elasticated waist<br>Side pockets and reach through openings<br>Chest- and back pockets with yoke as flap<br>Loop for ID-card inside left chest pocket<br>Double folding ruler pocket with flap and tool loops, at right leg<br>Leg pocket with flap, outside mobile pocket and pen holder<br>All pocket flaps closed with hidden metal ring fasteners<br>Two-level knee pockets<br>Multi function loop for gas detector at right chest<br>Front closed with hidden metal ring fasteners<\/p><p>EN 14404: 2004 + A1:2010, Type 2, Level 1&nbsp;<br>EN 17353: 2020 Type B2&nbsp;<br>ISO 11612: 2015, A1\/A2, B1, C1, E3, F1<br>ISO 11611: 2015, class 1 A1\/A2<br>EN 1149-5: 2018<br>EN 61482-2: 2020, APC 1,&nbsp;<br>ELIM 10,0 cal\/cm\u00b2, ATPV 12,0 cal\/cm\u00b2<\/p>"
    },
    {
        Id:109,
        rankingId:null,
        name:"Flame and Fire Plus Protection Jacket",
        description:"<p>Front closed with hidden metal ring fasteners&nbsp;<br>High collar for maximum protection<br>Side pockets with flat<br>All pockets closed with hidden metal ring fasteners<br>Flap construction enables easy access to pockets<br>Multi function loop at right chest pocket<br>Reflective tape at sleeves and shoulders<\/p><p>EN 14404: 2004 + A1:2010, Type 2, Level 1&nbsp;<br>EN 17353: 2020 Type B2&nbsp;<br>ISO 11612: 2015, A1\/A2, B1, C1, E3, F1<br>ISO 11611: 2015, class 1 A1\/A2<br>EN 1149-5: 2018<br>EN 61482-2: 2020, APC 1,&nbsp;<br>ELIM 10,0 cal\/cm\u00b2, ATPV 12,0 cal\/cm\u00b2<\/p>"
    },
    {
        Id:110,
        rankingId:null,
        name:"Flame and Fire Plus Protection Trousers",
        description:"<p>Front closed with hidden buttoning<br>Metal zipper in fly&nbsp;<br>Side pockets<br>Back pockets with yoke flap<br>Flap construstion enables easy access to pockets<br>All pockets closed with hidden metal ring fasterners<br>Two- level knee pockets<br>Reflective tape around legs for enhanced visibility<\/p><p>EN 14404: 2004 + A1:2010, Type 2, Level 1&nbsp;<br>EN 17353: 2020 Type B2&nbsp;<br>ISO 11612: 2015, A1\/A2, B1, C1, E3, F1<br>ISO 11611: 2015, class 1 A1\/A2<br>EN 1149-5: 2018<br>EN 61482-2: 2020, APC 1,&nbsp;<br>ELIM 10,0 cal\/cm\u00b2, ATPV 12,0 cal\/cm\u00b2<\/p>"
    },
    {
        Id:111,
        rankingId:null,
        name:"Flame Protection Coat",
        description:"<p>Concealed Popper and Zip&nbsp;<br>High collar for max protection<br>Side pockets with flaps<\/p><p>SO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1<br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:112,
        rankingId:null,
        name:"Flame Protection Cotton Coverall",
        description:"<p>Adjustable semi elasticated waist<br>Side pockets and reach through openings<br>Chest- and back pockets with yoke as flap<br>Loop for ID-card inside left chest pocket<br>Double folding ruler pocket with flap at right leg<br>Leg pocket with flap, at left leg<br>All pocket flaps closed with hidden metal ring fasteners<br>Two-level knee pockets<br>Front closed with hidden metal ring fasteners<\/p><p>EN ISO 11611 (Class 1 &amp; 2)<\/p>"
    },
    {
        Id:113,
        rankingId:null,
        name:"Flame Protection Cotton Jacket",
        description:"<p>Front closed with hidden metal ring fasteners<br>High collar for maximum protection of the wearer<br>Side pockets with flap<br>Chest pockets with yoke as flap<br>Flap construction enables easy access to pockets<br>All pocket flaps closed with hidden metal ring fasteners<br>Loop for ID card holder inside left chest pocket<br>EN ISO 11611 (Class 1 &amp; 2)<\/p>"
    },
    {
        Id:114,
        rankingId:null,
        name:"Flame Protection Cotton Trousers",
        description:"<p>Front closed with hidden buttoning, metal zipper in fly<br>Side pockets<br>Back pockets with yoke as flap<br>Flap construction enables easy access to pockets<br>Double folding ruler pocket with flap at right leg<br>Leg pocket with flap, at left leg<br>All pocket flaps closed with hidden metal ring fasteners<br>Two-level knee pockets<\/p><p>EN ISO 11611 (Class 1 &amp; 2)<\/p>"
    },
    {
        Id:115,
        rankingId:null,
        name:"Flame Protection Coverall",
        description:"<p>Adjustable semi elasticated waist&nbsp;<br>Side pockets and reach through opening<br>Chest and back pockets&nbsp;<br>Double folding ruler pocket with flap at right leg<br>Leg pocket with flap<br>Outside mobile pocket and pen holder<br>All pockets closed with hidden metal ring fasteners<br>Two-level knee pockets<br>Multi function loop at right chest<br>Front closed with hidden metal ring fasteners<\/p><p>SO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1<br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:116,
        rankingId:null,
        name:"Flame Protection Jacket",
        description:"<p>Concealed popper and zip&nbsp;<br>High collar for max protection<br>Side pockets with flaps<br>Chest pocket with yoke as flap<br>Loop for ID card at left check pocket<br>Multifunction at right chest&nbsp;<\/p><p>SO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1<br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:117,
        rankingId:null,
        name:"Flame Protection Trousers",
        description:"<p>Concealed button opening<br>Metal zipper in fly<br>Back pockets with yoke as flap<br>Double folding ruler pocket and pen holder<br>All pockets closed with hidden metal ring fasteners<br>Two-level knee pockets<\/p><p>SO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1 <br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:118,
        rankingId:1,
        name:"Flamemaster Coverall",
        description:"<p>Bartacks at stress points for extra strength<br>One rule pocket positioned on the right leg&nbsp;<br>Seven concealed front stud fastenings<br>Side elastication<br>Two breast pockets with flaps<br>Two concealed studs to prevent ingress from welding splatter<br>Two side swing pockets with access<\/p><p>EN ISO 116112 (A, B, C, F)<\/p>"
    },
    {
        Id:120,
        rankingId:1,
        name:"Polycotton Coloured Trousers",
        description:"<p>Unisex industrial trousers<br>Hook and bar fastening<br>Two front swing pockets<br>Jet pocket on back<\/p>"
    },
    {
        Id:121,
        rankingId:null,
        name:"Hi Vis Layering Fleece Jacket",
        description:"<p>Side pockets, front closed with zipper<br>Stretch reflective bands<\/p><p>Hi-Visibility EN 20471 class 3<\/p>"
    },
    {
        Id:122,
        rankingId:null,
        name:"Hi Vis Layering Polo Shirt",
        description:"<p>Stretch reflectives<br>Collar and front closed with metal ring fasteners<\/p><p>D70062111\/18: EN ISO 20471 class 3<br>D71A62111\/18: EN ISO 20471 class 2<\/p>"
    },
    {
        Id:123,
        rankingId:null,
        name:"Hi Vis Long Slv Layering Polo Shirt",
        description:"<p>Long sleeves with rib cuff&nbsp;<br>Stretch reflectives<br>Collar and front closed with metal ring fasteners<\/p><p>Hi-Visibility EN 20471 class 3<\/p>"
    },
    {
        Id:124,
        rankingId:null,
        name:"Hi Vis Layering Sweater",
        description:"<p>Long cuffs with ergonomic thumb grip for maximum protection<br>Stretch reflective bands<\/p><p>Hi-Visibility EN 20471 class 3<\/p>"
    },
    {
        Id:126,
        rankingId:null,
        name:"Hi Vis Weather Proof Winter Coverall",
        description:"<p>Side and back pockets<br>Chest pockets closed with zipper reach through opening<br>Inner pocket&nbsp;<br>Rib cuffs inside sleeves<br>Fleece inside collar<br>Adjustable elastic in back waist<br>Velcro strap at sleeve endings<br>Zippers in sides at lower legs and snap buttons for detachable hood<br>Front closed with 2-way zipper and hidden press buttons<br>At collar possible to attach separate hood 052066511<\/p><p>EN ISO 20471, class 3<\/p>"
    },
    {
        Id:127,
        rankingId:null,
        name:"Hi Vis Weather Proof Winter Jacket",
        description:"<p>Zipped pockets both inside and outside<br>Front pockets and inside collar with fleece lining<br>Ribbed cuffs inside sleeves and endings adjustable with velcro<br>Elastic drawstring with cord stopper at bottom<br>Front closed with zipper and press buttons<br>At collar possible to attach separate hood 052066511<\/p><p>EN ISO 20471: 2013, class 2<\/p>"
    },
    {
        Id:128,
        rankingId:null,
        name:"Hi Vis Weather Proof Winter Trousers",
        description:"<p>Side - and back pockets<br>Shaped knees<br>Zip opening at leg sides<br>Elastic and adjustable waist with velcro closed with<\/p><p>EN ISO 20471: 2013, class 2<\/p>"
    },
    {
        Id:129,
        rankingId:1,
        name:"Industry Basic Coat",
        description:"<p>Unisex industrial coat<br>Front opening<br>Concealed fasteners<br>Traditional collar<br>Set in sleeves<br>Two mitred patch pockets at the hip<br>One mitred patch pocket left breast as worn<br>Different colour options available to identify wearers\/departments<\/p>"
    },
    {
        Id:130,
        rankingId:1,
        name:"Industry Basic Coverall",
        description:"<p>Unisex industrial coverall<br>Front opening<br>Concealed stud front fastening with top stud showing<br>Traditional collar<br>Raglan Sleeves<br>External pockets&nbsp;<\/p>"
    },
    {
        Id:131,
        rankingId:null,
        name:"Work Jacket",
        description:"<p>Side pockets and Napoleon at chest pockets closed with zipper<br>Pen holder at left sleeve Longer in back and strap for adjustment of width<br>Stand up collar and front closed with zipper<br>Hi Vis fabric and reflective piping for enhanced visibility<\/p>"
    },
    {
        Id:132,
        rankingId:null,
        name:"Lumi Jacket",
        description:"<p>Chest, side and inside pockets<br>Stand up collar<br>Closed with metal ring fasteners.<\/p><p>EN ISO 20471, class 2<\/p>"
    },
    {
        Id:133,
        rankingId:null,
        name:"Lumi Trousers",
        description:"<p>Back - and side pockets<br>Reinforced knee pockets<br>Ruler pocket at right side<br>Leg pocket with mobile pocket and pen holder left leg<\/p><p>EN ISO 20471:2013+A1:2016, class 1<br>EN 14404: 2004 + A1:2010, Type 2, Level 1<\/p>"
    },
    {
        Id:134,
        rankingId:null,
        name:"Motion Metal Popper Polo Shirt",
        description:"<p>Unisex Polo shirt<br>Metal ring fasteners<br>Fabric treated for extra stability and long life<br>Ribbed finish at collar and cuff<br>Available in selection of colours<\/p>"
    },
    {
        Id:135,
        rankingId:null,
        name:"Molten Metal Coverall",
        description:"<p>Bartacks at stress points for extra strength<br>Mandarin collar<br>Seven concealed heavy duty front stud fastenings fastened to neck (four in blouse, three in trouser)<br>Two internal square breast pockets<br>EN ISO 11612 D3\/E3<\/p>"
    },
    {
        Id:136,
        rankingId:null,
        name:"Molten Metal Jacket",
        description:"<p>Bartacks at stress points for extra strength<br>Concealed stud front fastening<br>Rule pocket on right hand leg<br>Two breast pockets with mitered corners<br>Two side swing pockets with access <br>EN ISO 11612 D3\/E3&nbsp; &nbsp;&nbsp;<\/p>"
    },
    {
        Id:137,
        rankingId:null,
        name:"Molten Metal Trousers",
        description:"<p>Bartacks at stress points for extra strength<br>Three concealed heavy-duty stud fastened fly<br>Waistband has seven belt loops<br>Concealed jean style button fastening<br>Two in-seam swing pockets edge stitched straight through<br>EN ISO 11612 D3\/E3&nbsp; &nbsp;&nbsp;<\/p>"
    },
    {
        Id:138,
        rankingId:null,
        name:"Multisplash Coverall",
        description:"<p>Side, chest and back pockets<br>Reinforced knee pockets with aramid<br>Leg pocket at right side and ruler pocket at left side, both with flap<br>Adjustable cuffs and waist<br>Closed with plastic flame retardant press fasteners<\/p><p>EN ISO 20471: 2013, class 1 ISO 11612: 2015, A1\/A2, B1, C1, E2, F1 ISO 11611: 2015, class 1 EN 1149-5: 2018 EN 13034 \/ type [PB] 6 IEC 61482-2: 2009, class 1&nbsp;<\/p>"
    },
    {
        Id:139,
        rankingId:null,
        name:"Multisplash Coverall",
        description:"<p>Side, chest and back pockets&nbsp;<br>Reinforced knee pockets with aramid<br>Leg pocket at right side and ruler pocket at left side, both with flap Adjustable cuffs and waist&nbsp;<br>Closed with plastic flame retardant press fasteners<\/p><p>EN ISO 20471: 2013, class 1 ISO 11612: 2015, A1\/A2, B1, C1, E2, F1 ISO 11611: 2015, class 1 EN 1149-5: 2018 EN 13034 \/ type [PB] 6 IEC 61482-2: 2009, class 1&nbsp;<\/p>"
    },
    {
        Id:140,
        rankingId:null,
        name:"Multisplash Jacket",
        description:"<p>Side and chest pockets, two inside pockets one closed with flame retardant plastic zipper<br>Adjustable cuffs and waist<br>Closed with hidden plastic press fasteners<\/p><p>EN ISO 20471: 2013, class 2 ISO 11612: 2015, A1\/A2, B1, C1, E2, F1 ISO 11611: 2015, class 1 EN 1149-5: 2018 EN 13034 \/ type [PB] 6 IEC 61482-2: 2009, class 1&nbsp;<\/p>"
    },
    {
        Id:141,
        rankingId:null,
        name:"Multisplash Jacket",
        description:"<p>Side and chest pockets, two inside pockets one closed with flame retardant plastic zipper.&nbsp;<br>Adjustable cuffs and waist.&nbsp;<br>Closed with hidden plastic press fasteners.<\/p><p>EN ISO 20471: 2013, class 2 ISO 11612: 2015, A1\/A2, B1, C1, E2, F1 ISO 11611: 2015, class 1 EN 1149-5: 2018 EN 13034 \/ type [PB] 6 IEC 61482-2: 2009, class 1&nbsp;<\/p>"
    },
    {
        Id:142,
        rankingId:null,
        name:"Multisplash Outdoor Jacket",
        description:"<p>Jacket with front - and chest pockets, zip pocket hidden behind front placket<br>Pockets are closed with flame retardant velcro&nbsp;<br>Adjustable cuffs and hem<br>Front closed with flame retardant zipper and velcro<br>Rib cuffs inside sleeve endings<br>Waterproof liner in between shell fabric and lining<br>Shell fabric is fluorocarbon treated which makes it waterproof<br>Attachable hood, separate article<\/p><p>EN ISO 20471:2013+A1:2016, class 2 EN 343:2003+A1:2007, class 2 ISO 11612: 2015, A1\/A2, B1, C1, E2, F1 EN 1149-5:2018 EN 13034:2005+A1:2009 type 6 [PB]&nbsp;<\/p>"
    },
    {
        Id:143,
        rankingId:null,
        name:"Multisplash Outdoor Jacket",
        description:"<p>Jacket with front - and chest pockets, zip pocket hidden behind front placket.&nbsp;<br>Pockets are closed with flame retardant velcro.&nbsp;<br>Adjustable cuffs and hem.<br>Front closed with flame retardant zipper and velcro.&nbsp;<br>Rib cuffs inside sleeve endings.&nbsp;<br>Waterproof liner in between shell fabric and lining.&nbsp;<br>Shell fabric is fluorcarbon treated which makes it waterproof.&nbsp;<br>Attachable hood, separate article.<\/p><p>EN ISO 20471:2013+A1:2016, class 2 EN 343:2003+A1:2007, class 2 ISO 11612: 2015, A1\/A2, B1, C1, E2, F1 EN 1149-5:2018 EN 13034:2005+A1:2009 type 6 [PB]&nbsp;<\/p>"
    },
    {
        Id:144,
        rankingId:null,
        name:"Multisplash Trousers",
        description:"<p>Side and and back pockets, knee pockets reinforced with aramid fabric<br>Leg pocket at right side and ruler pocket at left side, both with flap<br>Closed with plastic flame retardant zipper and button<\/p><p>EN ISO 20471: 2013, class 1&nbsp;<br>ISO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1&nbsp;<br>EN 1149-5: 2018&nbsp;<br>EN 13034 \/ type [PB] 6&nbsp;<br>IEC 61482-2: 2009, class 1&nbsp;<\/p>"
    },
    {
        Id:145,
        rankingId:null,
        name:"Multisplash Trousers",
        description:"<p>Side and and back pockets, knee pockets reinforced with aramid fabric Leg pocket at right side and ruler pocket at left side, both with flap<br>Closed with plastic flame retardant zipper and button<\/p><p>EN ISO 20471: 2013, class 1&nbsp;<br>ISO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1&nbsp;<br>EN 1149-5: 2018&nbsp;<br>EN 13034 \/ type [PB] 6&nbsp;<br>IEC 61482-2: 2009, class 1&nbsp;<\/p>"
    },
    {
        Id:146,
        rankingId:null,
        name:"Motion Patch Pocket Jacket",
        description:"<p>Front closed with hidden zipper<br>Stand up collar<br>Side pockets<br>Chest pockets with flap<br>Inside mobile pocket<br>Pen pocket at left sleeve.&nbsp;<br>Waist adjustable with straps<br>Stretch material in armhole for improved comfort<br>All fasteners are plastic<\/p>"
    },
    {
        Id:147,
        rankingId:1,
        name:"Pebble Mini",
        description:"<p>Our entry level Plug &amp; Play\u00ae hand dryer is compatible with our Terra4 Docking Station\u00ae, giving outstanding value at a great price.<br>Incorporating our low carbon engine and an impressive IK10 impact rating, all wrapped&nbsp;up in a sleek and modern compact design.<\/p>"
    },
    {
        Id:148,
        rankingId:null,
        name:"Phoenix Foam Soap Dispenser 800ml (Manual)",
        description:"<p>Made from recycled ABS plastic<br>Foaming lotion dispenser with anti-drip system<br>Light and smooth Ecolabel creamy foam<br>Visual control of the consumable through window on the front<\/p>"
    },
    {
        Id:149,
        rankingId:1,
        name:"Phoenix Liquid Soap Dispenser  (Manual)",
        description:"<p>Made from recycled ABS plastic<br>Liquid lotion dispenser<br>Variety of qualitative soap (fragrance-free, perfumed etc.)<br>Visual control of the consumable through window on the front<\/p>"
    },
    {
        Id:150,
        rankingId:1,
        name:"Phoenix Paper Roll Dispenser",
        description:"<p>Made from recycled ABS plastic<br>Manual operation<br>Paper is fully protected by the dispenser<br>Delivered with a key for easy replenishing<br>Standard batteries: 20,000 uses<br>Visual control of consumable through window on the front<\/p>"
    },
    {
        Id:151,
        rankingId:1,
        name:"Phoenix  Safe Seat Sanitiser  (Manual)",
        description:"<p>Made from recycled ABS plastic<br>Liquid sanitiser dispenser<br>Manually operated<br>Visual control of the consumable through window on the front<br>Can be mounted to metal stand<\/p>"
    },
    {
        Id:152,
        rankingId:null,
        name:"Phoenix Small Roll Dispenser",
        description:"<p>Made from recycled ABS plastic<br>Paper is fully protected by the dispenser<br>Delivered with a key for easy replenishing<br>Paper is fully protected by the dispenser<br>Visual control of consumable through window on the front<br>Space to hold 2 roll<\/p>"
    },
    {
        Id:153,
        rankingId:1,
        name:"Phoenix Cotton Towel Dispenser ",
        description:"<p>Made from recycled ABS plastic<br>Manual version available<br>Hands dried in 10s<br>Special weaving for an efficient friction<br>Washing cycle : 60-80 washings<br>Recycling of the towels<br>100% cotton hand drying solution \u2013 like at home<br>Optimal absorption<br>Natural hypoallergenic<\/p>"
    },
    {
        Id:154,
        rankingId:1,
        name:"Phoenix Jumbo Roll Dispenser Reserve",
        description:"<p>Made from recycled ABS plastic<br>The jumbo is a toilet roll dispenser ideal for high to medium traffic environments<br>Accommodates any roll with a core diameter of 56 \u2013 62mm and with an external diameter of up to 280mm<br>Features a useful inspection window<br>Delivered with a key for easy replenishing<br>Paper is fully protected by the dispenser<br>Reserve feature for when the roll is down to 25% or less to reduce waste<\/p>"
    },
    {
        Id:155,
        rankingId:1,
        name:"Phoenix Sanitary Disposal Unit (Manual)",
        description:"<p>Made from recycled ABS plastic.<br>The design makes them easy to clean externally<br>Reversible lid so unit can be used either side of cubicle<\/p>"
    },
    {
        Id:156,
        rankingId:null,
        name:"Motion Plastic Button Polo Shirt",
        description:"<p>Unisex Polo shirt<br>3 button neck fastering<br>Fabric treated for extra stability and long life<br>Ribbed finish at collar and cuff<br>Available in selection of colours<\/p>"
    },
    {
        Id:158,
        rankingId:null,
        name:"Work Polo Shirt",
        description:"<p>Front closed with plastic buttons&nbsp;<br>Hi Vis panels at sides and top of sleeves for enhanced visibility<\/p>"
    },
    {
        Id:159,
        rankingId:null,
        name:"Proban Coverall",
        description:"<p>Bartacks at stress points for extra strength<br>One breast pocket with flap<br>One rule pocket positioned on the right leg<br>Seven concealed front stud fastenings<br>Two side swing pockets with access<\/p><p>EN ISO 116112 (A, B, C, F)<\/p>"
    },
    {
        Id:164,
        rankingId:null,
        name:"Reinforced Molten Metal Jacket",
        description:"<p>Bartacks at stress points for extra strength<br>Three concealed heavy duty stud fastened fly<br>Waistband has seven belt loops&nbsp;<br>Concealed jean style button fastening<br>Two in-seam swing pockets edge stitched straight through<br>Overlocked On XS440Rs silver panels from above knee to hem<br>EN ISO 11612 D3\/E3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<\/p>"
    },
    {
        Id:165,
        rankingId:null,
        name:"Reinforced Molten Metal Trousers",
        description:"<p>Bartacks at stress points for extra strength<br>Three concealed heavy duty stud fastened fly<br>Waistband has seven belt loops<br>Concealed jean style button fastening<br>Two in-seam swing pockets edge stitched straight through<br>Overlocked on XS440Rs silver panels from above knee to hem<br>EN ISO 11612 D3\/E3&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<\/p>"
    },
    {
        Id:166,
        rankingId:null,
        name:"Revision Coverall Class 3",
        description:"<p>Front closed with zipper &amp; hidden metal press fastener<br>Side and front pockets with flap&nbsp;<br>Closed with hidden metal ring fasteners<br>Extra pocket and pen holder at left side&nbsp;<br>Bottom sleeve width adjustable with hidden metal press fasteners<br>Side and back pockets<br>Double folding ruler pocket at right back leg<br>Lleg pocket with flap at left side with mobile pocket and pen holders<br>Bellows for maximum volume<br>Pre-shaped legs with reinforced knee pockets and reinforced leg bottoms<\/p><p>EN ISO 20471:2013+A1:2016, class 3 EN 14404: 2004 + A1:2010, Type 2, Level 1&nbsp;<\/p>"
    },
    {
        Id:167,
        rankingId:null,
        name:"Revision Jacket Class 3",
        description:"<p>Front closed with zipper and hidden metal press fasteners<br>Side and front pockets with flap<br>Closed with hidden metal ring fasteners<br>Extra pocket and pen holder at left side<br>Bottom sleeve width adjustable with hidden metal press fasteners<br>Inside mobile pocket at right side with flap closed with hidden metal ring fastener<br>Loop for phone cable<br>Ergonomic construction - preshaped sleeves, longer in back<\/p><p>EN ISO 20471:2013+A1:2016, class 3&nbsp;<\/p>"
    },
    {
        Id:168,
        rankingId:null,
        name:"Revision Trousers Class 1",
        description:"<p>Front closed with jeans button and zipper<br>Side and back pockets<br>Double folding ruler pocket at right back leg<br>Leg pocket with flap at left side with mobile pocket and pen holders<br>Bellows for maximum volume&nbsp;<br>Preshaped legs with reinforced knee pockets. and reinforced leg bottoms<br>Hi-Visibility EN 20471<\/p>"
    },
    {
        Id:169,
        rankingId:null,
        name:"Motion Side Pocket Trousers",
        description:"<p>Back and side pockets<br>Leg pocket with mobile pocket&nbsp;<br>Double ruler pocket with pen pocket<br>Preshaped knee area, with inside knee pockets<br>Reinforcement material on knees and bottom hem<br>Waist closed with plastic button<br>All fasteners are plastic<\/p>"
    },
    {
        Id:170,
        rankingId:null,
        name:"Sparkle Coverall",
        description:"<p>Front closed with hidden metal ring fasteners or *fasteners &amp; zip&nbsp;<br>Adjustable semi elasticated waist<br>Side pockets and reach through openings<br>Chest- and back pockets with yoke as flap<br>Loop for ID-card inside left chest pocket<br>Double folding ruler pocket with flap and tool loops<br>Leg pocket with flap, outside mobile pocket and pen holder<br>All pocket flaps closed with hidden metal ring fastener<br>Preshaped knee part with inside knee pocket<br>Multi function loop<br>Front closed with fasteners &amp; zip - Product codes : FRY 830281169*, SSG 830281169*, SOL 102618<\/p><p>EN ISO 20471, class 2<br>ISO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1<br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:171,
        rankingId:null,
        name:"Sparkle Jacket Class 2",
        description:"<p>Front closed with hidden metal ring fasteners or *fasteners &amp; zip<br>High collar for maximum protection of the wearer&nbsp;<br>Side pockets with flap<br>Inside pockets, one closed with zipper and one with penholder<br>Chest pockets with yoke as flap<br>All pocket flaps closed with hidden metal ring fasteners<br>Flap construction enables easy access to pockets<br>Loop for ID-card holder inside left chest pocket<br>Multi function loop for gas detector at right chest<br>Option with hidden metal ring fasteners SOL 102471<br>Option with fasteners and zip SOL 102473<\/p><p>EN ISO 20471, class 2<br>ISO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1<br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:172,
        rankingId:null,
        name:"Sparkle Trousers Class 1",
        description:"<p>Front closed with hidden buttoning, metal zipper in fly<br>Side pockets. Back pockets with yoke as flap<br>Flap construction enables easy access to pockets<br>Double folding ruler pocket with flap and tool loops, at right leg<br>Leg pocket with flap, at left leg, outside mobile pocket and pen holder<br>All pocket flaps closed with hidden metal ring fastener<br>Preshaped knee part with inside knee pocket<\/p><p>EN ISO 20471, class 2<br>ISO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1<br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:173,
        rankingId:null,
        name:"Motion Sweater",
        description:"<p>Neck plate for labelling<br>Contrast side panels<\/p>"
    },
    {
        Id:174,
        rankingId:null,
        name:"Motion Toolpocket Trousers",
        description:"<p>Back and side pockets<br>Leg pocket with mobile pocket&nbsp;<br>Hanging tool pockets<br>Ruler pocket with knife holder and pen pocket<br>Preshaped knee area, with inside knee pockets<br>Reinforcement material on knees and bottom hem<br>Waist closed with double plastic buttons<br>All fasteners are plastic<\/p>"
    },
    {
        Id:175,
        rankingId:null,
        name:"Work Trousers",
        description:"<p>Front and back pockets&nbsp;<br>Reinforced knee pockets&nbsp;<br>Additional pockets for mobile phone, combined ruler and tape measure&nbsp;<br>Hi Vis panels and reflective piping for enhanced visibility, <br>Fly fastening closed with plastic button and zipper<\/p>"
    },
    {
        Id:176,
        rankingId:null,
        name:"Motion Trousers",
        description:"<p>Back and side pockets<br>Two leg pockets, one with mobile pocket, one with pen pocket<br>Waist closed with plastic button<br>All fasteners are plastic<\/p>"
    },
    {
        Id:178,
        rankingId:null,
        name:"Welders Apron",
        description:"<p>Siloxane apron with VT945 lining<br>Labels sewn to the lining of bib<br>Vt945 halter neck<br>Two VT945 waist ties<\/p><p>EN ISO 11611 (Class 1 &amp; 2)<\/p>"
    },
    {
        Id:179,
        rankingId:null,
        name:"Welders Coverall",
        description:"<p>Bartacks at stress points for extra strength<br>Two breast pockets with flap<br>Two flame retardant swing pockets and side access&nbsp;<br>Knee pad pockets with flaps fastened with concealed fr velcro<br>Two side swing pockets with access<br>Zip fastened front<br>Action back<br>EN ISO 11611 (Class 1 &amp; 2)<\/p>"
    },
    {
        Id:180,
        rankingId:null,
        name:"Welders Coverall Extra Protection",
        description:"<p>Bartacks at stress points for extra strength<br>Two breast pockets with flap<br>Siloxane reinforced and front, from above waist to just above crotch, no top stitching on Siloxane<br>Internal knee pad pockets with bottom entry envelope opening<br>Siloxane reinforced sleeves with Weldshield cuff (no top stitching on Siloxane) fastened with one heavy duty concealed stud (concealed male and female stud)<br>Zip fastened front<br>Action back<br>EN ISO 11611 (Class 1 &amp; 2)<\/p>"
    },
    {
        Id:181,
        rankingId:null,
        name:"Wiper - Dispenser Box & Frame (125) - Hi Tech - Green",
        description:"<p>Graphic industry<br>Storage Container: Self-closing lid&nbsp;<br>Foot pedal<br>Two wheels&nbsp;<br>1 metal lock<br>Clean and soiled in one solution&nbsp;<br>125 clean wipers per box&nbsp;<br>More boxes per frame possible<br>Movable&nbsp;<br>Anti-static<br>Wiper: High Quality&nbsp;<br>Highly absorbant<br>Multi purpose usage<br>Wipers in bag for dust-free storage<br>Wipers in bag for easy counting and take-away<\/p>"
    },
    {
        Id:182,
        rankingId:null,
        name:"Wiper - Dispenser Box & Frame (125) - Hi Tech - Red",
        description:"<p>Automotive industry<br>Storage Container: Self-closing lid&nbsp;<br>Foot pedal<br>Two wheels&nbsp;<br>1 metal lock<br>Clean and soiled in one solution&nbsp;<br>125 clean wipers per box&nbsp;<br>More boxes per frame possible<br>Movable&nbsp;<br>Anti-static<br>Wiper: High Quality&nbsp;<br>Highly absorbant<br>Multi purpose usage<br>Wipers in bag for dust-free storage<br>Wipers in bag for easy counting and take-away<\/p>"
    },
    {
        Id:183,
        rankingId:null,
        name:"Wiper - Dispenser Box & Frame (125) - Multi loose fill - Blue",
        description:"<p>Metal industry<br>Storage Container: Self-closing lid&nbsp;<br>Foot pedal<br>Two wheels&nbsp;<br>1 metal lock<br>Clean and soiled in one solution&nbsp;<br>125 clean wipers per box&nbsp;<br>More boxes per frame possible<br>Movable&nbsp;<br>Anti-static<br>Wiper: High Quality&nbsp;<br>Highly absorbant<br>Multi purpose usage<br>Wipers in bag for dust-free storage<br>Wipers in bag for easy counting and take-away<\/p>"
    },
    {
        Id:184,
        rankingId:null,
        name:"Wiper - Dispenser Box & Frame (125) - Multi loose fill - Green",
        description:"<p>Graphic industry<br>Storage Container: Self-closing lid&nbsp;<br>Foot pedal<br>Two wheels&nbsp;<br>1 metal lock<br>Clean and soiled in one solution&nbsp;<br>125 clean wipers per box&nbsp;<br>More boxes per frame possible<br>Movable&nbsp;<br>Anti-static<br>Wiper: High Quality&nbsp;<br>Highly absorbant<br>Multi purpose usage<br>Wipers in bag for dust-free storage<br>Wipers in bag for easy counting and take-away<\/p>"
    },
    {
        Id:185,
        rankingId:null,
        name:"Wiper - Dispenser Box & Frame (125) - Multi loose fill - Red",
        description:"<p>Automotive industry<br>Storage Container: Self-closing lid&nbsp;<br>Foot pedal<br>Two wheels&nbsp;<br>1 metal lock<br>Clean and soiled in one solution&nbsp;<br>125 clean wipers per box&nbsp;<br>More boxes per frame possible<br>Movable&nbsp;<br>Anti-static<br>Wiper: High Quality&nbsp;<br>Highly absorbant<br>Multi purpose usage<br>Wipers in bag for dust-free storage<br>Wipers in bag for easy counting and take-away<\/p>"
    },
    {
        Id:186,
        rankingId:null,
        name:"Motion Zip Pocket Jacket",
        description:"<p>Front closed with hidden zipper<br>Stand up collar<br>Side pockets closed with fastener<br>Chest pockets closed with zipper, right side with mobile pocket, left side with D-ring<br>Pen pocket at left sleeve<br>Waist adjustable with straps<br>All fasteners are plastic<\/p>"
    },
    {
        Id:187,
        rankingId:null,
        name:" Classic Brown Mat 85x150",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:188,
        rankingId:null,
        name:" Classic Blue Mat 85x150",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:189,
        rankingId:null,
        name:"WOM Original Mink Mat 85x150",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:190,
        rankingId:null,
        name:"  Classic Light Grey Mat 85x150",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:192,
        rankingId:null,
        name:"Classic Brown Mat 85x300",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:193,
        rankingId:null,
        name:"Classic Blue Mat 85x300",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:194,
        rankingId:null,
        name:" WOM Original Mink Mat 85x300",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:195,
        rankingId:null,
        name:"Classic Light Grey Mat 85x300",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:197,
        rankingId:null,
        name:"Classic Brown Mat 115x180",
        description:"<p>The most efficient mat there is\u200b.&nbsp;Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance.&nbsp;A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions.&nbsp;Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:198,
        rankingId:null,
        name:"Classic Blue Mat 115x180",
        description:"<p>The most efficient mat there is\u200b.&nbsp;Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance.&nbsp;A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions.&nbsp;Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:199,
        rankingId:null,
        name:"WOM Original Mink Mat 115x180",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:200,
        rankingId:null,
        name:"Classic Light Grey Mat 115x180",
        description:"<p>The most efficient mat there is\u200b. <br>Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. <br>A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. <br>Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:202,
        rankingId:null,
        name:"Classic Brown Mat 150x250",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:203,
        rankingId:null,
        name:"Classic Blue Mat 150x250",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:204,
        rankingId:null,
        name:" WOM Original Mink Mat 150x250",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:205,
        rankingId:null,
        name:"Classic Light Grey Mat 150x250",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:207,
        rankingId:null,
        name:"Micromix Graphite Grey Mat 85x150",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.<\/p>"
    },
    {
        Id:208,
        rankingId:null,
        name:"Micromix Graphite Grey Mat 85x300",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:209,
        rankingId:null,
        name:"Micromix Graphite Grey Mat 115x200",
        description:"<p>The most efficient mat there is\u200b ur popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:210,
        rankingId:null,
        name:"Micromix Graphite Grey Mat 150x250",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton<\/p>"
    },
    {
        Id:211,
        rankingId:null,
        name:"Anti Fatigue Mat 84x139",
        description:"<p>Prevents fatigue of joints and muscles<br>Unique cushion nitrile rubber compound<br>Available in 4 standard sizes<br>Resistant to grease, perfect for heavy duty industrial and commercial uses<\/p>"
    },
    {
        Id:212,
        rankingId:null,
        name:"Anti Fatigue Mat 119x178",
        description:"<p>Prevents fatigue of joints and muscles<br>Unique cushion nitrile rubber compound<br>Available in 4 standard sizes<br>Resistant to grease, perfect for heavy duty industrial and commercial uses<\/p>"
    },
    {
        Id:213,
        rankingId:null,
        name:"Anti Fatigue Mat 84x297",
        description:"<p>Prevents fatigue of joints and muscles<br>Unique cushion nitrile rubber compound<br>Available in 4 standard sizes<br>Resistant to grease, perfect for heavy duty industrial and commercial uses<\/p>"
    },
    {
        Id:214,
        rankingId:null,
        name:"Ultra Flow Mat 85x140",
        description:"<p>Provides comfort to the users feet, legs and back, with a durable surface texture, designed to prevent slipping in wet areas. <br>Product permits drainage and air circulation<\/p>"
    },
    {
        Id:215,
        rankingId:null,
        name:"Heavy Duty Scraper Mat 85x150",
        description:"<p>Acts as a tough first line of defence in all weather conditions. Made from strong solution dyed monfilament yarns. To remove heavy deposits of mud, snow, sand and grit<\/p>"
    },
    {
        Id:218,
        rankingId:null,
        name:"Wash Hands Mat 200X200",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:219,
        rankingId:null,
        name:"Small semi-circle mat - Mink Mat 42x85",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton. &nbsp;<\/p>"
    },
    {
        Id:220,
        rankingId:null,
        name:"Medium semi-circle mat - Mink Mat 57x115",
        description:"<p>The most efficient mat there is\u200b. Our popular, highly absorbent mat with microfibre top and a unique UltraGrip underside which delivers unrivalled performance. A mat with superior ability to absorb both wet and dry dirt while staying in its place in all weather conditions. Elis' new MicroTech mat combines the best features of nylon and cotton.&nbsp;<\/p>"
    },
    {
        Id:221,
        rankingId:null,
        name:"Small semi-circle mat - Elis logo Mat 42x85",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:222,
        rankingId:null,
        name:"Medium semi-circle mat - Elis logo Mat 57x115",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:225,
        rankingId:null,
        name:" Comfort Flow Mat 60 x 90",
        description:"<p>Prevents fatigue of joints and muscles<br>Unique cushion nitrile rubber compound<br>Available in 4 standard sizes<br>Resistant to grease, perfect for heavy duty industrial and commercial uses<\/p>"
    },
    {
        Id:228,
        rankingId:null,
        name:"Logo Design Mat 85x150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers&nbsp;<\/p>"
    },
    {
        Id:229,
        rankingId:null,
        name:"N2074 - 85 x 150 (3' x 5') - LOGO MAT: PORTAL",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:234,
        rankingId:null,
        name:"N1462 - Logo DESIGN No. 85x300",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:235,
        rankingId:null,
        name:"N2075 - 85 x 300 (3' x 10') - LOGO MAT: PORTAL",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:236,
        rankingId:null,
        name:"N1461 - Logo DESIGN No. 115x180",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:237,
        rankingId:null,
        name:"N2076 - 115 x 180 (4' x 6') - LOGO MAT: PORTAL",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:239,
        rankingId:null,
        name:"Stop Spread Mat 115X180",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:240,
        rankingId:null,
        name:"Wash Hands Mat 115X180",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:241,
        rankingId:null,
        name:"Logo Design Mat 150x250",
        description:"<p>Over 150 colours available to bring your logo to life \u200b<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:242,
        rankingId:null,
        name:"N2077 - 150 x 250 (5' x 8') - LOGO MAT: PORTAL",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:243,
        rankingId:null,
        name:"Eye Protection Warning Portrait Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:244,
        rankingId:null,
        name:"Food\/Drink Warning Portrait Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:245,
        rankingId:null,
        name:"Forklift Trucks Operating Warning Portrait Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:246,
        rankingId:null,
        name:"Hair Nets Warning Portrait Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:247,
        rankingId:null,
        name:"Hard Hat Warning Portrait Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life \u200b<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:248,
        rankingId:null,
        name:"Hearing Protection Warning Portrait Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:249,
        rankingId:null,
        name:"High Visibility Clothing Warning Portrait Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:250,
        rankingId:null,
        name:"Highly Flammable Warning Portrait  Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:251,
        rankingId:null,
        name:"Mobile Phone Warning Portrait Mat 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers&nbsp;<\/p>"
    },
    {
        Id:252,
        rankingId:null,
        name:"Safety Footwear Warning Portrait 85 x 150",
        description:"<p>Over 150 colours available to bring your logo to life<br>Retains small dirt and dust while at the same time creating an appealing look around your building<br>Perfect for attracting the attention of visitors or potential customers<\/p>"
    },
    {
        Id:253,
        rankingId:null,
        name:"Table Cloth 70 x 144",
        description:"<p>Table cloth suitable for larger tables<br>Drapes nicely<br>Hard-wearing<\/p>"
    },
    {
        Id:254,
        rankingId:null,
        name:"Table Cloth 70 x 108",
        description:"<p>Table cloth suitable for medium sized tables<br>Drapes nicely&nbsp;<br>Hard-wearing<\/p>"
    },
    {
        Id:255,
        rankingId:null,
        name:"Napkin 20 x 20",
        description:"<p>Table napkin<br>Holds shape<br>Hard-wearing<\/p>"
    },
    {
        Id:256,
        rankingId:null,
        name:"Table Cloth 52x52",
        description:"<p>Square cloth<br>Drapes nicely<br>Hard-wearing<\/p>"
    },
    {
        Id:257,
        rankingId:null,
        name:"Table Cloth 70x70",
        description:"<p>Square cloth<br>Drapes nicely<br>Hard-wearing<\/p>"
    },
    {
        Id:258,
        rankingId:null,
        name:"Table Cloth 90x90",
        description:"<p>Square cloth<br>Drapes nicely<br>Hard-wearing<\/p>"
    },
    {
        Id:259,
        rankingId:null,
        name:"Aqualine Sanitiser Dispenser 650ml (No Touch)",
        description:"<p>Liquid sanitiser dispenser<br>Manual &amp; no touch versions available<br>Can be mounted to metal stand<\/p>"
    },
    {
        Id:260,
        rankingId:null,
        name:"Revision Trousers Class 2",
        description:"<p>Front closed with jeans button and zipper<br>Side and back pockets<br>Double folding ruler pocket at right back leg<br>Leg pocket with flap at left side with mobile pocket and pen holders<br>Bellows for maximum volume<br>Preshaped legs with reinforced knee pockets. and reinforced leg bottoms&nbsp;<\/p><p>EN ISO 20471:2013+A1:2016, class 2 EN 14404: 2004 + A1:2010, Type 2, Level 1&nbsp;<\/p>"
    },
    {
        Id:261,
        rankingId:null,
        name:"Motion Long Sleeve Polo Shirt",
        description:"<p>Neck plate for labelling. <br>Contrast side panels.<\/p>"
    },
    {
        Id:262,
        rankingId:null,
        name:"Sparkle Jacket Class 3",
        description:"<p>Front closed with hidden metal ring fasteners or *fasteners &amp; zip<br>High collar for maximum protection of the wearer&nbsp;<br>Side pockets with flap<br>Inside pockets, one closed with zipper and one with penholder<br>Chest pockets with yoke as flap<br>All pocket flaps closed with hidden metal ring fasteners<br>Flap construction enables easy access to pockets<br>Loop for ID-card holder inside left chest pocket<br>Multi function loop for gas detector at right chest<\/p><p>EN ISO 20471, class 2<br>ISO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1<br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:263,
        rankingId:null,
        name:"Sparkle Trousers Class 2",
        description:"<p>Front closed with hidden buttoning, metal zipper in fly<br>Side pockets. Back pockets with yoke as flap<br>Flap construction enables easy access to pockets<br>Double folding ruler pocket with flap and tool loops, at right leg<br>Leg pocket with flap, at left leg, outside mobile pocket and pen holder<br>All pocket flaps closed with hidden metal ring fastener<br>Preshaped knee part with inside knee pocket<\/p><p>EN ISO 20471, class 2<br>ISO 11612: 2015, A1\/A2, B1, C1, E2, F1<br>ISO 11611: 2015, class 1<br>EN 1149-5<br>IEC 61482-2, class 1 (4kA), ATPV 15,1 cal\/cm\u00b2<\/p>"
    },
    {
        Id:264,
        rankingId:null,
        name:"Revision Jacket Class 2",
        description:"<p>Front closed with zipper and hidden metal press fasteners<br>Side and front pockets with flap<br>Closed with hidden metal ring fasteners<br>Extra pocket and pen holder at left side<br>Bottom sleeve width adjustable with hidden metal press fasteners<br>Inside mobile pocket at right side with flap closed with hidden metal ring fastener<br>Loop for phone cable<br>Ergonomic construction - preshaped sleeves, longer in back<\/p><p>Hi-Visibility EN 20471<\/p>"
    },
    {
        Id:265,
        rankingId:null,
        name:" Pebble",
        description:"<p>A high quality, low carbon hand dryer equipped with an intelligent H13 media filter, helping to maintain hygienic protocol<br>It\u2019s digital brushless motor negates the need for maintenance, making it perfect for high footfall environments, whilst its secure Plug &amp; Play\u00ae functionality allows for easy installation, replacement, and servicing.<\/p>"
    },
    {
        Id:266,
        rankingId:null,
        name:"Food Trade Warm Area Long Sleeve Shirt",
        description:"<p>2 Piece yoke overlapping at neck&nbsp;<br>1 internal pocket on left breast as worn&nbsp;<br>Side vents&nbsp;<br>Stud adjustment at cuff<\/p>"
    },
    {
        Id:267,
        rankingId:null,
        name:"Industry Basic Jacket",
        description:"<p>Raglan Sleeves<br>3 concealed tud front fastening<br>2 mitred breast pockets with concealed stud fastening flaps<br>Bartack pen division in left pocket as worn<br>Side access swing pockets<\/p>"
    },
    {
        Id:268,
        rankingId:null,
        name:"Low Risk Coverall",
        description:"<p>Unisex food manufacturing coverall<br>Front opening<br>Concealed stud front fastening with top stud showing<br>Traditional collar<br>Raglan Sleeves<br>External pocket<\/p>"
    },
    {
        Id:269,
        rankingId:null,
        name:"Mix and Match Fleece",
        description:"<p>Zipped front pockets<br>Stand up collar<br>Front closed with zipper<br>Elastic ribbon around collar, sleeve opening and bottom&nbsp;edges<\/p>"
    },
    {
        Id:270,
        rankingId:null,
        name:"Core Sweatshirt",
        description:"<p>Crew neck&nbsp;<br>Set in sleeve<br>Open or welt bottom&nbsp;<br>Available in a range of colours<\/p>"
    }
]