export const menuSeed = [
    {
        Id:13,
        title:"Industry Basics",
        description:null,
        parentId:4,
        children:false
    },
    {
        Id:15,
        title:"Work",
        description:null,
        parentId:4,
        children:false
    },
    {
        Id:37,
        title:"Motion",
        description:null,
        parentId:4,
        children:false
    },
    {
        Id:4,
        title:"Industrial",
        description:"Elis\u2019s workwear solutions are made to survive the harsh demands of industrial environments. Designed with safety in mind, our garments are tough and durable\u2026without compromising on comfort. Our range includes basic essentials, right through to highly functional and specialised items for bespoke applications.",
        parentId:1,
        children:true
    },
    {
        Id:5,
        title:"Food Manufacturing",
        description:"We offer a bespoke range of garment combinations designed to meet your specific food manufacturing requirements, keeping wearers safe in the workplace and ensuring optimum levels of hygiene at all times. Our styles can be supplied for use in low, high and maximum-risk areas, ensuring compliance with relevant industry standards.",
        parentId:1,
        children:false
    },
    {
        Id:8,
        title:"Petro-Chem",
        description:null,
        parentId:6,
        children:false
    },
    {
        Id:9,
        title:"Utility Service",
        description:null,
        parentId:6,
        children:false
    },
    {
        Id:10,
        title:"Extra",
        description:null,
        parentId:6,
        children:false
    },
    {
        Id:11,
        title:"Light Engineering",
        description:null,
        parentId:6,
        children:false
    },
    {
        Id:12,
        title:"Heavy Mechanical Industry",
        description:null,
        parentId:6,
        children:false
    },
    {
        Id:14,
        title:"Transport",
        description:null,
        parentId:6,
        children:false
    },
    {
        Id:17,
        title:"Metal Foundries & Smelters",
        description:null,
        parentId:6,
        children:false
    },
    {
        Id:6,
        title:"PPE",
        description:"Ensure maximum protection levels for your workforce by choosing from Elis\u2019s wide range of PPE garments. Our solutions are suitable for multiple end-uses, offering protection against fire, molten metals, chemicals and many other hazardous substances.",
        parentId:1,
        children:true
    },
    {
        Id:7,
        title:"Catering",
        description:"Let us take the hassle out of keeping your chef whites pristine. At Elis, we have a large choice of items suitable for your kitchen and front of house staff. From executive chef whites and aprons to professional serving staff attire, we\u2019ve got you covered.",
        parentId:1,
        children:false
    },
    {
        Id:36,
        title:"Linen",
        description:null,
        parentId:1,
        children:false
    },
    {
        Id:1,
        title:"Workwear",
        description:"Our workwear collections can be customised to your exact requirements as our end-to-end supply chain process provides us with in-house design, manufacturing teams and total control over our supply chain.",
        parentId:0,
        children:true
    },
    {
        Id:22,
        title:"Hand Drying",
        description:null,
        parentId:21,
        children:false
    },
    {
        Id:23,
        title:"Hand Hygiene",
        description:null,
        parentId:21,
        children:false
    },
    {
        Id:24,
        title:"Air Care",
        description:null,
        parentId:21,
        children:false
    },
    {
        Id:25,
        title:"Cubicle Care",
        description:null,
        parentId:21,
        children:false
    },
    {
        Id:26,
        title:"Disposal Services",
        description:null,
        parentId:21,
        children:false
    },
    {
        Id:27,
        title:"Urinal Care",
        description:null,
        parentId:21,
        children:false
    },
    {
        Id:21,
        title:"Washroom",
        description:"Elis is the leading provider of washroom services across Europe. We pride ourselves on delivering a comprehensive and reliable service. This includes a free initial no-obligation survey of your site to ensure our solutions are perfectly matched to your business requirements.",
        parentId:0,
        children:true
    },
    {
        Id:28,
        title:"Wipers",
        description:"Elis\u2019s reusable industrial wipers are made from a minimum of 85% recycled cotton, providing a more sustainable alternative to disposable paper equivalents. Choose between our 125-piece stacked dispensers or 600-piece eco-bins, approved for hazardous goods packaging and fire safety control. Whichever option you select we will deliver and install this, providing a regular collection, washing and delivery service to suit your needs.",
        parentId:0,
        children:false
    },
    {
        Id:30,
        title:"Classic Mats",
        description:null,
        parentId:29,
        children:false
    },
    {
        Id:31,
        title:"Micromix Mats",
        description:null,
        parentId:29,
        children:false
    },
    {
        Id:32,
        title:"Rubber Mats",
        description:null,
        parentId:29,
        children:false
    },
    {
        Id:33,
        title:"Scraper Mats",
        description:null,
        parentId:29,
        children:false
    },
    {
        Id:34,
        title:"Designed Mats",
        description:null,
        parentId:29,
        children:false
    },
    {
        Id:35,
        title:"Logo Mats",
        description:null,
        parentId:29,
        children:false
    },
    {
        Id:29,
        title:"Mats",
        description:"Elis mats are collected, cleaned and delivered to an agreed schedule, at a time and day specified by you. From entrance mats to anti-fatigue mats, our product portfolio includes solutions suitable for every business requirement, with highly absorbent fibres helping to keep your floors safe and clean.",
        parentId:0,
        children:true
    }
]