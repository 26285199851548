export const productAttributeSeed = [
    {
        Id:1,
        productId:1,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:1
    },
    {
        Id:2,
        productId:1,
        name:"Weight",
        data:"240GSM",
        position:2
    },
    {
        Id:3,
        productId:1,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:3
    },
    {
        Id:4,
        productId:1,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:4
    },
    {
        Id:10,
        productId:2,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:11,
        productId:2,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:12,
        productId:2,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:13,
        productId:2,
        name:"Sizing",
        data:"XS - 5XL",
        position:0
    },
    {
        Id:14,
        productId:3,
        name:"Length",
        data:"81cm",
        position:0
    },
    {
        Id:15,
        productId:3,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:16,
        productId:3,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:17,
        productId:3,
        name:"Sizing",
        data:"XS - 5XL",
        position:0
    },
    {
        Id:18,
        productId:4,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:19,
        productId:4,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:20,
        productId:4,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:21,
        productId:5,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:22,
        productId:5,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:23,
        productId:5,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:24,
        productId:5,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:25,
        productId:6,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:26,
        productId:6,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:27,
        productId:6,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:28,
        productId:6,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:29,
        productId:7,
        name:"Length",
        data:"81cm",
        position:0
    },
    {
        Id:30,
        productId:7,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:31,
        productId:7,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:32,
        productId:7,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:33,
        productId:8,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:34,
        productId:8,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:35,
        productId:8,
        name:"Sizing",
        data:"Regular and Tall 72 \/ 124",
        position:0
    },
    {
        Id:36,
        productId:9,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:37,
        productId:9,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:38,
        productId:9,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:39,
        productId:10,
        name:"Length",
        data:"120cm",
        position:0
    },
    {
        Id:40,
        productId:10,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:41,
        productId:10,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:42,
        productId:10,
        name:"Sizing",
        data:"XS - 5XL",
        position:0
    },
    {
        Id:43,
        productId:11,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:44,
        productId:11,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:45,
        productId:11,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:46,
        productId:11,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:47,
        productId:12,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:48,
        productId:12,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:49,
        productId:12,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:50,
        productId:12,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:51,
        productId:13,
        name:"Length",
        data:"81cm",
        position:0
    },
    {
        Id:52,
        productId:13,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:53,
        productId:13,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:54,
        productId:13,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:55,
        productId:14,
        name:"Length",
        data:"81cm",
        position:0
    },
    {
        Id:56,
        productId:14,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:57,
        productId:14,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:58,
        productId:14,
        name:"Sizing",
        data:"XS - 5XL",
        position:0
    },
    {
        Id:59,
        productId:15,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:60,
        productId:15,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:61,
        productId:15,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:62,
        productId:15,
        name:"Sizing",
        data:"XS - 5XL",
        position:0
    },
    {
        Id:63,
        productId:16,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:64,
        productId:16,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:65,
        productId:16,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:66,
        productId:17,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:67,
        productId:17,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:68,
        productId:17,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:69,
        productId:18,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:70,
        productId:18,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:71,
        productId:18,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:72,
        productId:19,
        name:"Length",
        data:"120cm",
        position:0
    },
    {
        Id:73,
        productId:19,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:74,
        productId:19,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:75,
        productId:19,
        name:"Sizing",
        data:"XS - 5XL",
        position:0
    },
    {
        Id:76,
        productId:20,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:77,
        productId:20,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:78,
        productId:20,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:79,
        productId:20,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:80,
        productId:21,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:81,
        productId:21,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:82,
        productId:21,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:83,
        productId:21,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:84,
        productId:22,
        name:"Length",
        data:"101cm",
        position:0
    },
    {
        Id:85,
        productId:22,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:86,
        productId:22,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:87,
        productId:22,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:88,
        productId:23,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:89,
        productId:23,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:90,
        productId:23,
        name:"Sizing",
        data:"72-140",
        position:0
    },
    {
        Id:91,
        productId:24,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:92,
        productId:24,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:93,
        productId:24,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:94,
        productId:25,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:95,
        productId:25,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:96,
        productId:25,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:97,
        productId:26,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:98,
        productId:26,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:99,
        productId:26,
        name:"Sizing",
        data:"Regular and Tall 92 \/ 100 \/ 108 \/ 116 \/ 124 \/ 132 \/ 140",
        position:0
    },
    {
        Id:100,
        productId:27,
        name:"Composition",
        data:"60% Polyester \/ 40% Cotton",
        position:0
    },
    {
        Id:101,
        productId:27,
        name:"Weight",
        data:"215GSM",
        position:0
    },
    {
        Id:102,
        productId:27,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:103,
        productId:27,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:104,
        productId:28,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:0
    },
    {
        Id:105,
        productId:28,
        name:"Weight",
        data:"215GSM",
        position:0
    },
    {
        Id:106,
        productId:28,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:107,
        productId:28,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:108,
        productId:29,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:109,
        productId:29,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:110,
        productId:29,
        name:"Sizing",
        data:"One Size",
        position:0
    },
    {
        Id:111,
        productId:29,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:112,
        productId:30,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:113,
        productId:30,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:114,
        productId:30,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:115,
        productId:31,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:116,
        productId:31,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:117,
        productId:31,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:118,
        productId:32,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:0
    },
    {
        Id:119,
        productId:32,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:120,
        productId:32,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:121,
        productId:33,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:122,
        productId:33,
        name:"Weight",
        data:"200GSM",
        position:0
    },
    {
        Id:123,
        productId:33,
        name:"Sizing",
        data:"XXS - 4XL",
        position:0
    },
    {
        Id:124,
        productId:34,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:125,
        productId:34,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:126,
        productId:34,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:127,
        productId:34,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:128,
        productId:35,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:129,
        productId:35,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:130,
        productId:35,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:131,
        productId:35,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:132,
        productId:36,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:133,
        productId:36,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:134,
        productId:36,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:135,
        productId:36,
        name:"Fabric",
        data:"Oeko-Tex standard 101",
        position:0
    },
    {
        Id:136,
        productId:37,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:137,
        productId:37,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:138,
        productId:37,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:139,
        productId:38,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:140,
        productId:38,
        name:"Weight",
        data:"175GSM",
        position:0
    },
    {
        Id:141,
        productId:38,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:142,
        productId:39,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:143,
        productId:39,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:144,
        productId:39,
        name:"Sizing",
        data:"XS \u2013 4XL+ long",
        position:0
    },
    {
        Id:145,
        productId:40,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:146,
        productId:40,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:147,
        productId:40,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:148,
        productId:40,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:149,
        productId:41,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:150,
        productId:41,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:151,
        productId:41,
        name:"Sizing",
        data:"Reg \/ Short \/ Long XS-4XL",
        position:0
    },
    {
        Id:152,
        productId:41,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:153,
        productId:42,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:154,
        productId:42,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:155,
        productId:42,
        name:"Sizing",
        data:"XS \u2013 4XL+ long",
        position:0
    },
    {
        Id:156,
        productId:43,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:157,
        productId:43,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:158,
        productId:43,
        name:"Sizing",
        data:"XS \u2013 4XL+ long",
        position:0
    },
    {
        Id:159,
        productId:44,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:160,
        productId:44,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:161,
        productId:44,
        name:"Sizing",
        data:"One size: length 110 cm, width 76 cm",
        position:0
    },
    {
        Id:162,
        productId:44,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:163,
        productId:45,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:164,
        productId:45,
        name:"Weight",
        data:"210GSM",
        position:0
    },
    {
        Id:165,
        productId:45,
        name:"Sizing",
        data:"One size: length 60 cm,width 90 cm",
        position:0
    },
    {
        Id:166,
        productId:45,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:167,
        productId:46,
        name:"Composition",
        data:"100% Polyester",
        position:0
    },
    {
        Id:168,
        productId:46,
        name:"Weight",
        data:"200GSM",
        position:0
    },
    {
        Id:169,
        productId:46,
        name:"Sizing",
        data:"One size",
        position:0
    },
    {
        Id:170,
        productId:47,
        name:"Composition",
        data:"100% Polyester",
        position:0
    },
    {
        Id:171,
        productId:47,
        name:"Sizing",
        data:"S\/M\/L\/XL",
        position:0
    },
    {
        Id:172,
        productId:48,
        name:"Composition",
        data:"60% Polyester \/ 40% Cotton",
        position:0
    },
    {
        Id:173,
        productId:48,
        name:"Weight",
        data:"210 - 220GSM",
        position:0
    },
    {
        Id:174,
        productId:48,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:175,
        productId:48,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:176,
        productId:49,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:0
    },
    {
        Id:177,
        productId:49,
        name:"Weight",
        data:"210 - 220GSM",
        position:0
    },
    {
        Id:178,
        productId:49,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:179,
        productId:49,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:180,
        productId:50,
        name:"Dimensions",
        data:"L 110mm W 118mm H 265mm",
        position:0
    },
    {
        Id:181,
        productId:51,
        name:"Dimensions",
        data:"L 110mm W 118mm H 265mm",
        position:0
    },
    {
        Id:182,
        productId:52,
        name:"Dimensions",
        data:"L 127mm W 118mm H 285mm",
        position:0
    },
    {
        Id:183,
        productId:53,
        name:"Dimensions",
        data:"L 127mm W 118mm H 285mm",
        position:0
    },
    {
        Id:184,
        productId:54,
        name:"Dimensions",
        data:"L 110mm W 118mm H 275mm",
        position:0
    },
    {
        Id:185,
        productId:55,
        name:"Dimensions",
        data:"L 165mm W 115mm H 335mm",
        position:0
    },
    {
        Id:186,
        productId:56,
        name:"Dimensions",
        data:"L 174mm W 153mm H 450mm",
        position:0
    },
    {
        Id:187,
        productId:57,
        name:"Dimensions",
        data:"L 205mm W 180mm H 287mm",
        position:0
    },
    {
        Id:188,
        productId:58,
        name:"Dimensions",
        data:"L 110mm W 118mm H 265mm",
        position:0
    },
    {
        Id:189,
        productId:59,
        name:"Dimensions",
        data:"L 110mm W 118mm H 265mm",
        position:0
    },
    {
        Id:190,
        productId:60,
        name:"Dimensions",
        data:"L 127mm W 118mm H 285mm",
        position:0
    },
    {
        Id:191,
        productId:61,
        name:"Dimensions",
        data:"L 250mm W 253mm H 310mm",
        position:0
    },
    {
        Id:192,
        productId:62,
        name:"Dimensions",
        data:"L 311mm W 230mm H 354mm",
        position:0
    },
    {
        Id:193,
        productId:63,
        name:"Dimensions",
        data:"L 410mm W 225mm H 475mm",
        position:0
    },
    {
        Id:194,
        productId:64,
        name:"Dimensions",
        data:"L 410mm W 257mm H 502mm",
        position:0
    },
    {
        Id:195,
        productId:65,
        name:"Dimensions",
        data:"L 410mm W 257mm H 502mm",
        position:0
    },
    {
        Id:196,
        productId:66,
        name:"Dimensions",
        data:"L 100mm W 234mm H 394mm",
        position:0
    },
    {
        Id:197,
        productId:67,
        name:"Dimensions",
        data:"L 100mm W 450mm H 500mm",
        position:0
    },
    {
        Id:198,
        productId:68,
        name:"Dimensions",
        data:"L 312mm W 303mm",
        position:0
    },
    {
        Id:199,
        productId:69,
        name:"Dimensions",
        data:"L 286mm W 303mm H 159mm",
        position:0
    },
    {
        Id:200,
        productId:70,
        name:"Dimensions",
        data:"L 286mm W 303mm H 309mm",
        position:0
    },
    {
        Id:201,
        productId:71,
        name:"Dimensions",
        data:"L 74mm W 73mm H 150mm",
        position:0
    },
    {
        Id:202,
        productId:72,
        name:"Dimensions",
        data:"L 155mm W 86mm H 200mm",
        position:0
    },
    {
        Id:203,
        productId:73,
        name:"Dimensions",
        data:"L 412mm W 132mm H 93mm",
        position:0
    },
    {
        Id:204,
        productId:74,
        name:"Dimensions",
        data:"L 510mm W 160mm H 550mm",
        position:0
    },
    {
        Id:205,
        productId:75,
        name:"Dimensions",
        data:"L 540mm W 160mm H 550mm",
        position:0
    },
    {
        Id:206,
        productId:76,
        name:"Dimensions",
        data:"L 122mm W 33mm H 375mm",
        position:0
    },
    {
        Id:207,
        productId:77,
        name:"Dimensions",
        data:"L 435mm W 370mm H 658mm",
        position:0
    },
    {
        Id:208,
        productId:78,
        name:"Dimensions",
        data:"L 313mm W 135mm H 295mm",
        position:0
    },
    {
        Id:209,
        productId:79,
        name:"Dimensions",
        data:"L 420mm W 135mm H 295mm",
        position:0
    },
    {
        Id:210,
        productId:80,
        name:"Dimensions",
        data:"L 310mm W 143mm H 197mm",
        position:0
    },
    {
        Id:211,
        productId:81,
        name:"Dimensions",
        data:"L 110mm W 118mm H 285mm",
        position:0
    },
    {
        Id:212,
        productId:82,
        name:"Dimensions",
        data:"L 127mm W 118mm H 275mm",
        position:0
    },
    {
        Id:213,
        productId:83,
        name:"Dimensions",
        data:"L 130mm W 130mm H 285mm",
        position:0
    },
    {
        Id:214,
        productId:87,
        name:"Composition",
        data:"100% Aramid knittied, FR silicone coating",
        position:0
    },
    {
        Id:215,
        productId:87,
        name:"Weight",
        data:"545GSM",
        position:0
    },
    {
        Id:216,
        productId:87,
        name:"Sizing",
        data:"M",
        position:0
    },
    {
        Id:217,
        productId:88,
        name:"Composition",
        data:"54% MA Protex \/ 44% Cotton \/ 2% Antistatic",
        position:0
    },
    {
        Id:218,
        productId:88,
        name:"Weight",
        data:"190GSM",
        position:0
    },
    {
        Id:219,
        productId:88,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:220,
        productId:89,
        name:"Composition",
        data:"54% MA Protex \/ 44% Cotton \/ 2% Antistatic",
        position:0
    },
    {
        Id:221,
        productId:89,
        name:"Weight",
        data:"190GSM",
        position:0
    },
    {
        Id:222,
        productId:89,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:223,
        productId:90,
        name:"Composition",
        data:"54% MA Protex \/ 44% Cotton \/ 2% Antistatic",
        position:0
    },
    {
        Id:224,
        productId:90,
        name:"Weight",
        data:"190GSM",
        position:0
    },
    {
        Id:225,
        productId:90,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:226,
        productId:91,
        name:"Composition",
        data:"54% MA Protex \/ 44% Cotton \/ 2% Antistatic",
        position:0
    },
    {
        Id:227,
        productId:91,
        name:"Weight",
        data:"190GSM",
        position:0
    },
    {
        Id:228,
        productId:91,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:229,
        productId:93,
        name:"Composition",
        data:"100% Polyester",
        position:0
    },
    {
        Id:230,
        productId:93,
        name:"Weight",
        data:"240GSM",
        position:0
    },
    {
        Id:231,
        productId:93,
        name:"Sizing",
        data:"One size, 92cm length bib to hem",
        position:0
    },
    {
        Id:232,
        productId:93,
        name:"Fabric",
        data:"Weave: 2\/1 twill, Crease resist",
        position:0
    },
    {
        Id:233,
        productId:100,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:234,
        productId:100,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:235,
        productId:100,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:236,
        productId:100,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:237,
        productId:101,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:238,
        productId:101,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:239,
        productId:101,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:240,
        productId:102,
        name:"Composition",
        data:"65% cotton \/ 35% polyester >80% recycled yarns",
        position:0
    },
    {
        Id:241,
        productId:102,
        name:"Weight",
        data:"45 GSM",
        position:0
    },
    {
        Id:242,
        productId:102,
        name:"Sizing",
        data:"Wiper: 39cm x 35cm",
        position:0
    },
    {
        Id:243,
        productId:102,
        name:"Fabric",
        data:"Poly \/ cotton",
        position:0
    },
    {
        Id:244,
        productId:103,
        name:"Composition",
        data:"65% cotton \/ 35% polyester >80% recycled yarns",
        position:0
    },
    {
        Id:245,
        productId:103,
        name:"Weight",
        data:"45 GSM",
        position:0
    },
    {
        Id:246,
        productId:103,
        name:"Sizing",
        data:"Wiper: 39cm x 35cm",
        position:0
    },
    {
        Id:247,
        productId:103,
        name:"Fabric",
        data:"Poly \/ cotton",
        position:0
    },
    {
        Id:248,
        productId:104,
        name:"Composition",
        data:"65% cotton \/ 35% polyester >80% recycled yarns",
        position:0
    },
    {
        Id:249,
        productId:104,
        name:"Weight",
        data:"45 GSM",
        position:0
    },
    {
        Id:250,
        productId:104,
        name:"Sizing",
        data:"Wiper: 39cm x 35cm",
        position:0
    },
    {
        Id:251,
        productId:104,
        name:"Fabric",
        data:"Poly \/ cotton",
        position:0
    },
    {
        Id:252,
        productId:105,
        name:"Composition",
        data:"93% meta-aramid \/ 5% para-aramid \/ 2% antistatic fiber",
        position:0
    },
    {
        Id:253,
        productId:105,
        name:"Weight",
        data:"260GSM",
        position:0
    },
    {
        Id:254,
        productId:105,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:255,
        productId:106,
        name:"Composition",
        data:"93% meta-aramid \/ 5% para-aramid \/  2% antistatic fiber",
        position:0
    },
    {
        Id:256,
        productId:106,
        name:"Weight",
        data:"260GSM",
        position:0
    },
    {
        Id:257,
        productId:106,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:258,
        productId:107,
        name:"Composition",
        data:"93% meta-aramid \/ 5% para-aramid \/ 2% antistatic fiber",
        position:0
    },
    {
        Id:259,
        productId:107,
        name:"Weight",
        data:"260GSM",
        position:0
    },
    {
        Id:260,
        productId:107,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:261,
        productId:108,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:262,
        productId:108,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:263,
        productId:108,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:264,
        productId:109,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:265,
        productId:109,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:266,
        productId:109,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:267,
        productId:110,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:268,
        productId:110,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:269,
        productId:110,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:270,
        productId:111,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:271,
        productId:111,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:272,
        productId:111,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:273,
        productId:112,
        name:"Composition",
        data:"100% FR Cotton",
        position:0
    },
    {
        Id:274,
        productId:112,
        name:"Weight",
        data:"385GSM",
        position:0
    },
    {
        Id:275,
        productId:112,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:276,
        productId:113,
        name:"Composition",
        data:"100% FR Cotton",
        position:0
    },
    {
        Id:277,
        productId:113,
        name:"Weight",
        data:"385GSM",
        position:0
    },
    {
        Id:278,
        productId:113,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:279,
        productId:114,
        name:"Composition",
        data:"100% FR Cotton",
        position:0
    },
    {
        Id:280,
        productId:114,
        name:"Weight",
        data:"385GSM",
        position:0
    },
    {
        Id:281,
        productId:114,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:282,
        productId:115,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:283,
        productId:115,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:284,
        productId:115,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:285,
        productId:116,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:286,
        productId:116,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:287,
        productId:116,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:288,
        productId:117,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:289,
        productId:117,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:290,
        productId:117,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:291,
        productId:119,
        name:"Composition",
        data:"67% Polyester, 33% Cotton",
        position:0
    },
    {
        Id:292,
        productId:119,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:293,
        productId:119,
        name:"Sizing",
        data:"72-140",
        position:0
    },
    {
        Id:294,
        productId:120,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:295,
        productId:120,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:296,
        productId:120,
        name:"Sizing",
        data:"72-140",
        position:0
    },
    {
        Id:297,
        productId:121,
        name:"Composition",
        data:"100%  Polyester",
        position:0
    },
    {
        Id:298,
        productId:121,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:299,
        productId:121,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:300,
        productId:122,
        name:"Composition",
        data:"100%  Polyester",
        position:0
    },
    {
        Id:301,
        productId:122,
        name:"Weight",
        data:"225GSM",
        position:0
    },
    {
        Id:302,
        productId:122,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:303,
        productId:123,
        name:"Composition",
        data:"100%  Polyester",
        position:0
    },
    {
        Id:304,
        productId:123,
        name:"Weight",
        data:"225GSM",
        position:0
    },
    {
        Id:305,
        productId:123,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:306,
        productId:124,
        name:"Composition",
        data:"60% Polyester \/ 40% cotton",
        position:0
    },
    {
        Id:307,
        productId:124,
        name:"Weight",
        data:"320GSM",
        position:0
    },
    {
        Id:308,
        productId:124,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:309,
        productId:125,
        name:"Composition",
        data:"70% Polyester .\/ 30% Cotton ",
        position:0
    },
    {
        Id:310,
        productId:125,
        name:"Weight",
        data:"240GSM",
        position:0
    },
    {
        Id:311,
        productId:125,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:312,
        productId:126,
        name:"Composition",
        data:"70% Polyester \/ 30% Cotton ",
        position:0
    },
    {
        Id:313,
        productId:126,
        name:"Weight",
        data:"240GSM",
        position:0
    },
    {
        Id:314,
        productId:126,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:315,
        productId:127,
        name:"Composition",
        data:"70% Polyester \/ 30% Cotton ",
        position:0
    },
    {
        Id:316,
        productId:127,
        name:"Weight",
        data:"240GSM",
        position:0
    },
    {
        Id:317,
        productId:127,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:318,
        productId:128,
        name:"Composition",
        data:"70% Polyester .\/ 30% Cotton ",
        position:0
    },
    {
        Id:319,
        productId:128,
        name:"Weight",
        data:"240GSM",
        position:0
    },
    {
        Id:320,
        productId:128,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:321,
        productId:129,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:322,
        productId:129,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:323,
        productId:129,
        name:"Sizing",
        data:"92-140",
        position:0
    },
    {
        Id:324,
        productId:130,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:325,
        productId:130,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:326,
        productId:130,
        name:"Sizing",
        data:"Regular and Tall 92 \/ 100 \/ 108 \/ 116 \/ 124 \/ 132 \/ 140",
        position:0
    },
    {
        Id:327,
        productId:131,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:328,
        productId:131,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:329,
        productId:131,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:330,
        productId:132,
        name:"Composition",
        data:"85% Polyester \/ 15% Cotton ",
        position:0
    },
    {
        Id:331,
        productId:132,
        name:"Weight",
        data:"315GSM",
        position:0
    },
    {
        Id:332,
        productId:132,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:333,
        productId:133,
        name:"Composition",
        data:"85% Polyester \/ 15% Cotton ",
        position:0
    },
    {
        Id:334,
        productId:133,
        name:"Weight",
        data:"315GSM",
        position:0
    },
    {
        Id:335,
        productId:133,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:336,
        productId:134,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:0
    },
    {
        Id:337,
        productId:134,
        name:"Weight",
        data:"240GSM",
        position:0
    },
    {
        Id:338,
        productId:134,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:339,
        productId:134,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:340,
        productId:138,
        name:"Composition",
        data:"54% ORAN-FR\/45% cellulosic fiber\/ 1% antistatic",
        position:0
    },
    {
        Id:341,
        productId:138,
        name:"Weight",
        data:"300GSM",
        position:0
    },
    {
        Id:342,
        productId:138,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:343,
        productId:139,
        name:"Composition",
        data:"54% ORAN-FR\/45% cellulosic fiber\/ 1% antistatic",
        position:0
    },
    {
        Id:344,
        productId:139,
        name:"Weight",
        data:"300GSM",
        position:0
    },
    {
        Id:345,
        productId:139,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:346,
        productId:140,
        name:"Composition",
        data:"54% ORAN-FR\/45% cellulosic fiber\/ 1% antistatic",
        position:0
    },
    {
        Id:347,
        productId:140,
        name:"Weight",
        data:"385GSM",
        position:0
    },
    {
        Id:348,
        productId:140,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:349,
        productId:141,
        name:"Composition",
        data:"54% ORAN-FR\/45% cellulosic fiber\/ 1% antistatic",
        position:0
    },
    {
        Id:350,
        productId:141,
        name:"Weight",
        data:"300GSM",
        position:0
    },
    {
        Id:351,
        productId:141,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:352,
        productId:142,
        name:"Composition",
        data:"54% ORAN-FR\/45% cellulosic fiber\/ 1% antistatic",
        position:0
    },
    {
        Id:353,
        productId:142,
        name:"Weight",
        data:"300GSM",
        position:0
    },
    {
        Id:354,
        productId:142,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:355,
        productId:143,
        name:"Composition",
        data:"54% ORAN-FR\/45% cellulosic fiber\/ 1% antistatic",
        position:0
    },
    {
        Id:356,
        productId:143,
        name:"Weight",
        data:"300GSM",
        position:0
    },
    {
        Id:357,
        productId:143,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:358,
        productId:144,
        name:"Composition",
        data:"54% ORAN-FR\/45% cellulosic fiber\/ 1% antistatic",
        position:0
    },
    {
        Id:359,
        productId:144,
        name:"Weight",
        data:"300GSM",
        position:0
    },
    {
        Id:360,
        productId:144,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:361,
        productId:145,
        name:"Composition",
        data:"54% ORAN-FR\/45% cellulosic fiber\/ 1% antistatic",
        position:0
    },
    {
        Id:362,
        productId:145,
        name:"Weight",
        data:"300GSM",
        position:0
    },
    {
        Id:363,
        productId:145,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:364,
        productId:146,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:365,
        productId:146,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:366,
        productId:146,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:367,
        productId:146,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:368,
        productId:148,
        name:"Dimensions",
        data:"L 110mm W 118mm H 275mm",
        position:0
    },
    {
        Id:369,
        productId:149,
        name:"Dimensions",
        data:"L 110mm W 118mm H 265mm",
        position:0
    },
    {
        Id:370,
        productId:150,
        name:"Dimensions",
        data:"L 311mm W 230mm H 354mm",
        position:0
    },
    {
        Id:371,
        productId:151,
        name:"Dimensions",
        data:"L 110mm W 118mm H 265mm",
        position:0
    },
    {
        Id:372,
        productId:152,
        name:"Dimensions",
        data:"L 310mm W 143mm H 197mm",
        position:0
    },
    {
        Id:373,
        productId:153,
        name:"Dimensions",
        data:"L 410mm W 257mm H 502mm",
        position:0
    },
    {
        Id:374,
        productId:154,
        name:"Dimensions",
        data:"L 420mm W 135mm H 295mm",
        position:0
    },
    {
        Id:375,
        productId:155,
        name:"Dimensions",
        data:"L 510mm W 160mm H 550mm",
        position:0
    },
    {
        Id:376,
        productId:156,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:0
    },
    {
        Id:377,
        productId:156,
        name:"Weight",
        data:"240GSM",
        position:0
    },
    {
        Id:378,
        productId:156,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:379,
        productId:156,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:380,
        productId:157,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:0
    },
    {
        Id:381,
        productId:157,
        name:"Weight",
        data:"240GSM",
        position:0
    },
    {
        Id:382,
        productId:157,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:383,
        productId:157,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:384,
        productId:158,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:385,
        productId:158,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:386,
        productId:158,
        name:"Sizing",
        data:"XS-5XL",
        position:0
    },
    {
        Id:387,
        productId:166,
        name:"Composition",
        data:"85% Polyester \/ 15% Cotton ",
        position:0
    },
    {
        Id:388,
        productId:166,
        name:"Weight",
        data:"315GSM",
        position:0
    },
    {
        Id:389,
        productId:166,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:390,
        productId:167,
        name:"Composition",
        data:"85% Polyester \/ 15% Cotton ",
        position:0
    },
    {
        Id:391,
        productId:167,
        name:"Weight",
        data:"315GSM",
        position:0
    },
    {
        Id:392,
        productId:167,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:393,
        productId:168,
        name:"Composition",
        data:"85% Polyester \/ 15% Cotton ",
        position:0
    },
    {
        Id:394,
        productId:168,
        name:"Weight",
        data:"315GSM",
        position:0
    },
    {
        Id:395,
        productId:168,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:396,
        productId:169,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:397,
        productId:169,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:398,
        productId:169,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:399,
        productId:169,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:400,
        productId:170,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:401,
        productId:170,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:402,
        productId:170,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:403,
        productId:171,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:404,
        productId:171,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:405,
        productId:171,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:406,
        productId:172,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:407,
        productId:172,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:408,
        productId:172,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:409,
        productId:173,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:0
    },
    {
        Id:410,
        productId:173,
        name:"Weight",
        data:"250GSM",
        position:0
    },
    {
        Id:411,
        productId:173,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:412,
        productId:173,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:413,
        productId:174,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:414,
        productId:174,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:415,
        productId:174,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:416,
        productId:174,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:417,
        productId:175,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:418,
        productId:175,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:419,
        productId:175,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:420,
        productId:176,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:421,
        productId:176,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:422,
        productId:176,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:423,
        productId:176,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:424,
        productId:181,
        name:"Composition",
        data:"100% Cotton",
        position:0
    },
    {
        Id:425,
        productId:181,
        name:"Weight",
        data:"45 GSM",
        position:0
    },
    {
        Id:426,
        productId:181,
        name:"Sizing",
        data:"Wiper: 39cm x 35cm",
        position:0
    },
    {
        Id:427,
        productId:181,
        name:"Fabric",
        data:"Cotton",
        position:0
    },
    {
        Id:428,
        productId:182,
        name:"Composition",
        data:"100% Cotton",
        position:0
    },
    {
        Id:429,
        productId:182,
        name:"Weight",
        data:"45 GSM",
        position:0
    },
    {
        Id:430,
        productId:182,
        name:"Sizing",
        data:"Wiper: 39cm x 35cm",
        position:0
    },
    {
        Id:431,
        productId:182,
        name:"Fabric",
        data:"Cotton",
        position:0
    },
    {
        Id:432,
        productId:183,
        name:"Composition",
        data:"65% cotton \/ 35% polyester >80% recycled yarns",
        position:0
    },
    {
        Id:433,
        productId:183,
        name:"Weight",
        data:"45 GSM",
        position:0
    },
    {
        Id:434,
        productId:183,
        name:"Sizing",
        data:"Wiper: 39cm x 35cm",
        position:0
    },
    {
        Id:435,
        productId:183,
        name:"Fabric",
        data:"Poly \/ cotton",
        position:0
    },
    {
        Id:436,
        productId:184,
        name:"Composition",
        data:"65% cotton \/ 35% polyester >80% recycled yarns",
        position:0
    },
    {
        Id:437,
        productId:184,
        name:"Weight",
        data:"45 GSM",
        position:0
    },
    {
        Id:438,
        productId:184,
        name:"Sizing",
        data:"Wiper: 39cm x 35cm",
        position:0
    },
    {
        Id:439,
        productId:184,
        name:"Fabric",
        data:"Poly \/ Cotton",
        position:0
    },
    {
        Id:440,
        productId:185,
        name:"Composition",
        data:"65% cotton \/ 35% polyester >80% recycled yarns",
        position:0
    },
    {
        Id:441,
        productId:185,
        name:"Weight",
        data:"45 GSM",
        position:0
    },
    {
        Id:442,
        productId:185,
        name:"Sizing",
        data:"Wiper: 39cm x 35cm",
        position:0
    },
    {
        Id:443,
        productId:185,
        name:"Fabric",
        data:"Poly \/ cotton",
        position:0
    },
    {
        Id:444,
        productId:186,
        name:"Composition",
        data:"65% Polyester \/ 35% Cotton",
        position:0
    },
    {
        Id:445,
        productId:186,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:446,
        productId:186,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:447,
        productId:186,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:448,
        productId:187,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:449,
        productId:187,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:450,
        productId:187,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:451,
        productId:188,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:452,
        productId:188,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:453,
        productId:188,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:454,
        productId:189,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:455,
        productId:189,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:456,
        productId:189,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:457,
        productId:190,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:458,
        productId:190,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:459,
        productId:190,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:460,
        productId:191,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:461,
        productId:191,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:462,
        productId:191,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:463,
        productId:192,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:464,
        productId:192,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:465,
        productId:192,
        name:"Sizing",
        data:"85x300 cm",
        position:0
    },
    {
        Id:466,
        productId:193,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:467,
        productId:193,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:468,
        productId:193,
        name:"Sizing",
        data:"85x300 cm",
        position:0
    },
    {
        Id:469,
        productId:194,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:470,
        productId:194,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:471,
        productId:194,
        name:"Sizing",
        data:"85x300 cm",
        position:0
    },
    {
        Id:472,
        productId:195,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:473,
        productId:195,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:474,
        productId:195,
        name:"Sizing",
        data:"85x300 cm",
        position:0
    },
    {
        Id:475,
        productId:196,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:476,
        productId:196,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:477,
        productId:196,
        name:"Sizing",
        data:"85x300 cm",
        position:0
    },
    {
        Id:478,
        productId:197,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:479,
        productId:197,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:480,
        productId:197,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:481,
        productId:198,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:482,
        productId:198,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:483,
        productId:198,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:484,
        productId:199,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:485,
        productId:199,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:486,
        productId:199,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:487,
        productId:200,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:488,
        productId:200,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:489,
        productId:200,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:490,
        productId:201,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:491,
        productId:201,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:492,
        productId:201,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:493,
        productId:202,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:494,
        productId:202,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:495,
        productId:202,
        name:"Sizing",
        data:"150x250 cm",
        position:0
    },
    {
        Id:496,
        productId:203,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:497,
        productId:203,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:498,
        productId:203,
        name:"Sizing",
        data:"150x250 cm",
        position:0
    },
    {
        Id:499,
        productId:204,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:500,
        productId:204,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:501,
        productId:204,
        name:"Sizing",
        data:"150x250 cm",
        position:0
    },
    {
        Id:502,
        productId:205,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:503,
        productId:205,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:504,
        productId:205,
        name:"Sizing",
        data:"150x250 cm",
        position:0
    },
    {
        Id:505,
        productId:206,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:506,
        productId:206,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:507,
        productId:206,
        name:"Sizing",
        data:"150x250 cm",
        position:0
    },
    {
        Id:508,
        productId:207,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:509,
        productId:207,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:510,
        productId:207,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:511,
        productId:208,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:512,
        productId:208,
        name:"Weight",
        data:"1900GSM Rubber \/ 980GSM Textile ",
        position:0
    },
    {
        Id:513,
        productId:208,
        name:"Sizing",
        data:"85X300 cm",
        position:0
    },
    {
        Id:514,
        productId:209,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:515,
        productId:209,
        name:"Weight",
        data:"1900GSM Rubber \/ 980GSM Textile ",
        position:0
    },
    {
        Id:516,
        productId:209,
        name:"Sizing",
        data:"115x200 cm",
        position:0
    },
    {
        Id:517,
        productId:210,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:518,
        productId:210,
        name:"Weight",
        data:"1900GSM Rubber \/ 980GSM Textile ",
        position:0
    },
    {
        Id:519,
        productId:210,
        name:"Sizing",
        data:"150x250 cm",
        position:0
    },
    {
        Id:520,
        productId:211,
        name:"Composition",
        data:"100% Nitrile Rubber",
        position:0
    },
    {
        Id:521,
        productId:211,
        name:"Weight",
        data:"3100GSM",
        position:0
    },
    {
        Id:522,
        productId:211,
        name:"Sizing",
        data:"84x139 cm",
        position:0
    },
    {
        Id:523,
        productId:212,
        name:"Composition",
        data:"100% Nitrile Rubber",
        position:0
    },
    {
        Id:524,
        productId:212,
        name:"Weight",
        data:"3100GSM",
        position:0
    },
    {
        Id:525,
        productId:212,
        name:"Sizing",
        data:"119x178 cm",
        position:0
    },
    {
        Id:526,
        productId:213,
        name:"Composition",
        data:"100% Nitrile Rubber",
        position:0
    },
    {
        Id:527,
        productId:213,
        name:"Weight",
        data:"3100GSM",
        position:0
    },
    {
        Id:528,
        productId:213,
        name:"Sizing",
        data:"84x297 cm",
        position:0
    },
    {
        Id:529,
        productId:214,
        name:"Composition",
        data:"100% Nitrile Rubber",
        position:0
    },
    {
        Id:530,
        productId:214,
        name:"Weight",
        data:"4230GSM",
        position:0
    },
    {
        Id:531,
        productId:214,
        name:"Sizing",
        data:"85x140 cm",
        position:0
    },
    {
        Id:532,
        productId:215,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl monofilament recycled nylon",
        position:0
    },
    {
        Id:533,
        productId:215,
        name:"Weight",
        data:"1900GSM Rubber \/ 780GSM Textile",
        position:0
    },
    {
        Id:534,
        productId:215,
        name:"Sizing",
        data:" 85x150 cm",
        position:0
    },
    {
        Id:535,
        productId:216,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl monofilament recycled nylon",
        position:0
    },
    {
        Id:536,
        productId:216,
        name:"Weight",
        data:"1900GSM Rubber \/ 780GSM Textile",
        position:0
    },
    {
        Id:537,
        productId:216,
        name:"Sizing",
        data:" 85 x 300 cm",
        position:0
    },
    {
        Id:538,
        productId:217,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl monofilament recycled nylon",
        position:0
    },
    {
        Id:539,
        productId:217,
        name:"Weight",
        data:"1900GSM Rubber \/ 780GSM Textile",
        position:0
    },
    {
        Id:540,
        productId:217,
        name:"Sizing",
        data:"115 X 200 cm",
        position:0
    },
    {
        Id:541,
        productId:218,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:542,
        productId:218,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:543,
        productId:218,
        name:"Sizing",
        data:"200X200 cm",
        position:0
    },
    {
        Id:544,
        productId:219,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:545,
        productId:219,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:546,
        productId:219,
        name:"Sizing",
        data:"42x85 cm",
        position:0
    },
    {
        Id:547,
        productId:220,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:548,
        productId:220,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:549,
        productId:220,
        name:"Sizing",
        data:"57x115 cm",
        position:0
    },
    {
        Id:550,
        productId:221,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:551,
        productId:221,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:552,
        productId:221,
        name:"Sizing",
        data:"42x85 cm",
        position:0
    },
    {
        Id:553,
        productId:222,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:554,
        productId:222,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:555,
        productId:222,
        name:"Sizing",
        data:"57x115 cm",
        position:0
    },
    {
        Id:556,
        productId:223,
        name:"Sizing",
        data:"60x90 cm",
        position:0
    },
    {
        Id:557,
        productId:224,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:558,
        productId:225,
        name:"Sizing",
        data:"60x90 cm",
        position:0
    },
    {
        Id:559,
        productId:226,
        name:"Sizing",
        data:"86x142 cm",
        position:0
    },
    {
        Id:560,
        productId:227,
        name:"Sizing",
        data:"86x277 cm",
        position:0
    },
    {
        Id:561,
        productId:228,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:562,
        productId:228,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:563,
        productId:228,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:564,
        productId:229,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:565,
        productId:229,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:566,
        productId:229,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:567,
        productId:230,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:568,
        productId:230,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:569,
        productId:230,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:570,
        productId:231,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:571,
        productId:231,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:572,
        productId:231,
        name:"Sizing",
        data:"80x145cm",
        position:0
    },
    {
        Id:573,
        productId:232,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:574,
        productId:232,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:575,
        productId:232,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:576,
        productId:233,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:577,
        productId:233,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:578,
        productId:233,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:579,
        productId:234,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:580,
        productId:234,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:581,
        productId:234,
        name:"Sizing",
        data:"85x300 cm",
        position:0
    },
    {
        Id:582,
        productId:235,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:583,
        productId:235,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:584,
        productId:235,
        name:"Sizing",
        data:"85x300 cm",
        position:0
    },
    {
        Id:585,
        productId:236,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:586,
        productId:236,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:587,
        productId:236,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:588,
        productId:237,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:589,
        productId:237,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:590,
        productId:237,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:591,
        productId:238,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:592,
        productId:238,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:593,
        productId:238,
        name:"Sizing",
        data:"110x175 cm ",
        position:0
    },
    {
        Id:594,
        productId:239,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:595,
        productId:239,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:596,
        productId:239,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:597,
        productId:240,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:598,
        productId:240,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:599,
        productId:240,
        name:"Sizing",
        data:"115x180 cm",
        position:0
    },
    {
        Id:600,
        productId:241,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:601,
        productId:241,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:602,
        productId:241,
        name:"Sizing",
        data:"150x250 cm",
        position:0
    },
    {
        Id:603,
        productId:242,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:604,
        productId:242,
        name:"Weight",
        data:"1900GSM Rubber \/ 940GSM Textile ",
        position:0
    },
    {
        Id:605,
        productId:242,
        name:"Sizing",
        data:"150x250 cm",
        position:0
    },
    {
        Id:606,
        productId:243,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:607,
        productId:243,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:608,
        productId:243,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:609,
        productId:244,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:610,
        productId:244,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:611,
        productId:244,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:612,
        productId:245,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:613,
        productId:245,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:614,
        productId:245,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:615,
        productId:246,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:616,
        productId:246,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:617,
        productId:246,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:618,
        productId:247,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:619,
        productId:247,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:620,
        productId:247,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:621,
        productId:248,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:622,
        productId:248,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:623,
        productId:248,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:624,
        productId:249,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:625,
        productId:249,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:626,
        productId:249,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:627,
        productId:250,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:628,
        productId:250,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:629,
        productId:250,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:630,
        productId:251,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:631,
        productId:251,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:632,
        productId:251,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:633,
        productId:252,
        name:"Composition",
        data:"100% Nitrile Rubber base \/ woven polyester substraight \/ 100% Econyl recycled nylon",
        position:0
    },
    {
        Id:634,
        productId:252,
        name:"Weight",
        data:"1900GSM Rubber \/ 740GSM Textile ",
        position:0
    },
    {
        Id:635,
        productId:252,
        name:"Sizing",
        data:"85x150 cm",
        position:0
    },
    {
        Id:636,
        productId:97,
        name:"Composition",
        data:"Tomboy with DFC 65% Polyester \/ 35% cotton",
        position:0
    },
    {
        Id:637,
        productId:97,
        name:"Weight",
        data:" 245GSM",
        position:0
    },
    {
        Id:638,
        productId:98,
        name:"Composition",
        data:"Tomboy with DFC 65% Polyester \/ 35% cotton ",
        position:0
    },
    {
        Id:639,
        productId:98,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:640,
        productId:99,
        name:"Composition",
        data:"Tomboy with DFC 65% Polyester \/ 35% cotton ",
        position:0
    },
    {
        Id:641,
        productId:99,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:642,
        productId:118,
        name:"Composition",
        data:"Flamemaster 75% Cotton \/ 25% Polyester",
        position:0
    },
    {
        Id:643,
        productId:118,
        name:"Weight",
        data:"365GSM",
        position:0
    },
    {
        Id:644,
        productId:135,
        name:"Weight",
        data:"450GSM",
        position:2
    },
    {
        Id:645,
        productId:135,
        name:"Composition",
        data:"VALEN-T945 52% Cotton \/ 22% Modified acrylic fibre type-F \/ 19% Viscose \/ 6% P-Aramide \/ 1% Antistatic",
        position:1
    },
    {
        Id:646,
        productId:136,
        name:"Composition",
        data:"VALEN-T945 52% Cotton \/ 22% Modified acrylic fibre type-F \/ 19% Viscose \/ 6% P-Aramide \/ 1% Antistatic",
        position:0
    },
    {
        Id:647,
        productId:136,
        name:"Weight",
        data:"450GSM",
        position:0
    },
    {
        Id:648,
        productId:137,
        name:"Composition",
        data:"VALEN-T945 52% Cotton \/ 22% Modified acrylic fibre type-F \/ 19% Viscose \/ 6% P-Aramide \/ 1% Antistatic",
        position:0
    },
    {
        Id:649,
        productId:137,
        name:"Weight",
        data:"450GSM",
        position:0
    },
    {
        Id:650,
        productId:159,
        name:"Composition",
        data:"Flameshield 100% Cotton ",
        position:0
    },
    {
        Id:651,
        productId:159,
        name:"Weight",
        data:"340GSM",
        position:0
    },
    {
        Id:652,
        productId:164,
        name:"Composition",
        data:"VALEN-T945 52% Cotton \/ 22% Modified acrylic fibre type-F \/ 19% Viscose \/ 6% P-Aramide \/ 1% Antistatic",
        position:0
    },
    {
        Id:653,
        productId:164,
        name:"Weight",
        data:"450GSM",
        position:0
    },
    {
        Id:654,
        productId:178,
        name:"Composition",
        data:"Outer SILOXANE 47% Metalized Siloxane \/ 38% Pan Oxidzed \/ 15% P-Aramide Lining VT945  52% Cotton \/ 22% Modified acrylic fibre type-F \/ 19% Viscose \/ 6% P-Aramide \/ 1% Antistatic",
        position:0
    },
    {
        Id:655,
        productId:178,
        name:"Weight",
        data:"400GSM",
        position:0
    },
    {
        Id:656,
        productId:179,
        name:"Composition",
        data:"WELDSHEILD 100% Cotton",
        position:0
    },
    {
        Id:657,
        productId:179,
        name:"Weight",
        data:"500GSM",
        position:0
    },
    {
        Id:658,
        productId:180,
        name:"Composition",
        data:"WELDSHEILD 100% Cotton",
        position:0
    },
    {
        Id:659,
        productId:180,
        name:"Weight",
        data:"500GSM",
        position:0
    },
    {
        Id:660,
        productId:259,
        name:"Dimensions",
        data:"L 127mm W 118mm H 285mm",
        position:0
    },
    {
        Id:661,
        productId:253,
        name:"Composition",
        data:"Polyester \/ Cotton",
        position:0
    },
    {
        Id:662,
        productId:254,
        name:"Composition",
        data:"Polyester \/ Cotton",
        position:0
    },
    {
        Id:663,
        productId:255,
        name:"Composition",
        data:"Polyester \/ Cotton",
        position:0
    },
    {
        Id:664,
        productId:256,
        name:"Composition",
        data:"Polyester \/ Cotton",
        position:0
    },
    {
        Id:665,
        productId:257,
        name:"Composition",
        data:"Polyester \/ Cotton",
        position:0
    },
    {
        Id:666,
        productId:258,
        name:"Composition",
        data:"Polyester \/ Cotton",
        position:0
    },
    {
        Id:667,
        productId:147,
        name:"Dimensions",
        data:"D134mm W230mm H264mm",
        position:0
    },
    {
        Id:668,
        productId:260,
        name:"Composition",
        data:"85% Polyester \/ 15% Cotton ",
        position:0
    },
    {
        Id:669,
        productId:260,
        name:"Weight",
        data:"315GSM",
        position:0
    },
    {
        Id:670,
        productId:260,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:671,
        productId:165,
        name:"Composition",
        data:"VALEN-T945 52% Cotton \/ 22% Modified acrylic fibre type-F \/ 19% Viscose \/ 6% P-Aramide \/ 1% Antistatic",
        position:0
    },
    {
        Id:672,
        productId:165,
        name:"Weight",
        data:"450GSM",
        position:0
    },
    {
        Id:674,
        productId:23,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:675,
        productId:84,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:676,
        productId:147,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:677,
        productId:258,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:678,
        productId:257,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:679,
        productId:256,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:680,
        productId:255,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:681,
        productId:254,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:682,
        productId:253,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:683,
        productId:16,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:684,
        productId:22,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:685,
        productId:11,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:686,
        productId:21,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:687,
        productId:5,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:688,
        productId:8,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:689,
        productId:65,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:690,
        productId:61,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:691,
        productId:80,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:692,
        productId:102,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:693,
        productId:104,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:694,
        productId:103,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:695,
        productId:181,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:696,
        productId:182,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:697,
        productId:183,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:698,
        productId:184,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:699,
        productId:185,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:700,
        productId:73,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:701,
        productId:85,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:702,
        productId:67,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:703,
        productId:70,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:704,
        productId:69,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:705,
        productId:66,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:706,
        productId:71,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:707,
        productId:82,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:708,
        productId:55,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:709,
        productId:53,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:710,
        productId:60,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:711,
        productId:52,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:712,
        productId:259,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:713,
        productId:86,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:714,
        productId:79,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:715,
        productId:78,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:716,
        productId:81,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:717,
        productId:54,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:718,
        productId:50,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:719,
        productId:58,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:720,
        productId:51,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:721,
        productId:59,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:722,
        productId:72,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:723,
        productId:64,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:724,
        productId:63,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:725,
        productId:62,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:726,
        productId:57,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:727,
        productId:155,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:728,
        productId:151,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:729,
        productId:154,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:730,
        productId:150,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:731,
        productId:149,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:732,
        productId:148,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:733,
        productId:76,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:734,
        productId:75,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:735,
        productId:74,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:736,
        productId:83,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:737,
        productId:77,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:738,
        productId:56,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:739,
        productId:46,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:740,
        productId:33,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:741,
        productId:123,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:742,
        productId:122,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:743,
        productId:134,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:744,
        productId:158,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:745,
        productId:156,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:746,
        productId:47,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:747,
        productId:31,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:748,
        productId:30,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:749,
        productId:42,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:750,
        productId:43,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:751,
        productId:124,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:752,
        productId:173,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:753,
        productId:1,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:754,
        productId:93,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:755,
        productId:26,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:756,
        productId:121,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:757,
        productId:170,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:758,
        productId:106,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:759,
        productId:171,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:760,
        productId:113,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:761,
        productId:186,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:762,
        productId:146,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:763,
        productId:29,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:764,
        productId:118,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:765,
        productId:38,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:766,
        productId:174,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:767,
        productId:169,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:768,
        productId:176,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:769,
        productId:49,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:770,
        productId:48,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:771,
        productId:126,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:772,
        productId:166,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:773,
        productId:105,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:774,
        productId:101,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:775,
        productId:108,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:776,
        productId:115,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:777,
        productId:112,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:778,
        productId:100,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:779,
        productId:132,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:780,
        productId:131,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:781,
        productId:167,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:782,
        productId:127,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:783,
        productId:109,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:784,
        productId:116,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:785,
        productId:2,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:786,
        productId:111,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:787,
        productId:7,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:788,
        productId:34,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:789,
        productId:9,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:790,
        productId:17,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:791,
        productId:4,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:792,
        productId:107,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:793,
        productId:128,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:794,
        productId:133,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:795,
        productId:175,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:796,
        productId:260,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:797,
        productId:110,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:798,
        productId:117,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:799,
        productId:114,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:800,
        productId:45,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:801,
        productId:44,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:802,
        productId:180,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:803,
        productId:179,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:804,
        productId:178,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:805,
        productId:159,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:806,
        productId:261,
        name:"Composition",
        data:"50% Polyester \/ 50% Cotton",
        position:0
    },
    {
        Id:807,
        productId:261,
        name:"Weight",
        data:"250GSM",
        position:0
    },
    {
        Id:808,
        productId:261,
        name:"Sizing",
        data:"XS \u2013 4XL",
        position:0
    },
    {
        Id:809,
        productId:261,
        name:"Fabric",
        data:"Oeko-Tex standard 100",
        position:0
    },
    {
        Id:810,
        productId:261,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:811,
        productId:262,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:812,
        productId:262,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:813,
        productId:262,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:814,
        productId:262,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:815,
        productId:263,
        name:"Composition",
        data:"60% FR Cotton \/ 39% Polyester \/ 1% Antistatic fiber",
        position:0
    },
    {
        Id:816,
        productId:263,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:817,
        productId:263,
        name:"Sizing",
        data:"44-68",
        position:0
    },
    {
        Id:818,
        productId:263,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:819,
        productId:172,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:820,
        productId:264,
        name:"Composition",
        data:"85% Polyester \/ 15% Cotton ",
        position:0
    },
    {
        Id:821,
        productId:264,
        name:"Weight",
        data:"315GSM",
        position:0
    },
    {
        Id:822,
        productId:264,
        name:"Sizing",
        data:"XS-4XL",
        position:0
    },
    {
        Id:823,
        productId:264,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:824,
        productId:40,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:825,
        productId:153,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:826,
        productId:68,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:827,
        productId:39,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:828,
        productId:152,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:829,
        productId:27,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:830,
        productId:265,
        name:"Dimensions",
        data:"D145 x W 270 x H 322mm",
        position:0
    },
    {
        Id:831,
        productId:265,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:832,
        productId:266,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton ",
        position:0
    },
    {
        Id:833,
        productId:266,
        name:"Weight",
        data:"245 GSM",
        position:0
    },
    {
        Id:834,
        productId:266,
        name:"Sizing",
        data:"Regular 92 \/ 100 \/ 108 \/ 116 \/ 124 \/ 132 \/ 140",
        position:0
    },
    {
        Id:835,
        productId:266,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:836,
        productId:267,
        name:"Length",
        data:"71cm",
        position:0
    },
    {
        Id:837,
        productId:267,
        name:"Composition",
        data:"67% Polyester \/ 33% Cotton",
        position:0
    },
    {
        Id:838,
        productId:267,
        name:"Weight",
        data:"245GSM",
        position:0
    },
    {
        Id:839,
        productId:267,
        name:"Sizing",
        data:"92-140",
        position:0
    },
    {
        Id:840,
        productId:268,
        name:"Composition",
        data:"100% Polyester",
        position:0
    },
    {
        Id:841,
        productId:268,
        name:"Weight",
        data:"265GSM",
        position:0
    },
    {
        Id:842,
        productId:269,
        name:"Composition",
        data:"100% Polyester",
        position:0
    },
    {
        Id:843,
        productId:269,
        name:"Weight",
        data:"265GSM",
        position:0
    },
    {
        Id:844,
        productId:270,
        name:"Composition",
        data:"100% Polyester",
        position:0
    },
    {
        Id:845,
        productId:270,
        name:"Weight",
        data:"360GSM",
        position:0
    },
    {
        Id:846,
        productId:129,
        name:"Length",
        data:"100cm",
        position:0
    },
    {
        Id:847,
        productId:267,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:848,
        productId:268,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:849,
        productId:269,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:850,
        productId:270,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:851,
        productId:130,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:852,
        productId:129,
        name:"Sales",
        data:"Local",
        position:0
    },
    {
        Id:853,
        productId:120,
        name:"Sales",
        data:"Local",
        position:0
    }
]