import React from 'react';
import './GridItem.scoped.css'

import Icon from "../Icon/Icon";
import {Button, Card} from "react-bootstrap";
import {Link} from "react-router-dom";

// context
import { DataContext } from "../../context/DataContext";
import {truncateWithEllipses} from "../../Helpers";

class GridItem extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {
        super( props );
        this.state = {
            primaryImage: '',
            imagePath: 'https://via.placeholder.com/150',
            images: []
        };
    }

    _init = async () => {
        if (this._isMounted) {
            let menuImage = await this.context.getMenuAssetsById(this.props.gridItem.Id);

            if(menuImage?.length > 0){
                this.setState({
                    primaryImage: '/assets/products/' + menuImage[0].path
                })
            } else{
                this.setState({
                    primaryImage: '/assets/products/ymjronP3JYv9ilRz4wik83StyxDi4X-metaZXpnaWYuY29tLWdpZi1tYWtlci53ZWJw-.webp'
                })
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    render() {
        return (
            <>
                <Card className={'gridItem border-0'}>
                    {
                        this.props.fromWelcomePage &&
                        <Card.Img className={'welcomeImages'} variant="top" src={ `/assets/images/${this.props.gridItem.title.toString().toLowerCase()}.svg` } height={150} />
                    }
                    {
                        !this.props.fromWelcomePage &&
                        <Card.Img variant="top" src={ this.state.primaryImage } />
                    }
                    <Card.Body>
                        {
                            this.props.fromWelcomePage &&
                            <Card.Title className={'welcome-product-title text-center font-weight-bold mb-2 mt-2'}>{this.props.gridItem.title}</Card.Title>
                        }
                        {
                            !this.props.fromWelcomePage &&
                            <Card.Title className={'product-title text-center font-weight-bold mb-2 mt-2'}>{this.props.gridItem.title}</Card.Title>
                        }
                        {(this.props.showDescription === undefined || this.props.showDescription === true) &&
                            <Card.Text className={'text-center font-weight-normal'}>
                                { truncateWithEllipses(this.props.gridItem.description) }
                            </Card.Text>
                        }
                        {
                            (this.props.showButtons === undefined || this.props.showButtons === true) &&
                            <Button variant={'primary'} className={'button--green explore-button'}>Explore</Button>
                            // <Button as={Link} to={`/${this.props.navPath}/${this.props.gridItem.Id}/` } variant={'primary'} className={'button--green'}>EXPLORE</Button>
                        }

                    </Card.Body>
                </Card>
            </>
        )
    }
}

GridItem.contextType = DataContext;

export default function( props ) {
    return <GridItem {...props} />;
}


