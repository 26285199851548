export const productMenuManagerSeed = [
    {
        Id:3,
        menuId:5,
        productId:2,
        position:0
    },
    {
        Id:4,
        menuId:5,
        productId:3,
        position:0
    },
    {
        Id:5,
        menuId:5,
        productId:4,
        position:0
    },
    {
        Id:6,
        menuId:5,
        productId:5,
        position:0
    },
    {
        Id:7,
        menuId:5,
        productId:6,
        position:0
    },
    {
        Id:8,
        menuId:5,
        productId:7,
        position:0
    },
    {
        Id:9,
        menuId:5,
        productId:8,
        position:0
    },
    {
        Id:10,
        menuId:5,
        productId:9,
        position:0
    },
    {
        Id:11,
        menuId:5,
        productId:10,
        position:0
    },
    {
        Id:12,
        menuId:5,
        productId:11,
        position:0
    },
    {
        Id:13,
        menuId:5,
        productId:12,
        position:0
    },
    {
        Id:14,
        menuId:5,
        productId:13,
        position:0
    },
    {
        Id:15,
        menuId:5,
        productId:14,
        position:0
    },
    {
        Id:16,
        menuId:5,
        productId:15,
        position:0
    },
    {
        Id:17,
        menuId:5,
        productId:16,
        position:0
    },
    {
        Id:18,
        menuId:5,
        productId:17,
        position:0
    },
    {
        Id:19,
        menuId:5,
        productId:18,
        position:0
    },
    {
        Id:20,
        menuId:5,
        productId:19,
        position:0
    },
    {
        Id:21,
        menuId:5,
        productId:20,
        position:0
    },
    {
        Id:22,
        menuId:5,
        productId:21,
        position:0
    },
    {
        Id:23,
        menuId:5,
        productId:22,
        position:0
    },
    {
        Id:24,
        menuId:5,
        productId:23,
        position:0
    },
    {
        Id:25,
        menuId:5,
        productId:24,
        position:0
    },
    {
        Id:26,
        menuId:5,
        productId:25,
        position:0
    },
    {
        Id:27,
        menuId:5,
        productId:26,
        position:0
    },
    {
        Id:28,
        menuId:5,
        productId:27,
        position:0
    },
    {
        Id:29,
        menuId:5,
        productId:28,
        position:0
    },
    {
        Id:30,
        menuId:5,
        productId:29,
        position:0
    },
    {
        Id:31,
        menuId:7,
        productId:30,
        position:0
    },
    {
        Id:32,
        menuId:7,
        productId:31,
        position:0
    },
    {
        Id:33,
        menuId:7,
        productId:32,
        position:0
    },
    {
        Id:34,
        menuId:7,
        productId:33,
        position:0
    },
    {
        Id:35,
        menuId:7,
        productId:34,
        position:0
    },
    {
        Id:36,
        menuId:7,
        productId:35,
        position:0
    },
    {
        Id:37,
        menuId:7,
        productId:36,
        position:0
    },
    {
        Id:38,
        menuId:7,
        productId:37,
        position:0
    },
    {
        Id:39,
        menuId:7,
        productId:38,
        position:0
    },
    {
        Id:40,
        menuId:7,
        productId:39,
        position:0
    },
    {
        Id:41,
        menuId:7,
        productId:40,
        position:0
    },
    {
        Id:42,
        menuId:7,
        productId:41,
        position:0
    },
    {
        Id:43,
        menuId:7,
        productId:42,
        position:0
    },
    {
        Id:44,
        menuId:7,
        productId:43,
        position:0
    },
    {
        Id:45,
        menuId:7,
        productId:44,
        position:0
    },
    {
        Id:46,
        menuId:7,
        productId:45,
        position:0
    },
    {
        Id:47,
        menuId:7,
        productId:46,
        position:0
    },
    {
        Id:48,
        menuId:7,
        productId:47,
        position:0
    },
    {
        Id:49,
        menuId:7,
        productId:48,
        position:0
    },
    {
        Id:50,
        menuId:7,
        productId:49,
        position:0
    },
    {
        Id:51,
        menuId:23,
        productId:50,
        position:0
    },
    {
        Id:52,
        menuId:23,
        productId:51,
        position:0
    },
    {
        Id:53,
        menuId:23,
        productId:52,
        position:0
    },
    {
        Id:54,
        menuId:23,
        productId:53,
        position:0
    },
    {
        Id:55,
        menuId:23,
        productId:54,
        position:0
    },
    {
        Id:56,
        menuId:23,
        productId:55,
        position:0
    },
    {
        Id:57,
        menuId:23,
        productId:56,
        position:0
    },
    {
        Id:58,
        menuId:23,
        productId:57,
        position:0
    },
    {
        Id:59,
        menuId:23,
        productId:58,
        position:0
    },
    {
        Id:60,
        menuId:23,
        productId:59,
        position:0
    },
    {
        Id:61,
        menuId:23,
        productId:60,
        position:0
    },
    {
        Id:62,
        menuId:22,
        productId:61,
        position:0
    },
    {
        Id:63,
        menuId:22,
        productId:62,
        position:0
    },
    {
        Id:64,
        menuId:22,
        productId:63,
        position:0
    },
    {
        Id:65,
        menuId:22,
        productId:64,
        position:0
    },
    {
        Id:66,
        menuId:22,
        productId:65,
        position:0
    },
    {
        Id:67,
        menuId:22,
        productId:66,
        position:0
    },
    {
        Id:68,
        menuId:22,
        productId:67,
        position:0
    },
    {
        Id:69,
        menuId:22,
        productId:68,
        position:0
    },
    {
        Id:70,
        menuId:22,
        productId:69,
        position:0
    },
    {
        Id:71,
        menuId:22,
        productId:70,
        position:0
    },
    {
        Id:72,
        menuId:24,
        productId:71,
        position:0
    },
    {
        Id:73,
        menuId:24,
        productId:72,
        position:0
    },
    {
        Id:74,
        menuId:24,
        productId:73,
        position:0
    },
    {
        Id:75,
        menuId:26,
        productId:74,
        position:0
    },
    {
        Id:76,
        menuId:26,
        productId:75,
        position:0
    },
    {
        Id:77,
        menuId:26,
        productId:76,
        position:0
    },
    {
        Id:78,
        menuId:26,
        productId:77,
        position:0
    },
    {
        Id:79,
        menuId:25,
        productId:78,
        position:0
    },
    {
        Id:80,
        menuId:25,
        productId:79,
        position:0
    },
    {
        Id:81,
        menuId:25,
        productId:80,
        position:0
    },
    {
        Id:82,
        menuId:25,
        productId:81,
        position:0
    },
    {
        Id:83,
        menuId:25,
        productId:82,
        position:0
    },
    {
        Id:84,
        menuId:25,
        productId:83,
        position:0
    },
    {
        Id:85,
        menuId:27,
        productId:84,
        position:0
    },
    {
        Id:86,
        menuId:27,
        productId:85,
        position:0
    },
    {
        Id:87,
        menuId:27,
        productId:86,
        position:0
    },
    {
        Id:88,
        menuId:10,
        productId:87,
        position:0
    },
    {
        Id:89,
        menuId:10,
        productId:88,
        position:0
    },
    {
        Id:90,
        menuId:10,
        productId:89,
        position:0
    },
    {
        Id:91,
        menuId:10,
        productId:90,
        position:0
    },
    {
        Id:92,
        menuId:10,
        productId:91,
        position:0
    },
    {
        Id:93,
        menuId:9,
        productId:92,
        position:0
    },
    {
        Id:94,
        menuId:7,
        productId:93,
        position:0
    },
    {
        Id:95,
        menuId:9,
        productId:94,
        position:0
    },
    {
        Id:96,
        menuId:9,
        productId:95,
        position:0
    },
    {
        Id:97,
        menuId:9,
        productId:96,
        position:0
    },
    {
        Id:98,
        menuId:8,
        productId:97,
        position:0
    },
    {
        Id:99,
        menuId:8,
        productId:98,
        position:0
    },
    {
        Id:100,
        menuId:8,
        productId:99,
        position:0
    },
    {
        Id:102,
        menuId:15,
        productId:101,
        position:0
    },
    {
        Id:103,
        menuId:28,
        productId:102,
        position:0
    },
    {
        Id:104,
        menuId:28,
        productId:103,
        position:0
    },
    {
        Id:105,
        menuId:28,
        productId:104,
        position:0
    },
    {
        Id:106,
        menuId:8,
        productId:105,
        position:0
    },
    {
        Id:107,
        menuId:8,
        productId:106,
        position:0
    },
    {
        Id:108,
        menuId:8,
        productId:107,
        position:0
    },
    {
        Id:109,
        menuId:11,
        productId:108,
        position:0
    },
    {
        Id:110,
        menuId:11,
        productId:109,
        position:0
    },
    {
        Id:111,
        menuId:11,
        productId:110,
        position:0
    },
    {
        Id:112,
        menuId:11,
        productId:111,
        position:0
    },
    {
        Id:113,
        menuId:12,
        productId:112,
        position:0
    },
    {
        Id:114,
        menuId:12,
        productId:113,
        position:0
    },
    {
        Id:115,
        menuId:12,
        productId:114,
        position:0
    },
    {
        Id:116,
        menuId:11,
        productId:115,
        position:0
    },
    {
        Id:117,
        menuId:11,
        productId:116,
        position:0
    },
    {
        Id:118,
        menuId:11,
        productId:117,
        position:0
    },
    {
        Id:119,
        menuId:11,
        productId:118,
        position:0
    },
    {
        Id:120,
        menuId:13,
        productId:119,
        position:0
    },
    {
        Id:121,
        menuId:13,
        productId:120,
        position:0
    },
    {
        Id:122,
        menuId:14,
        productId:121,
        position:0
    },
    {
        Id:123,
        menuId:14,
        productId:122,
        position:0
    },
    {
        Id:124,
        menuId:14,
        productId:123,
        position:0
    },
    {
        Id:125,
        menuId:14,
        productId:124,
        position:0
    },
    {
        Id:126,
        menuId:14,
        productId:125,
        position:0
    },
    {
        Id:127,
        menuId:14,
        productId:126,
        position:0
    },
    {
        Id:128,
        menuId:14,
        productId:127,
        position:0
    },
    {
        Id:129,
        menuId:14,
        productId:128,
        position:0
    },
    {
        Id:130,
        menuId:13,
        productId:129,
        position:0
    },
    {
        Id:131,
        menuId:13,
        productId:130,
        position:0
    },
    {
        Id:133,
        menuId:15,
        productId:131,
        position:0
    },
    {
        Id:134,
        menuId:14,
        productId:132,
        position:0
    },
    {
        Id:135,
        menuId:14,
        productId:133,
        position:0
    },
    {
        Id:137,
        menuId:17,
        productId:135,
        position:0
    },
    {
        Id:138,
        menuId:17,
        productId:136,
        position:0
    },
    {
        Id:139,
        menuId:17,
        productId:137,
        position:0
    },
    {
        Id:140,
        menuId:8,
        productId:138,
        position:0
    },
    {
        Id:141,
        menuId:9,
        productId:139,
        position:0
    },
    {
        Id:142,
        menuId:8,
        productId:140,
        position:0
    },
    {
        Id:143,
        menuId:9,
        productId:141,
        position:0
    },
    {
        Id:144,
        menuId:8,
        productId:142,
        position:0
    },
    {
        Id:145,
        menuId:9,
        productId:143,
        position:0
    },
    {
        Id:146,
        menuId:8,
        productId:144,
        position:0
    },
    {
        Id:147,
        menuId:9,
        productId:145,
        position:0
    },
    {
        Id:150,
        menuId:23,
        productId:148,
        position:0
    },
    {
        Id:151,
        menuId:23,
        productId:149,
        position:0
    },
    {
        Id:152,
        menuId:22,
        productId:150,
        position:0
    },
    {
        Id:153,
        menuId:25,
        productId:151,
        position:0
    },
    {
        Id:154,
        menuId:25,
        productId:152,
        position:0
    },
    {
        Id:155,
        menuId:22,
        productId:153,
        position:0
    },
    {
        Id:156,
        menuId:25,
        productId:154,
        position:0
    },
    {
        Id:157,
        menuId:26,
        productId:155,
        position:0
    },
    {
        Id:160,
        menuId:15,
        productId:158,
        position:0
    },
    {
        Id:161,
        menuId:11,
        productId:159,
        position:0
    },
    {
        Id:162,
        menuId:18,
        productId:160,
        position:0
    },
    {
        Id:163,
        menuId:18,
        productId:161,
        position:0
    },
    {
        Id:164,
        menuId:18,
        productId:162,
        position:0
    },
    {
        Id:165,
        menuId:17,
        productId:163,
        position:0
    },
    {
        Id:166,
        menuId:17,
        productId:164,
        position:0
    },
    {
        Id:167,
        menuId:17,
        productId:165,
        position:0
    },
    {
        Id:168,
        menuId:14,
        productId:166,
        position:0
    },
    {
        Id:169,
        menuId:14,
        productId:167,
        position:0
    },
    {
        Id:170,
        menuId:14,
        productId:168,
        position:0
    },
    {
        Id:172,
        menuId:11,
        productId:170,
        position:0
    },
    {
        Id:173,
        menuId:11,
        productId:171,
        position:0
    },
    {
        Id:174,
        menuId:11,
        productId:172,
        position:0
    },
    {
        Id:177,
        menuId:15,
        productId:175,
        position:0
    },
    {
        Id:179,
        menuId:9,
        productId:177,
        position:0
    },
    {
        Id:180,
        menuId:12,
        productId:178,
        position:0
    },
    {
        Id:181,
        menuId:12,
        productId:179,
        position:0
    },
    {
        Id:182,
        menuId:12,
        productId:180,
        position:0
    },
    {
        Id:183,
        menuId:28,
        productId:181,
        position:0
    },
    {
        Id:184,
        menuId:28,
        productId:182,
        position:0
    },
    {
        Id:185,
        menuId:28,
        productId:183,
        position:0
    },
    {
        Id:186,
        menuId:28,
        productId:184,
        position:0
    },
    {
        Id:187,
        menuId:28,
        productId:185,
        position:0
    },
    {
        Id:189,
        menuId:30,
        productId:187,
        position:0
    },
    {
        Id:190,
        menuId:30,
        productId:188,
        position:0
    },
    {
        Id:191,
        menuId:30,
        productId:189,
        position:0
    },
    {
        Id:192,
        menuId:30,
        productId:190,
        position:0
    },
    {
        Id:193,
        menuId:30,
        productId:191,
        position:0
    },
    {
        Id:194,
        menuId:30,
        productId:192,
        position:0
    },
    {
        Id:195,
        menuId:30,
        productId:193,
        position:0
    },
    {
        Id:196,
        menuId:30,
        productId:194,
        position:0
    },
    {
        Id:197,
        menuId:30,
        productId:195,
        position:0
    },
    {
        Id:198,
        menuId:30,
        productId:196,
        position:0
    },
    {
        Id:199,
        menuId:30,
        productId:197,
        position:0
    },
    {
        Id:200,
        menuId:30,
        productId:198,
        position:0
    },
    {
        Id:201,
        menuId:30,
        productId:199,
        position:0
    },
    {
        Id:202,
        menuId:30,
        productId:200,
        position:0
    },
    {
        Id:203,
        menuId:30,
        productId:201,
        position:0
    },
    {
        Id:204,
        menuId:30,
        productId:202,
        position:0
    },
    {
        Id:205,
        menuId:30,
        productId:203,
        position:0
    },
    {
        Id:206,
        menuId:30,
        productId:204,
        position:0
    },
    {
        Id:207,
        menuId:30,
        productId:205,
        position:0
    },
    {
        Id:208,
        menuId:30,
        productId:206,
        position:0
    },
    {
        Id:210,
        menuId:31,
        productId:208,
        position:0
    },
    {
        Id:211,
        menuId:31,
        productId:209,
        position:0
    },
    {
        Id:212,
        menuId:31,
        productId:210,
        position:0
    },
    {
        Id:213,
        menuId:32,
        productId:211,
        position:0
    },
    {
        Id:214,
        menuId:32,
        productId:212,
        position:0
    },
    {
        Id:215,
        menuId:32,
        productId:213,
        position:0
    },
    {
        Id:216,
        menuId:32,
        productId:214,
        position:0
    },
    {
        Id:217,
        menuId:33,
        productId:215,
        position:0
    },
    {
        Id:218,
        menuId:33,
        productId:216,
        position:0
    },
    {
        Id:219,
        menuId:33,
        productId:217,
        position:0
    },
    {
        Id:220,
        menuId:34,
        productId:218,
        position:0
    },
    {
        Id:221,
        menuId:30,
        productId:219,
        position:0
    },
    {
        Id:222,
        menuId:30,
        productId:220,
        position:0
    },
    {
        Id:223,
        menuId:35,
        productId:221,
        position:0
    },
    {
        Id:224,
        menuId:35,
        productId:222,
        position:0
    },
    {
        Id:225,
        menuId:32,
        productId:223,
        position:0
    },
    {
        Id:226,
        menuId:32,
        productId:224,
        position:0
    },
    {
        Id:227,
        menuId:32,
        productId:225,
        position:0
    },
    {
        Id:228,
        menuId:32,
        productId:226,
        position:0
    },
    {
        Id:229,
        menuId:32,
        productId:227,
        position:0
    },
    {
        Id:230,
        menuId:35,
        productId:228,
        position:0
    },
    {
        Id:231,
        menuId:35,
        productId:229,
        position:0
    },
    {
        Id:232,
        menuId:34,
        productId:230,
        position:0
    },
    {
        Id:233,
        menuId:34,
        productId:231,
        position:0
    },
    {
        Id:234,
        menuId:34,
        productId:232,
        position:0
    },
    {
        Id:235,
        menuId:34,
        productId:233,
        position:0
    },
    {
        Id:236,
        menuId:35,
        productId:234,
        position:0
    },
    {
        Id:237,
        menuId:35,
        productId:235,
        position:0
    },
    {
        Id:238,
        menuId:35,
        productId:236,
        position:0
    },
    {
        Id:239,
        menuId:35,
        productId:237,
        position:0
    },
    {
        Id:240,
        menuId:34,
        productId:238,
        position:0
    },
    {
        Id:241,
        menuId:34,
        productId:239,
        position:0
    },
    {
        Id:242,
        menuId:34,
        productId:240,
        position:0
    },
    {
        Id:243,
        menuId:35,
        productId:241,
        position:0
    },
    {
        Id:244,
        menuId:35,
        productId:242,
        position:0
    },
    {
        Id:245,
        menuId:34,
        productId:243,
        position:0
    },
    {
        Id:246,
        menuId:34,
        productId:244,
        position:0
    },
    {
        Id:247,
        menuId:34,
        productId:245,
        position:0
    },
    {
        Id:248,
        menuId:34,
        productId:246,
        position:0
    },
    {
        Id:249,
        menuId:34,
        productId:247,
        position:0
    },
    {
        Id:250,
        menuId:34,
        productId:248,
        position:0
    },
    {
        Id:251,
        menuId:34,
        productId:249,
        position:0
    },
    {
        Id:252,
        menuId:34,
        productId:250,
        position:0
    },
    {
        Id:253,
        menuId:34,
        productId:251,
        position:0
    },
    {
        Id:254,
        menuId:34,
        productId:252,
        position:0
    },
    {
        Id:255,
        menuId:23,
        productId:259,
        position:0
    },
    {
        Id:256,
        menuId:22,
        productId:147,
        position:0
    },
    {
        Id:257,
        menuId:14,
        productId:260,
        position:0
    },
    {
        Id:258,
        menuId:31,
        productId:207,
        position:0
    },
    {
        Id:259,
        menuId:36,
        productId:253,
        position:0
    },
    {
        Id:260,
        menuId:36,
        productId:254,
        position:0
    },
    {
        Id:261,
        menuId:36,
        productId:255,
        position:0
    },
    {
        Id:262,
        menuId:36,
        productId:256,
        position:0
    },
    {
        Id:263,
        menuId:36,
        productId:257,
        position:0
    },
    {
        Id:264,
        menuId:36,
        productId:258,
        position:0
    },
    {
        Id:265,
        menuId:37,
        productId:1,
        position:0
    },
    {
        Id:266,
        menuId:37,
        productId:100,
        position:0
    },
    {
        Id:267,
        menuId:37,
        productId:134,
        position:0
    },
    {
        Id:268,
        menuId:37,
        productId:146,
        position:0
    },
    {
        Id:269,
        menuId:37,
        productId:156,
        position:0
    },
    {
        Id:270,
        menuId:37,
        productId:157,
        position:0
    },
    {
        Id:271,
        menuId:37,
        productId:169,
        position:0
    },
    {
        Id:272,
        menuId:37,
        productId:173,
        position:0
    },
    {
        Id:273,
        menuId:37,
        productId:174,
        position:0
    },
    {
        Id:274,
        menuId:37,
        productId:176,
        position:0
    },
    {
        Id:275,
        menuId:37,
        productId:186,
        position:0
    },
    {
        Id:276,
        menuId:37,
        productId:261,
        position:0
    },
    {
        Id:277,
        menuId:11,
        productId:262,
        position:0
    },
    {
        Id:278,
        menuId:11,
        productId:263,
        position:0
    },
    {
        Id:279,
        menuId:14,
        productId:264,
        position:0
    },
    {
        Id:280,
        menuId:22,
        productId:265,
        position:0
    },
    {
        Id:281,
        menuId:5,
        productId:266,
        position:0
    },
    {
        Id:282,
        menuId:13,
        productId:267,
        position:0
    },
    {
        Id:283,
        menuId:5,
        productId:268,
        position:0
    },
    {
        Id:284,
        menuId:13,
        productId:269,
        position:0
    },
    {
        Id:285,
        menuId:13,
        productId:270,
        position:0
    },
    {
        Id:286,
        menuId:13,
        productId:271,
        position:0
    }
]