export const filterManagerSeed = [
    {
        Id:2,
        productId:2,
        filterId:22,
        position:0
    },
    {
        Id:3,
        productId:3,
        filterId:22,
        position:0
    },
    {
        Id:4,
        productId:4,
        filterId:22,
        position:0
    },
    {
        Id:5,
        productId:5,
        filterId:1,
        position:0
    },
    {
        Id:6,
        productId:6,
        filterId:1,
        position:0
    },
    {
        Id:7,
        productId:7,
        filterId:1,
        position:0
    },
    {
        Id:8,
        productId:8,
        filterId:22,
        position:0
    },
    {
        Id:9,
        productId:9,
        filterId:1,
        position:0
    },
    {
        Id:10,
        productId:10,
        filterId:24,
        position:0
    },
    {
        Id:11,
        productId:11,
        filterId:24,
        position:0
    },
    {
        Id:12,
        productId:12,
        filterId:24,
        position:0
    },
    {
        Id:13,
        productId:13,
        filterId:24,
        position:0
    },
    {
        Id:15,
        productId:15,
        filterId:24,
        position:0
    },
    {
        Id:16,
        productId:16,
        filterId:24,
        position:0
    },
    {
        Id:17,
        productId:17,
        filterId:24,
        position:0
    },
    {
        Id:18,
        productId:18,
        filterId:24,
        position:0
    },
    {
        Id:19,
        productId:19,
        filterId:33,
        position:0
    },
    {
        Id:20,
        productId:20,
        filterId:33,
        position:0
    },
    {
        Id:22,
        productId:21,
        filterId:8,
        position:0
    },
    {
        Id:23,
        productId:22,
        filterId:8,
        position:0
    },
    {
        Id:24,
        productId:23,
        filterId:8,
        position:0
    },
    {
        Id:25,
        productId:24,
        filterId:8,
        position:0
    },
    {
        Id:26,
        productId:25,
        filterId:34,
        position:0
    },
    {
        Id:27,
        productId:26,
        filterId:34,
        position:0
    },
    {
        Id:28,
        productId:27,
        filterId:34,
        position:0
    },
    {
        Id:29,
        productId:28,
        filterId:34,
        position:0
    },
    {
        Id:30,
        productId:29,
        filterId:34,
        position:0
    },
    {
        Id:35,
        productId:34,
        filterId:12,
        position:0
    },
    {
        Id:36,
        productId:35,
        filterId:12,
        position:0
    },
    {
        Id:37,
        productId:36,
        filterId:12,
        position:0
    },
    {
        Id:40,
        productId:39,
        filterId:6,
        position:0
    },
    {
        Id:41,
        productId:40,
        filterId:6,
        position:0
    },
    {
        Id:42,
        productId:41,
        filterId:8,
        position:0
    },
    {
        Id:43,
        productId:42,
        filterId:7,
        position:0
    },
    {
        Id:44,
        productId:43,
        filterId:7,
        position:0
    },
    {
        Id:45,
        productId:44,
        filterId:4,
        position:0
    },
    {
        Id:46,
        productId:45,
        filterId:4,
        position:0
    },
    {
        Id:47,
        productId:46,
        filterId:4,
        position:0
    },
    {
        Id:48,
        productId:47,
        filterId:34,
        position:0
    },
    {
        Id:49,
        productId:48,
        filterId:34,
        position:0
    },
    {
        Id:50,
        productId:49,
        filterId:34,
        position:0
    },
    {
        Id:51,
        productId:87,
        filterId:2,
        position:0
    },
    {
        Id:52,
        productId:88,
        filterId:2,
        position:0
    },
    {
        Id:53,
        productId:89,
        filterId:2,
        position:0
    },
    {
        Id:54,
        productId:90,
        filterId:2,
        position:0
    },
    {
        Id:55,
        productId:91,
        filterId:2,
        position:0
    },
    {
        Id:56,
        productId:92,
        filterId:3,
        position:0
    },
    {
        Id:57,
        productId:93,
        filterId:4,
        position:0
    },
    {
        Id:58,
        productId:94,
        filterId:5,
        position:0
    },
    {
        Id:59,
        productId:95,
        filterId:5,
        position:0
    },
    {
        Id:60,
        productId:96,
        filterId:5,
        position:0
    },
    {
        Id:61,
        productId:97,
        filterId:9,
        position:0
    },
    {
        Id:62,
        productId:98,
        filterId:9,
        position:0
    },
    {
        Id:63,
        productId:99,
        filterId:9,
        position:0
    },
    {
        Id:64,
        productId:102,
        filterId:35,
        position:0
    },
    {
        Id:65,
        productId:103,
        filterId:36,
        position:0
    },
    {
        Id:66,
        productId:104,
        filterId:37,
        position:0
    },
    {
        Id:67,
        productId:105,
        filterId:13,
        position:0
    },
    {
        Id:68,
        productId:106,
        filterId:13,
        position:0
    },
    {
        Id:69,
        productId:107,
        filterId:13,
        position:0
    },
    {
        Id:70,
        productId:108,
        filterId:14,
        position:0
    },
    {
        Id:71,
        productId:109,
        filterId:14,
        position:0
    },
    {
        Id:72,
        productId:110,
        filterId:14,
        position:0
    },
    {
        Id:73,
        productId:111,
        filterId:15,
        position:0
    },
    {
        Id:74,
        productId:112,
        filterId:16,
        position:0
    },
    {
        Id:75,
        productId:113,
        filterId:16,
        position:0
    },
    {
        Id:76,
        productId:114,
        filterId:16,
        position:0
    },
    {
        Id:77,
        productId:115,
        filterId:15,
        position:0
    },
    {
        Id:78,
        productId:116,
        filterId:15,
        position:0
    },
    {
        Id:79,
        productId:117,
        filterId:15,
        position:0
    },
    {
        Id:80,
        productId:118,
        filterId:17,
        position:0
    },
    {
        Id:81,
        productId:119,
        filterId:19,
        position:0
    },
    {
        Id:82,
        productId:120,
        filterId:19,
        position:0
    },
    {
        Id:83,
        productId:121,
        filterId:20,
        position:0
    },
    {
        Id:84,
        productId:122,
        filterId:20,
        position:0
    },
    {
        Id:85,
        productId:123,
        filterId:20,
        position:0
    },
    {
        Id:86,
        productId:124,
        filterId:20,
        position:0
    },
    {
        Id:87,
        productId:125,
        filterId:21,
        position:0
    },
    {
        Id:88,
        productId:126,
        filterId:21,
        position:0
    },
    {
        Id:89,
        productId:127,
        filterId:21,
        position:0
    },
    {
        Id:90,
        productId:128,
        filterId:21,
        position:0
    },
    {
        Id:91,
        productId:129,
        filterId:19,
        position:0
    },
    {
        Id:92,
        productId:130,
        filterId:19,
        position:0
    },
    {
        Id:93,
        productId:132,
        filterId:23,
        position:0
    },
    {
        Id:94,
        productId:133,
        filterId:23,
        position:0
    },
    {
        Id:95,
        productId:135,
        filterId:25,
        position:0
    },
    {
        Id:96,
        productId:136,
        filterId:25,
        position:0
    },
    {
        Id:97,
        productId:137,
        filterId:25,
        position:0
    },
    {
        Id:98,
        productId:138,
        filterId:26,
        position:0
    },
    {
        Id:99,
        productId:139,
        filterId:26,
        position:0
    },
    {
        Id:100,
        productId:140,
        filterId:26,
        position:0
    },
    {
        Id:101,
        productId:141,
        filterId:26,
        position:0
    },
    {
        Id:102,
        productId:142,
        filterId:26,
        position:0
    },
    {
        Id:103,
        productId:143,
        filterId:26,
        position:0
    },
    {
        Id:104,
        productId:144,
        filterId:26,
        position:0
    },
    {
        Id:105,
        productId:145,
        filterId:26,
        position:0
    },
    {
        Id:106,
        productId:148,
        filterId:38,
        position:0
    },
    {
        Id:107,
        productId:149,
        filterId:38,
        position:0
    },
    {
        Id:108,
        productId:150,
        filterId:38,
        position:0
    },
    {
        Id:109,
        productId:151,
        filterId:38,
        position:0
    },
    {
        Id:110,
        productId:152,
        filterId:38,
        position:0
    },
    {
        Id:111,
        productId:153,
        filterId:38,
        position:0
    },
    {
        Id:112,
        productId:154,
        filterId:38,
        position:0
    },
    {
        Id:113,
        productId:155,
        filterId:38,
        position:0
    },
    {
        Id:114,
        productId:159,
        filterId:27,
        position:0
    },
    {
        Id:115,
        productId:160,
        filterId:28,
        position:0
    },
    {
        Id:116,
        productId:161,
        filterId:28,
        position:0
    },
    {
        Id:117,
        productId:162,
        filterId:28,
        position:0
    },
    {
        Id:118,
        productId:163,
        filterId:29,
        position:0
    },
    {
        Id:119,
        productId:164,
        filterId:29,
        position:0
    },
    {
        Id:120,
        productId:165,
        filterId:29,
        position:0
    },
    {
        Id:121,
        productId:166,
        filterId:30,
        position:0
    },
    {
        Id:122,
        productId:167,
        filterId:30,
        position:0
    },
    {
        Id:123,
        productId:168,
        filterId:30,
        position:0
    },
    {
        Id:124,
        productId:170,
        filterId:31,
        position:0
    },
    {
        Id:125,
        productId:171,
        filterId:31,
        position:0
    },
    {
        Id:126,
        productId:172,
        filterId:31,
        position:0
    },
    {
        Id:127,
        productId:177,
        filterId:3,
        position:0
    },
    {
        Id:128,
        productId:178,
        filterId:32,
        position:0
    },
    {
        Id:129,
        productId:179,
        filterId:32,
        position:0
    },
    {
        Id:130,
        productId:180,
        filterId:32,
        position:0
    },
    {
        Id:131,
        productId:181,
        filterId:36,
        position:0
    },
    {
        Id:132,
        productId:182,
        filterId:37,
        position:0
    },
    {
        Id:133,
        productId:183,
        filterId:35,
        position:0
    },
    {
        Id:134,
        productId:184,
        filterId:36,
        position:0
    },
    {
        Id:135,
        productId:185,
        filterId:37,
        position:0
    },
    {
        Id:136,
        productId:30,
        filterId:12,
        position:0
    },
    {
        Id:137,
        productId:31,
        filterId:12,
        position:0
    },
    {
        Id:138,
        productId:32,
        filterId:12,
        position:0
    },
    {
        Id:139,
        productId:33,
        filterId:12,
        position:0
    },
    {
        Id:140,
        productId:37,
        filterId:12,
        position:0
    },
    {
        Id:141,
        productId:38,
        filterId:12,
        position:0
    },
    {
        Id:142,
        productId:260,
        filterId:30,
        position:0
    },
    {
        Id:144,
        productId:81,
        filterId:39,
        position:0
    },
    {
        Id:145,
        productId:54,
        filterId:39,
        position:0
    },
    {
        Id:146,
        productId:50,
        filterId:39,
        position:0
    },
    {
        Id:147,
        productId:58,
        filterId:39,
        position:0
    },
    {
        Id:148,
        productId:51,
        filterId:39,
        position:0
    },
    {
        Id:149,
        productId:59,
        filterId:39,
        position:0
    },
    {
        Id:150,
        productId:72,
        filterId:39,
        position:0
    },
    {
        Id:151,
        productId:64,
        filterId:39,
        position:0
    },
    {
        Id:152,
        productId:63,
        filterId:39,
        position:0
    },
    {
        Id:153,
        productId:62,
        filterId:39,
        position:0
    },
    {
        Id:154,
        productId:57,
        filterId:39,
        position:0
    },
    {
        Id:155,
        productId:79,
        filterId:39,
        position:0
    },
    {
        Id:156,
        productId:78,
        filterId:39,
        position:0
    },
    {
        Id:157,
        productId:55,
        filterId:39,
        position:0
    },
    {
        Id:158,
        productId:52,
        filterId:39,
        position:0
    },
    {
        Id:159,
        productId:155,
        filterId:39,
        position:0
    },
    {
        Id:160,
        productId:151,
        filterId:39,
        position:0
    },
    {
        Id:161,
        productId:154,
        filterId:39,
        position:0
    },
    {
        Id:162,
        productId:152,
        filterId:39,
        position:0
    },
    {
        Id:163,
        productId:150,
        filterId:39,
        position:0
    },
    {
        Id:164,
        productId:149,
        filterId:39,
        position:0
    },
    {
        Id:165,
        productId:148,
        filterId:39,
        position:0
    },
    {
        Id:166,
        productId:76,
        filterId:39,
        position:0
    },
    {
        Id:167,
        productId:75,
        filterId:39,
        position:0
    },
    {
        Id:168,
        productId:74,
        filterId:39,
        position:0
    },
    {
        Id:169,
        productId:83,
        filterId:39,
        position:0
    },
    {
        Id:170,
        productId:77,
        filterId:39,
        position:0
    },
    {
        Id:171,
        productId:73,
        filterId:39,
        position:0
    },
    {
        Id:172,
        productId:56,
        filterId:39,
        position:0
    },
    {
        Id:173,
        productId:46,
        filterId:39,
        position:0
    },
    {
        Id:174,
        productId:33,
        filterId:39,
        position:0
    },
    {
        Id:175,
        productId:123,
        filterId:39,
        position:0
    },
    {
        Id:176,
        productId:122,
        filterId:39,
        position:0
    },
    {
        Id:177,
        productId:134,
        filterId:39,
        position:0
    },
    {
        Id:178,
        productId:158,
        filterId:39,
        position:0
    },
    {
        Id:179,
        productId:156,
        filterId:39,
        position:0
    },
    {
        Id:180,
        productId:47,
        filterId:39,
        position:0
    },
    {
        Id:181,
        productId:31,
        filterId:39,
        position:0
    },
    {
        Id:182,
        productId:30,
        filterId:39,
        position:0
    },
    {
        Id:183,
        productId:42,
        filterId:39,
        position:0
    },
    {
        Id:184,
        productId:43,
        filterId:39,
        position:0
    },
    {
        Id:185,
        productId:124,
        filterId:39,
        position:0
    },
    {
        Id:186,
        productId:173,
        filterId:39,
        position:0
    },
    {
        Id:187,
        productId:1,
        filterId:39,
        position:0
    },
    {
        Id:188,
        productId:93,
        filterId:39,
        position:0
    },
    {
        Id:189,
        productId:26,
        filterId:39,
        position:0
    },
    {
        Id:190,
        productId:121,
        filterId:39,
        position:0
    },
    {
        Id:191,
        productId:170,
        filterId:39,
        position:0
    },
    {
        Id:192,
        productId:106,
        filterId:39,
        position:0
    },
    {
        Id:193,
        productId:171,
        filterId:39,
        position:0
    },
    {
        Id:194,
        productId:113,
        filterId:39,
        position:0
    },
    {
        Id:195,
        productId:186,
        filterId:39,
        position:0
    },
    {
        Id:196,
        productId:146,
        filterId:39,
        position:0
    },
    {
        Id:197,
        productId:29,
        filterId:39,
        position:0
    },
    {
        Id:198,
        productId:118,
        filterId:39,
        position:0
    },
    {
        Id:199,
        productId:38,
        filterId:39,
        position:0
    },
    {
        Id:200,
        productId:174,
        filterId:39,
        position:0
    },
    {
        Id:201,
        productId:169,
        filterId:39,
        position:0
    },
    {
        Id:202,
        productId:176,
        filterId:39,
        position:0
    },
    {
        Id:203,
        productId:49,
        filterId:39,
        position:0
    },
    {
        Id:204,
        productId:48,
        filterId:39,
        position:0
    },
    {
        Id:205,
        productId:126,
        filterId:39,
        position:0
    },
    {
        Id:206,
        productId:166,
        filterId:39,
        position:0
    },
    {
        Id:207,
        productId:105,
        filterId:39,
        position:0
    },
    {
        Id:208,
        productId:101,
        filterId:39,
        position:0
    },
    {
        Id:209,
        productId:108,
        filterId:39,
        position:0
    },
    {
        Id:210,
        productId:115,
        filterId:39,
        position:0
    },
    {
        Id:211,
        productId:112,
        filterId:39,
        position:0
    },
    {
        Id:212,
        productId:100,
        filterId:39,
        position:0
    },
    {
        Id:213,
        productId:132,
        filterId:39,
        position:0
    },
    {
        Id:214,
        productId:131,
        filterId:39,
        position:0
    },
    {
        Id:215,
        productId:167,
        filterId:39,
        position:0
    },
    {
        Id:216,
        productId:127,
        filterId:39,
        position:0
    },
    {
        Id:217,
        productId:109,
        filterId:39,
        position:0
    },
    {
        Id:218,
        productId:116,
        filterId:39,
        position:0
    },
    {
        Id:219,
        productId:2,
        filterId:39,
        position:0
    },
    {
        Id:220,
        productId:111,
        filterId:39,
        position:0
    },
    {
        Id:221,
        productId:7,
        filterId:39,
        position:0
    },
    {
        Id:222,
        productId:34,
        filterId:39,
        position:0
    },
    {
        Id:223,
        productId:9,
        filterId:39,
        position:0
    },
    {
        Id:224,
        productId:17,
        filterId:39,
        position:0
    },
    {
        Id:225,
        productId:4,
        filterId:39,
        position:0
    },
    {
        Id:226,
        productId:107,
        filterId:39,
        position:0
    },
    {
        Id:227,
        productId:128,
        filterId:39,
        position:0
    },
    {
        Id:228,
        productId:133,
        filterId:39,
        position:0
    },
    {
        Id:229,
        productId:175,
        filterId:39,
        position:0
    },
    {
        Id:230,
        productId:260,
        filterId:39,
        position:0
    },
    {
        Id:231,
        productId:110,
        filterId:39,
        position:0
    },
    {
        Id:232,
        productId:117,
        filterId:39,
        position:0
    },
    {
        Id:233,
        productId:114,
        filterId:39,
        position:0
    },
    {
        Id:234,
        productId:45,
        filterId:39,
        position:0
    },
    {
        Id:235,
        productId:44,
        filterId:39,
        position:0
    },
    {
        Id:236,
        productId:180,
        filterId:39,
        position:0
    },
    {
        Id:237,
        productId:179,
        filterId:39,
        position:0
    },
    {
        Id:238,
        productId:178,
        filterId:39,
        position:0
    },
    {
        Id:239,
        productId:159,
        filterId:39,
        position:0
    },
    {
        Id:240,
        productId:23,
        filterId:39,
        position:0
    },
    {
        Id:241,
        productId:84,
        filterId:39,
        position:0
    },
    {
        Id:242,
        productId:147,
        filterId:39,
        position:0
    },
    {
        Id:243,
        productId:258,
        filterId:39,
        position:0
    },
    {
        Id:244,
        productId:257,
        filterId:39,
        position:0
    },
    {
        Id:245,
        productId:256,
        filterId:39,
        position:0
    },
    {
        Id:246,
        productId:254,
        filterId:39,
        position:0
    },
    {
        Id:247,
        productId:253,
        filterId:39,
        position:0
    },
    {
        Id:248,
        productId:255,
        filterId:39,
        position:0
    },
    {
        Id:249,
        productId:16,
        filterId:39,
        position:0
    },
    {
        Id:250,
        productId:22,
        filterId:39,
        position:0
    },
    {
        Id:251,
        productId:11,
        filterId:39,
        position:0
    },
    {
        Id:252,
        productId:21,
        filterId:39,
        position:0
    },
    {
        Id:253,
        productId:5,
        filterId:39,
        position:0
    },
    {
        Id:254,
        productId:8,
        filterId:39,
        position:0
    },
    {
        Id:255,
        productId:65,
        filterId:39,
        position:0
    },
    {
        Id:256,
        productId:61,
        filterId:39,
        position:0
    },
    {
        Id:257,
        productId:80,
        filterId:39,
        position:0
    },
    {
        Id:258,
        productId:102,
        filterId:39,
        position:0
    },
    {
        Id:259,
        productId:104,
        filterId:39,
        position:0
    },
    {
        Id:260,
        productId:103,
        filterId:39,
        position:0
    },
    {
        Id:261,
        productId:181,
        filterId:39,
        position:0
    },
    {
        Id:262,
        productId:182,
        filterId:39,
        position:0
    },
    {
        Id:263,
        productId:183,
        filterId:39,
        position:0
    },
    {
        Id:264,
        productId:184,
        filterId:39,
        position:0
    },
    {
        Id:265,
        productId:185,
        filterId:39,
        position:0
    },
    {
        Id:266,
        productId:85,
        filterId:39,
        position:0
    },
    {
        Id:267,
        productId:67,
        filterId:39,
        position:0
    },
    {
        Id:268,
        productId:70,
        filterId:39,
        position:0
    },
    {
        Id:269,
        productId:69,
        filterId:39,
        position:0
    },
    {
        Id:270,
        productId:66,
        filterId:39,
        position:0
    },
    {
        Id:271,
        productId:71,
        filterId:39,
        position:0
    },
    {
        Id:272,
        productId:82,
        filterId:39,
        position:0
    },
    {
        Id:273,
        productId:53,
        filterId:39,
        position:0
    },
    {
        Id:274,
        productId:60,
        filterId:39,
        position:0
    },
    {
        Id:275,
        productId:259,
        filterId:39,
        position:0
    },
    {
        Id:276,
        productId:86,
        filterId:39,
        position:0
    },
    {
        Id:277,
        productId:14,
        filterId:1,
        position:0
    },
    {
        Id:278,
        productId:261,
        filterId:39,
        position:0
    },
    {
        Id:280,
        productId:262,
        filterId:31,
        position:0
    },
    {
        Id:281,
        productId:262,
        filterId:39,
        position:0
    },
    {
        Id:282,
        productId:263,
        filterId:31,
        position:0
    },
    {
        Id:283,
        productId:263,
        filterId:39,
        position:0
    },
    {
        Id:284,
        productId:172,
        filterId:39,
        position:0
    },
    {
        Id:285,
        productId:40,
        filterId:39,
        position:0
    },
    {
        Id:286,
        productId:153,
        filterId:39,
        position:0
    },
    {
        Id:287,
        productId:68,
        filterId:39,
        position:0
    },
    {
        Id:288,
        productId:39,
        filterId:39,
        position:0
    },
    {
        Id:289,
        productId:27,
        filterId:39,
        position:0
    },
    {
        Id:290,
        productId:265,
        filterId:39,
        position:0
    },
    {
        Id:291,
        productId:266,
        filterId:1,
        position:0
    },
    {
        Id:292,
        productId:266,
        filterId:39,
        position:0
    },
    {
        Id:293,
        productId:268,
        filterId:22,
        position:0
    },
    {
        Id:294,
        productId:26,
        filterId:1,
        position:0
    },
    {
        Id:295,
        productId:267,
        filterId:39,
        position:0
    },
    {
        Id:296,
        productId:268,
        filterId:39,
        position:0
    },
    {
        Id:297,
        productId:269,
        filterId:39,
        position:0
    },
    {
        Id:298,
        productId:270,
        filterId:39,
        position:0
    },
    {
        Id:299,
        productId:130,
        filterId:39,
        position:0
    },
    {
        Id:300,
        productId:129,
        filterId:39,
        position:0
    },
    {
        Id:301,
        productId:120,
        filterId:39,
        position:0
    }
]