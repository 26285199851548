import bcrypt from 'bcryptjs'
import CONFIG from "./Config";

/**
 * stores an error occurrance in the error logs for review in the app's 'developer' interface
 * SILENT LOGGING ONLY, this does not "handle" errors in the UI state i.e. does not apply error state or show an alert
 * @param {str} heading - Concisely state the intent of the action that caused this error e.g. "Error getting news posts"
 * @param {str} location - Give a *useful* description stating where the error occurred, ideally the file and function e.g. NewsContext->getPosts
 * @param {err} error - the error response object in case of a request or the exception in a try/catch
 * @returns
 */
export const logError = async ( heading, location, error ) => {
    console.warn( `ERROR LOG: ${heading} at ${location}`, error );
};

export const getSession = async () => {
    const accessToken = await '';
}

export const getSalt = async () => {
    return '$2a$10$zQShGfdsfds324fdffh3KeL/mGlaXPcifse234fdsfdsf';
}

export const hashPayload = async (payload) => {
    return bcrypt.hashSync(payload, getSalt());
}

export const getEndpoint = (route, params = {}, useVersion) => {

    let param_string = '',
        i = 0;

    for( let param in params ) {
        param_string += ( i === 0 ? '?' : '&' ) + param + '=' + params[param];
        i++;
    }

    let host = CONFIG.host[CONFIG.env.app_env];
    const version = useVersion !== false ? ( '/v' + ( useVersion ? useVersion : CONFIG.env.defaultAPIVersion ) ) : '';
    return encodeURI( host + version + ( route[0] == '/' ? '' : '/' ) +  route + param_string );
}

export const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

export const truncateWithEllipses = (text, max = 100) => {
    if(text) {
        let trimmedString = text.substr(0, max);
        return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + '...'
        //return text.substr(0, max - 1) + (text.length > max ? '...' : '');
    }
}

export const flattenArray = (source, list=[]) => {
    let len = source.length;

    for(let i = 0; i < len; i++) {
        let currentElement = source[i];

        if(Array.isArray(currentElement)) {
            let flatten = flattenArray(currentElement);
            list.push(...flatten);
        } else {
            list.push(currentElement)
        }
    }
    return list
}
