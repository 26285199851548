import React from 'react';
import './ColorSwatch.scoped.css'

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { DataContext } from "../../context/DataContext";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {useNavigate, useParams} from "react-router-dom";

class ColorSwatch extends React.Component {

    constructor( props ) {
        super( props );
        this.state = { };
    }


    _getItemId = () => {
        return this.props.params.id;
    }

    _init = async () => {
        if( this._isMounted ) {
            await this.context.getProductColoursById(this._getItemId());
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    render() {
        return (
            <DataContext.Consumer>{( dataContext ) => {
                const { productColoursById } = dataContext;

                if(this.state.isLoading){
                    return(
                        <LoadingSpinner />
                    )
                }
                if(!this.state.isLoading && productColoursById && productColoursById.length > 0) {
                    return(
                        <div className={'container colour-container'}>
                            <div className={'row g-0 mt-4'}>
                                {
                                    productColoursById.map((colour, index) => {
                                        return(
                                            <div className={'col-2 mb-4 text-center'} key={index}>
                                                <div className={'colorSwatch-container'}>
                                                    <div className={'color-name text-capitalize text-center'}>{colour.name}</div>
                                                    <div className={'color-swatch mt-2 align-self-center'} style={{'backgroundColor': colour.hex}}></div>
                                                    <div className={'product-code mt-2'}>
                                                        {
                                                            colour.productCodes &&
                                                            colour.productCodes.length > 0 &&
                                                            colour.productCodes?.map((productcode, index) => {
                                                                return(
                                                                    <div className={'produt-code mt-1'} key={index}>{productcode.name} {productcode.productcode}</div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    );
                }
            }}</DataContext.Consumer>
        );
    }
}

ColorSwatch.contextType = DataContext;

export default function( props ) {
    const navigation = useNavigate();
    const params = useParams();
    return <ColorSwatch {...props} navigation={navigation} params={params} />;
}
