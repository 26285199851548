import React from 'react';
import { Navigate  } from "react-router-dom";

import * as Helper from '../Helpers'

// context
import { DataContext } from "../context/DataContext";

// components
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'


class AuthLoadingScreen extends React.Component {

    _isMounted = false;
    _isLoading = false;

    constructor( props ) {

        super( props );

        this.state = {
            isLoading: true,
            loadFailed: false,
        };
    }

    _init = async () => {
        if( this._isMounted ) {
            //await Helper.sleep(5000);
            await this.context.importData();
            this.setState({ loadFailed: false, isLoading: false });
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this._init();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        if(this.state.isLoading) {
            return(
                <LoadingSpinner />
            )
        }
        if(!this.state.isLoading) {
            return(
                <>
                    <Navigate to='/home'/>
                </>
            )
        }
    }


}

AuthLoadingScreen.contextType = DataContext;

export default function( props ) {
    return <AuthLoadingScreen {...props} />;
}

